import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

export default function PaymentProcessorRedirect({ location }) {
  // const [stripeConnected, setStripeConnected] = useState(false);
  // const [connectUrl, setConnectUrl] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // history.push('/');
    history.push('/integration/payment-processor', {
      queryString: location.search,
    });
    // window.location.search = '';
    // console.log(window);
    // window.location = '/integration/payment-processor';
  }, []);
  return (
    <Typography gutterBottom align={'center'} color="primary">
      Processing...
    </Typography>
  );
}
