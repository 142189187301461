import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CurrencyText from './CurrencyText';
import Box from '@material-ui/core/Box';

export default function GlobalMetricDisplay(props) {
  const { data } = props;

  const allAggregates = data.map((aggregate) => {
    return (
      <Grid item key={aggregate.id}>
        <Typography
          gutterBottom
          data-cy={aggregate.label + '_label'}
          component="span"
          style={{ fontWeight: 'bold' }}
          color="textPrimary">
          {aggregate.label}
          {': '}
        </Typography>
        <Typography
          gutterBottom
          data-cy={aggregate.id + '_label'}
          style={{ fontWeight: 'bold' }}
          component="span"
          color="secondary">
          {(() => {
            let val = aggregate.value ? aggregate.value : null;
            switch (aggregate.dataType) {
              case 'currency':
                return <CurrencyText amount={val} />;
              case 'number':
                return val;
              default:
                return val;
            }
          })()}
        </Typography>
      </Grid>
    );
  });

  return (
    <Box>
      {allAggregates && (
        <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
          {allAggregates}
        </Grid>
      )}
    </Box>
  );
}
