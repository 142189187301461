import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { usePlaidLink } from 'react-plaid-link';
import GoodbricksLegacyAPIClient from '../../../restClients/GoodbricksLegacyAPIClient';

export default function ACHPaymentButton({ label, id, setValue, register }) {
  const [linkToken, setLinkToken] = useState('');
  const [bankToken, setBankToken] = useState(null);

  async function createLinkToken() {
    try {
      const response = await GoodbricksLegacyAPIClient.plaidCreateLinkToken();
      const responseJson = await response.json();
      console.log('Link token', responseJson.linkToken);
      setLinkToken(responseJson.linkToken);
    } catch (e) {
      console.log('ACH Link Token error', e);
    }
  }
  async function getBankToken(public_token, account_id) {
    let body = {
      publicToken: public_token,
      accountId: account_id,
    };
    try {
      const response = await GoodbricksLegacyAPIClient.createBankToken(body);
      const responseJson = await response.json();
      let bankToken = responseJson.bankAccountToken;
      console.log('Bank account token', bankToken);
      setBankToken(bankToken);
      setValue('token', bankToken);
      return bankToken;
    } catch (e) {
      console.log('ACH Link Token error', e);
    }
  }
  useEffect(() => {
    register({ name: 'token' });
    createLinkToken();
  }, []);

  const onSuccess = useCallback((token, metadata) => {
    // send token to server
    let bankToken = getBankToken(token, metadata.accounts[0].id);
    console.log('Public Token: ' + token);
    console.log('Customer-selected account ID: ' + metadata.accounts[0].id);
    console.log(bankToken);
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
    // ...
  };

  const { open, ready, error } = usePlaidLink(config);

  if (bankToken) {
    return <h1>Bank Account linked!</h1>;
  }
  return (
    !bankToken && (
      <Button size="large" variant="contained" color="secondary" fullWidth onClick={() => open()} disabled={!ready}>
        {label}
      </Button>
    )
  );
}
