import { makeStyles } from '@material-ui/core';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import sanitizeHtml from 'sanitize-html';

const useStyles = makeStyles(() => ({
  qlContainer: {
    height: '20rem',
    paddingBottom: '3rem',
  },
}));
export default function RichTextEditor(props) {
  const classes = useStyles();
  const { name, onChange, key, id, placeholder, value } = props;
  const module = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  const formats = ['header', 'bold', 'italic', 'underline', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

  const sanitizeHtmlOptions = {
    allowedTags: ['img', 'a', 'h1', 'h2', 'strong', 'p', 'em', 'u', 'blockquote', 'ol', 'ul', 'li', 'header'],
    allowedAttributes: {
      img: ['src'],
      a: ['href', 'target'],
      p: ['class'],
      li: ['class'],
    },
    allowedSchemes: ['http', 'https'],
    selfClosing: ['img'],
    allowedSchemesByTag: {
      img: ['data'],
    },
  };

  const handleChange = (data) => {
    const clean = sanitizeHtml(data, sanitizeHtmlOptions);
    onChange({ [name]: clean });
  };

  return (
    <div className={classes.qlContainer}>
      <ReactQuill
        className={classes.qlContainer}
        key={key}
        id={id}
        placeholder={placeholder}
        modules={module}
        formats={formats}
        theme={'snow'}
        defaultValue={value}
        onChange={handleChange}
      />
    </div>
  );
}
