import React from 'react';
import Typography from '@material-ui/core/Typography';
import customStyles from '../../theme/customStyles';

export default function CauseText(props) {
  const classes = customStyles();
  // return <Chip variant="outlined" size="small" label={props.label} color="secondary" />;
  return (
    <Typography color="secondary" className={classes.capitalize}>
      {props.label}
    </Typography>
  );
}
