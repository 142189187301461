import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';

export default function StripeInput(props) {
  const { component: Component, inputRef, ...other } = props;
  const theme = useTheme();
  const elementRef = React.useRef();

  React.useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      options={{
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: theme.palette.text.secondary,
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      onReady={(element) => (elementRef.current = element)}
      {...other}
    />
  );
}
