import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import NavLink from '../../components/displayValues/NavLink';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useSnackbar } from 'notistack';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { PHONE_MASK, USER_ROLES } from '../../Constants';
import { conformToMask } from 'react-text-mask';
import Page401 from '../error/Page401';

export default function CustomerEdit(props) {
  const {
    match: { params },
  } = props;
  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [paymentMethod, setPaymentMethod] = useState('');

  const submitCustomerChanges = async (data) => {
    await GoodbricksLegacyAPIClient.updateCustomer(authenticatedUser, data).then(
      (response) => {
        enqueueSnackbar('Customer Saved successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      },
      (error) => {
        enqueueSnackbar('Something went wrong. Please try again... ', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        console.log(error);
      },
    );
  };

  const [customer, setCustomer] = useState();

  const fields = [
    {
      name: 'customer-information',
      label: 'Customer Information',
      fieldType: 'section-header',
    },
    {
      name: 'name',
      label: 'Full Name',
      fieldType: 'text',
      inputType: 'text',
      minLength: 3,
      value: customer?.name ? customer.name : '',
    },
    {
      name: 'email',
      label: 'Email',
      fieldType: 'text',
      inputType: 'email',
      value: customer?.email ? customer.email : '',
      readOnly: true,
    },
    {
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
      value: customer?.phone ? conformToMask(customer?.phone, PHONE_MASK, { guide: false }).conformedValue : '',
    },
    {
      name: 'address-information',
      label: 'Address',
      fieldType: 'section-header',
    },
    {
      name: 'address',
      label: 'Address',
      fieldType: 'address',
      value: {
        addressApt: customer?.addressApt ? customer.addressApt : '',
        addressCity: customer?.addressCity ? customer.addressCity : '',
        addressState: customer?.addressState ? customer.addressState : '',
        addressStreet: customer?.addressStreet ? customer.addressStreet : '',
        addressZipCode: customer?.addressZipCode ? customer.addressZipCode : '',
      },
      required: false,
    },
    {
      name: 'payment-information',
      label: 'Payment Information',
      fieldType: 'section-header',
    },
    {
      name: 'paymentMethod',
      fieldType: 'radio-group',
      value: customer?.paymentMethod?.card?.last4 ? 'DEFAULT' : 'CARD',
      horizontal: true,
      options: [
        {
          label: 'Default',
          value: 'DEFAULT',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
      ],
    },
    {
      name: 'defaultPaymentMethod',
      label: 'Default Payment Method',
      readOnly: true,
      value: customer?.paymentMethod?.card?.last4
        ? customer?.paymentMethod?.card?.brand + '...***' + customer?.paymentMethod?.card?.last4
        : '',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['DEFAULT'],
      },
    },
    {
      name: 'stripe-payment',
      fieldType: 'stripe-payment',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['CARD'],
      },
    },
  ];

  useEffect(() => {
    if (params.customerId) {
      ElasticSearchAPIClient.getCustomerById(authenticatedUser, params.customerId).then((response) => {
        const customers = response.hits.hits.map((x) => {
          return { id: x.id, ...x._source };
        });
        setCustomer({
          ...customers[0],
        });
        setPaymentMethod(customer?.paymentMethod?.card?.last4 ? 'DEFAULT' : 'CARD');
      });
    }
  }, []);

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Box my={9}>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/customers" text="Customers" color="primary" />
              {!!params.customerId ? (
                <Typography component="span">Edit</Typography>
              ) : (
                <Typography component="span">New</Typography>
              )}
            </Breadcrumbs>
          </Box>
          {customer && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={8}>
                <DynamicForm
                  fields={fields}
                  formSubmitCallback={submitCustomerChanges}
                  formOptions={{
                    acceptStripePayment: (data) => {
                      return data.paymentMethod === 'CARD';
                    },
                    submitButtonLabel: 'Save Customer',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}
