import React from 'react';

import {
  BusinessOutlined,
  CreditCard,
  DashboardOutlined,
  DescriptionOutlined,
  LabelOutlined,
  MailOutlineOutlined,
  PeopleOutline,
  ReceiptOutlined,
  Repeat,
  PanToolOutlined,
} from '@material-ui/icons';
import Transactions from '../pages/income/Transactions';
import Causes from '../pages/causes/Causes';
import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import ConfirmSignUp from '../pages/auth/ConfirmSignUp';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import OrgSetup from '../pages/OrgSetup';
import Page404 from '../pages/error/Page404';
import Page500 from '../pages/error/Page500';
import Dashboard from '../pages/dashboard';
import Customers from '../pages/customer/Customers';
import BusinessProfile from '../pages/settings/BusinessProfile';
import Subscriptions from '../pages/subscription/Subscriptions';
import CustomerEdit from '../pages/customer/CustomerEdit';
import CustomerTransactions from '../pages/customer/CustomerTransactions';
import CustomerSubscriptions from '../pages/customer/CustomerSubscriptions';
import SubscriptionTransactions from '../pages/subscription/SubscriptionTransactions';
import CauseSubscriptions from '../pages/causes/CauseSubscriptions';
import CauseTransactions from '../pages/causes/CauseTransactions';
import ResendConfirmSignUpCode from '../pages/auth/ResendConfirmSignUpCode';
import TransactionNew from '../pages/income/TransactionNew';
import SubscriptionNew from '../pages/subscription/SubscriptionNew';
import CausePageEditor from '../pages/causes/editor/CausePageEditor';
import EmailSettingsEdit from '../pages/settings/emailSettings/EmailSettingsEdit';
import EmailSettings from '../pages/settings/emailSettings/EmailSettings';
import PaymentProcessor from '../pages/integrations/PaymentProcessor';
import PaymentProcessorRedirect from '../pages/integrations/PaymentProcessorRedirect';
import TransactionsImportWizard from '../pages/income/import/TransactionsImportWizard';
import CustomersByYear from '../pages/tax-receipts/CustomersByYear';
import YearEndTaxReceiptPrintPreview from '../pages/tax-receipts/YearEndTaxReceiptPrintPreview';
import Pledges from '../pages/pledge/Pledges';
import PledgeNew from '../pages/pledge/PledgeNew';
import PledgesImportWizard from '../pages/pledge/import/PledgesImportWizard';
import PledgeTransactions from '../pages/pledge/PledgeTransactions';
import Markerting from '../pages/marketing/Marketing';

// used to build different dashboard pages within dashboard layout (in Routes.js)
export const dashboardRoutes = [
  {
    id: 'dashboard',
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    icon: <DashboardOutlined />,
  },
  {
    id: 'transactions',
    path: '/transactions',
    name: 'Transactions',
    headerTitle: 'Transactions',
    component: Transactions,
    icon: <ReceiptOutlined data-cy="transactions-sidebar-icon" />,
    header: 'CRM',
  },
  {
    id: 'pledges',
    path: '/pledges',
    name: 'Pledges',
    component: Pledges,
    icon: <PanToolOutlined data-cy="pledge-sidebar-icon" />,
  },
  {
    id: 'customers',
    path: '/customers',
    name: 'Customers',
    component: Customers,
    icon: <PeopleOutline data-cy="customers-sidebar-icon" />,
  },
  {
    id: 'causes',
    path: '/causes',
    name: 'Causes',
    component: Causes,
    icon: <LabelOutlined data-cy="causes-sidebar-icon" />,
  },
  {
    id: 'subscriptions',
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    icon: <Repeat data-cy="subscriptions-sidebar-icon" />,
  },
  {
    id: 'business-profile',
    path: '/settings/business',
    component: BusinessProfile,
    icon: <BusinessOutlined data-cy="business-sidebar-icon" />,
    header: 'Settings',
    name: 'Business Profile',
  },
  {
    id: 'templates-email-and-receipts)',
    path: '/settings/templates',
    icon: <MailOutlineOutlined data-cy="email-sidebar-icon" />,
    component: EmailSettings,
    name: (
      <>
        Templates <br />
        (Email and Receipts)
      </>
    ),
  },
  {
    id: 'tax-receipts',
    path: '/tax-receipts/year-end',
    component: CustomersByYear,
    // component: TaxReceiptPreview,
    header: 'Tax Receipts',
    icon: <DescriptionOutlined data-cy="year-end-tax-receipts-icon" />,
    name: 'Year End - 2023',
  },

  {
    id: 'payment-processor',
    path: '/integration/payment-processor',
    component: PaymentProcessor,
    header: 'Integrations',
    icon: <CreditCard data-cy="payment-processor-sidebar-icon" />,
    name: 'Payment Processor',
  },
];

export const hiddenRoutes = [
  {
    id: 'Customer-Editor',
    path: '/customer/:customerId/editor',
    component: CustomerEdit,
    name: 'Customer Editor',
  },
  {
    id: 'Cause-Editor',
    path: '/causes/:causeId/editor',
    component: CausePageEditor,
    name: 'Cause Editor ',
  },
  {
    id: 'Cause-Editor',
    path: '/causes/new',
    component: CausePageEditor,
    name: 'Cause Editor ',
  },
  {
    id: 'Cause-transactions',
    path: '/causes/:causeId/transactions',
    component: CauseTransactions,
    name: 'Cause Transactions ',
  },
  {
    id: 'Cause-subscriptions',
    path: '/causes/:causeId/subscriptions',
    component: CauseSubscriptions,
    name: 'Cause / Subscriptions',
  },
  {
    id: 'Customers-transactions',
    path: '/customers/:customerId/transactions',
    component: CustomerTransactions,
    name: 'Customer Transactions ',
  },
  {
    id: 'Customers-subscriptions',
    path: '/customers/:customerId/subscriptions',
    component: CustomerSubscriptions,
    name: 'Customer / Subscriptions',
  },
  {
    id: 'Transaction-new',
    path: '/transaction/new',
    component: TransactionNew,
    name: 'New Transaction',
  },
  {
    id: 'Transactions-import',
    path: '/transactions/import',
    component: TransactionsImportWizard,
    name: 'Import Transactions',
  },
  {
    id: 'Pledge-new',
    path: '/pledge/new',
    component: PledgeNew,
    name: 'New Pledge',
  },
  {
    id: 'Pledges-import',
    path: '/pledges/import',
    component: PledgesImportWizard,
    name: 'Import Pledges',
  },
  {
    id: 'Pledge-transactions',
    path: '/pledges/:pledgeId/transactions',
    component: PledgeTransactions,
    name: 'Subscriptions Transactions',
  },
  {
    id: 'Subscriptions-transactions',
    path: '/subscriptions/:subscriptionId/transactions',
    component: SubscriptionTransactions,
    name: 'Subscriptions Transactions',
  },
  {
    id: 'Subscriptions-new',
    path: '/subscription/new',
    component: SubscriptionNew,
    name: 'New Subscription',
  },
  {
    id: 'Email Settings',
    path: '/settings/email/:id/edit',
    component: EmailSettingsEdit,
    name: 'Email Settings',
  },
  {
    id: 'Year-End-Tax-Receipt-Preview',
    path: '/tax-receipts/year-end/preview',
    component: YearEndTaxReceiptPrintPreview,
    name: 'Year End Tax Receipt Preview',
  },
  {
    id: 'Marketing',
    path: '/causes/:slug/marketing',
    component: Markerting,
    name: 'Marketing',
  },
];

// used to build different fullwidth pages within fullwidth layout (in Routes.js)
export const fullWidthPublicRoutes = [
  {
    id: 'Auth',
    path: '/auth',
    header: 'Full Width Public Pages',
    icon: <PeopleOutline />,
    children: [
      {
        path: '/auth/sign-in',
        name: 'Sign In',
        component: SignIn,
      },
      {
        path: '/auth/sign-up',
        name: 'Sign Up',
        component: SignUp,
      },
      {
        path: '/auth/confirm-sign-up',
        name: 'Confirm Sign Up',
        component: ConfirmSignUp,
      },
      {
        path: '/auth/resend-confirm-sign-up-code',
        name: 'Resend Confirm Sign Up code',
        component: ResendConfirmSignUpCode,
      },
      {
        path: '/auth/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
      },
      {
        path: '/auth/reset-password',
        name: 'Reset Password',
        component: ResetPassword,
      },
      {
        path: '/org/setup',
        name: 'Organization Setup',
        component: OrgSetup,
      },
    ],
  },
  {
    id: 'Payment-Processor-Redirect',
    path: '/integration/payment-processor/redirect',
    component: PaymentProcessorRedirect,
    name: 'Payment Processor Redirect',
  },
  {
    id: 'Error',
    path: '/auth',
    icon: <PeopleOutline />,
    children: [
      {
        path: '/error/404',
        name: '404 Page',
        component: Page404,
      },
      {
        path: '/error/500',
        name: '500 Page',
        component: Page500,
      },
    ],
  },
];
export const fullWidthPrivateRoutes = [
  {
    id: 'Org Setup',
    path: '/auth/org-setup',
    component: OrgSetup,
    name: 'Org Setup',
  },
];

// All routes used in Sidebar.js to build the menu hierarchy and links, in that order
export default dashboardRoutes;
