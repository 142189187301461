import React from 'react';

import { Tooltip } from '@material-ui/core';
import { DATE_RANGES, YEAR_RANGES } from '../Constants';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export default function DateRangeActions(props) {
  const { onDateRangeChange, isCustomersByYear } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(6);

  const handleMenuItemClick = (event, index, value) => {
    setSelectedIndex(index);
    onDateRangeChange(value);
  };

  return (
    <ButtonGroup color="default" size="small" variant="text">
      {isCustomersByYear
        ? YEAR_RANGES.map((dateRange, index) => (
            <Tooltip title={dateRange.toolTip} key={dateRange.label}>
              <Button
                color={index === selectedIndex ? 'primary' : 'default'}
                aria-label={dateRange.label}
                onClick={(event) => handleMenuItemClick(event, index, dateRange.value)}>
                {dateRange.label}
              </Button>
            </Tooltip>
          ))
        : DATE_RANGES.map((dateRange, index) => (
            <Tooltip title={dateRange.toolTip} key={dateRange.label}>
              <Button
                color={index === selectedIndex ? 'primary' : 'default'}
                aria-label={dateRange.label}
                onClick={(event) => handleMenuItemClick(event, index, dateRange.value)}>
                {dateRange.label}
              </Button>
            </Tooltip>
          ))}
    </ButtonGroup>
  );
}
