import React, { useContext, useState } from 'react';
import {
  Add,
  DescriptionOutlined,
  EditOutlined,
  FileCopyOutlined,
  Repeat,
  Replay,
  SaveAltOutlined,
} from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import CausesMoreInfo from './CausesMoreInfo';

import FormattedDate from '../../components/displayValues/FormattedDate';
import NavLink from '../../components/displayValues/NavLink';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../components/UserContext';
import CurrencyText from '../../components/displayValues/CurrencyText';
import GlobalMetricDisplay from '../../components/displayValues/GlobalMetricDisplay';
import { CAUSES, USER_ROLES } from '../../Constants';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import CauseText from '../../components/displayValues/CauseText';
import SlugText from '../../components/displayValues/SlugText';
import Typography from '@material-ui/core/Typography';
import Navlink from '../../components/displayValues/NavLink';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default function Causes(props) {
  const { orgPublicId, authenticatedUser, userRole } = useContext(UserContext);
  const history = useHistory();
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const globalActions = [
    {
      id: 'downoad-causes',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      type: 'downloadLink',
      displayName: 'Download Causes',
      icon: <SaveAltOutlined />,
      parsedResponseType: CAUSES,
      doAction: (filter, queryString) => {
        return ElasticSearchAPIClient.getCausesToDownload(authenticatedUser, filter, queryString, downloadSourceFields);
      },
    },
    {
      id: 'global-action-2',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      displayComponent: (
        <Button
          onClick={() => {
            history.push('/causes/new');
          }}
          data-cy={'cause-new'}
          variant="contained"
          color="primary"
          startIcon={<Add />}>
          New Cause
        </Button>
      ),
    },
  ];
  const globalMetricsDisplay = {
    aggregations: [
      {
        id: 'cause-count',
        field: 'slug.keyword', //for elastic search query
        aggregateFunction: 'value_count',
        label: 'Total Causes',
        dataType: 'number',
        displayOrder: 1,
      },
      {
        id: 'cause-sum',
        field: 'totalTransactionAmount', //for elastic search query
        aggregateFunction: 'sum',
        label: 'Total Payments',
        dataType: 'currency',
        displayOrder: 2,
      },
      {
        id: 'cause-avg',
        field: 'totalTransactionAmount', //for elastic search query
        aggregateFunction: 'avg',
        label: 'Average Payments',
        dataType: 'currency',
        displayOrder: 3,
      },
    ],
    doAction: (data) => {
      return <GlobalMetricDisplay data={data}></GlobalMetricDisplay>;
    },
  };
  const bulkActions = [
    {
      id: 'downoad-causes',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      type: 'downloadLink',
      displayName: 'Download Causes',
      icon: <SaveAltOutlined />,
      parsedResponseType: CAUSES,
      doAction: (ids) => {
        return ElasticSearchAPIClient.getCausesByIds(authenticatedUser, ids, downloadSourceFields);
      },
    },
  ];
  const singleRowActions = [
    {
      id: 'cause-transaction',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      displayName: 'Show Cause Transactions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <NavLink
          href={'/causes/' + rowData.id + '/transactions'}
          text={<DescriptionOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'cause-subscription',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      displayName: 'Show Cause Subscription',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <NavLink
          href={'/causes/' + rowData.id + '/subscriptions'}
          text={<Repeat fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
      icon: <Replay fontSize="small" color="secondary" />,
    },
    {
      id: 'cause-editor',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      displayName: 'Edit Cause',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <NavLink
          href={'/causes/' + rowData.id + '/editor'}
          text={<EditOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
      icon: <Replay fontSize="small" color="secondary" />,
    },
    {
      id: 'campaign-URL',
      visible: (userRole >= USER_ROLES.CAMPAIGN_MANAGER),
      displayName: 'Copy Marketing Page URLs',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/causes/' + rowData.slug + '/marketing'}
          text={<FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
      icon: <Replay fontSize="small" color="secondary" />,
    },
  ];
  const sourceFields = [
    'name',
    'totalTransactionCount',
    'totalTransactionAmount',
    'lastTransactionDate',
    'organization.publicId',
    'amount',
    'slug',
    'totalCustomerCount',
    'dateCreated',
  ];

  const downloadSourceFields = [
    'name',
    'totalTransactionCount',
    'totalTransactionAmount',
    'lastTransactionDate',
    'organization.publicId',
    'amount',
    'slug',
    'totalCustomerCount',
    'dateCreated',
    'taxDeductible',
    'zakatEligible',
    'displayOnKiosk',
    'displayOrder',
  ];

  const columns = [
    {
      id: 'name',
      field: 'name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        return <CauseText label={rowdata['name']} />;
      },
    },
    {
      id: 'slug',
      field: 'slug',
      disablePadding: true,
      label: 'Slug',
      cellContent: (rowdata) => {
        return <SlugText label={rowdata['slug']} />;
      },
    },
    {
      id: 'totalCustomerCount',
      field: 'totalCustomerCount',
      align: 'center',
      disablePadding: true,
      label: 'Total Customers',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['totalCustomerCount']}</React.Fragment>;
      },
    },
    {
      id: 'totalTransactionCount',
      field: 'totalTransactionCount',
      align: 'center',
      disablePadding: true,
      label: 'Txn Count',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['totalTransactionCount']}</React.Fragment>;
      },
    },
    {
      id: 'lastTransactionDate',
      field: 'lastTransactionDate',
      disablePadding: false,
      label: 'Last Txn',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.transactionDate} />;
      },
    },
    {
      id: 'totalTransactionAmount',
      field: 'totalTransactionAmount',
      align: 'right',
      disablePadding: false,
      label: 'Gross Amount',
      cellContent: (rowdata) => {
        return (
          <React.Fragment>
            {rowdata['totalTransactionAmount'] ? (
              <Typography style={{ fontWeight: 700 }}>
                <CurrencyText amount={rowdata['totalTransactionAmount']} />
              </Typography>
            ) : (
              '-'
            )}
          </React.Fragment>
        );
      },
    },
  ];
  const label = 'Causes';
  const onMoreInfoClick = (rowData) => {
    return <CausesMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          label={label}
          bulkActions={bulkActions}
          globalActions={globalActions}
          singleRowActions={singleRowActions}
          columns={columns}
          sourceFields={sourceFields}
          indexType={CAUSES}
          onMoreInfoClick={onMoreInfoClick}
          globalMetricsDisplay={globalMetricsDisplay}
        />}
    </React.Fragment>
  );
}
