import React from 'react';
import { renderer } from './renderer';
import Grid from '@material-ui/core/Grid';

export default function DynamicFormFragment(props) {
  const { fields, register, watch, errors, setValue, control } = props;
  return (
    <React.Fragment>
      {fields.map((field) => {
        const { name, gridWidth = 12 } = field;
        const watchFieldValue = field.visibleWhenFieldValue ? watch(field.visibleWhenFieldValue.field) : null;
        if (field.fieldType === 'hidden') {
          return <div key={name}>{renderer(field, register, errors, watch, setValue, null, null, control)}</div>;
        }
        return (
          (!field.visibleWhenFieldValue || field.visibleWhenFieldValue.valueIn.includes(watchFieldValue)) && (
            <Grid item xs={gridWidth} key={name}>
              {renderer(field, register, errors, watch, setValue, null, null, control)}
            </Grid>
          )
        );
      })}
    </React.Fragment>
  );
}
