import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { USER_ROLES } from '../../Constants';
import Page401 from '../error/Page401';

export default function TransactionNew() {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState('');
  useEffect(() => {
    setPaymentMethod('CARD');
  }, []);

  const fields = [
    {
      name: 'customer-information',
      label: 'Customer Information',
      fieldType: 'section-header',
    },
    {
      name: 'name',
      label: 'Full Name',
      fieldType: 'customerAutoComplete',
    },
    {
      name: 'email',
      label: 'Email',
      fieldType: 'customerAutoComplete',
    },
    {
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
    },
    {
      name: 'address-information',
      label: 'Address',
      fieldType: 'section-header',
    },
    {
      name: 'address',
      label: 'Address',
      fieldType: 'address',
      value: {
        addressApt: '',
        addressCity: '',
        addressState: '',
        addressStreet: '',
        addressZipCode: '',
      },
    },
    {
      name: 'cause-information',
      label: 'Cause Information',
      fieldType: 'section-header',
    },
    {
      gridWidth: 8,
      name: 'categoryName',
      label: 'Cause',
      fieldType: 'cause-dropdown',
    },
    {
      gridWidth: 4,
      name: 'categoryAmount',
      label: 'Amount',
      fieldType: 'amount',
    },
    {
      name: 'transaction-date',
      label: ' Transaction Date',
      fieldType: 'section-header',
    },
    {
      name: 'transactionDate',
      label: 'Transaction Date',
      fieldType: 'datetime',
      helperText: 'Transaction will be scheduled for ',
      noMinDate: true,
      gridWidth: 6,
    },
    {
      name: 'payment-information',
      label: 'Payment Information',
      fieldType: 'section-header',
    },
    {
      name: 'paymentMethod',
      fieldType: 'radio-group',
      value: 'CARD',
      horizontal: true,
      options: [
        {
          label: 'Default',
          value: 'DEFAULT',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
        {
          label: 'Other',
          value: 'OTHER',
        },
      ],
    },
    {
      name: 'otherTransactionReferenceId',
      label: 'Reference Id',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['OTHER'],
      },
    },
    {
      name: 'defaultPaymentMethod',
      label: 'Default Payment Method',
      readOnly: true,
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['DEFAULT'],
      },
    },
    {
      name: 'stripe-payment',
      fieldType: 'stripe-payment',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['CARD'],
      },
    },
    {
      name: 'achPaymentButton',
      label: 'Link Bank Account',
      fieldType: 'ach-link-button',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['BANK'],
      },
    },
    {
      name: 'saveAsDefault',
      label: 'Save as default',
      fieldType: 'checkbox',
      value: true,
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['CARD', 'BANK'],
      },
    },
    {
      name: 'sendEmail',
      label: 'Send Email To Customer',
      fieldType: 'checkbox',
      value: true,
    },
    {
      name: 'notes',
      label: 'Comments',
      fieldType: 'textarea',
      required: false,
    },
  ];

  const handleSubmitNewTransaction = async (data) => {
    await GoodbricksLegacyAPIClient.saveTransaction(authenticatedUser, data).then(
      (response) => {
        enqueueSnackbar('Transaction Added Successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        history.push('/transaction/new');
      },
      (error) => {
        enqueueSnackbar('Something went wrong. Please try again... ', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        console.log(error);
      },
    );
  };

  return (
    <React.Fragment>
      {userRole >= USER_ROLES.SUPER_ADMIN ?

        <>
          <Breadcrumbs aria-label="Breadcrumb">
            <NavLink href="/transactions" text="Transactions" color="primary" />
            <Typography component="span">New</Typography>
          </Breadcrumbs>

          <Grid container spacing={6}>
            {/*<Grid item xs={12} md={8}>*/}
            {/*<DividerWithSpacing />*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={6}>
              <DynamicForm
                fields={fields}
                formSubmitCallback={handleSubmitNewTransaction}
                formOptions={{
                  acceptStripePayment: (data) => {
                    return data.paymentMethod === 'CARD';
                  },
                  submitButtonLabel: 'Create New Transaction',
                }}
              />
            </Grid>
          </Grid>
        </> : <Page401 ></Page401>
      }
    </React.Fragment>
  );
}
