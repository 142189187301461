import React, { useContext, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { UserContext } from '../../../components/UserContext';
import FormWizardController from '../../../components/dynamicForm/FormWizardController';
import TransactionCsvUpload from './TransactionCsvUpload';
import TransactionCsvVerify from './TransactionCsvVerify';
import TransactionImportConfirmation from './TransactionImportConfirmation';
import { USER_ROLES } from '../../../Constants';
import Page401 from '../../error/Page401';

export default function TransactionsImportWizard() {
  const { userRole } = useContext(UserContext);
  const [rowData, setRowData] = useState();
  const handleCsvDataChange = (rowData) => {
    setRowData(rowData);
  };

  const submitCsvDataChanges = () => { };
  const stepComponents = [
    {
      component: TransactionCsvUpload,
      title: 'Upload CSV',
    },
    {
      component: TransactionCsvVerify,
      title: 'Verify Extracted Transaction',
    },
    {
      component: TransactionImportConfirmation,
      title: 'Import Confirmation',
    },
  ];

  return (
    <React.Fragment>
      {userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Box>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/transactions" text="Transactions" color="primary" />
              <Typography component="span">Import Transactions</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <FormWizardController
                    data={rowData}
                    handleDataChange={handleCsvDataChange}
                    submitDataChanges={submitCsvDataChanges}
                    steps={stepComponents}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </> : <Page401></Page401>
      }
    </React.Fragment>
  );
}
