import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LabelValueGridItem from '../../components/displayValues/LabelValueGridItem';
import { UserContext } from '../../components/UserContext';
import FormattedDate from '../../components/displayValues/FormattedDate';

import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';

export default function CausesMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [causeData, setCauseData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getCauseById(authenticatedUser, rowData.id).then((response) => {
      const causes = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setCauseData(causes[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography variant="body1" color="primary" gutterBottom data-cy={`cause_details_label_${causeData?.id}`}>
          Cause Details
        </Typography>
        <Box my={3} />
        <Grid container direction="row" spacing={3} alignItems="center">
          <LabelValueGridItem
            label={'Created On'}
            value={<FormattedDate date={causeData?.dateCreated} />}
            id={`cause_date_${causeData?.id}_value`}
          />
          {/*<LabelValueGridItem label={'Cause Id'} value={causeData?.dateCreated} id={`cause_id_${causeData?.id}_value`} />*/}
          <LabelValueGridItem
            label={'Tax Deductible'}
            value={causeData?.taxDeductible ? 'Yes' : 'No'}
            id={`taxDeductible_${causeData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Zakat Eligible'}
            value={causeData?.zakatEligible ? 'Yes' : 'No'}
            id={`zakatEligible_${causeData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Display on Kiosk'}
            value={causeData?.displayOnKiosk ? 'Yes' : 'No'}
            id={`displayOnKiosk_${causeData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Display Order'}
            value={causeData?.displayOrder}
            id={`displayOrder_${causeData?.id}_value`}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
}
