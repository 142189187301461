import { IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import DateRangeActions from '../DateRangeActions';
import Button from '@material-ui/core/Button';
import CsvExport from '../CsvExport';

const DatatableToolbar = (props) => {
  const {
    numSelected,
    bulkActions,
    tableOptions,
    onDateRangeChange,
    onQueryChange,
    displayGlobalMetricData,
    globalActions = [],
    label,
    isCustomersByYear,
  } = props;
  const theme = useTheme();
  const [searchFieldValue, setSearchFieldValue] = useState('');

  const renderGlobalAction = (action) => {
    switch (action.type) {
      case 'withActionButtonProps':
        return (
          <Button style={{ marginRight: '0.5rem' }} onClick={() => action.doAction()} {...action.actionButtonProps}>
            {action.displayComponent}
          </Button>
        );
      case 'downloadLink':
        return (
          <Tooltip title={action.displayName} key={action.id}>
            <span className="gbadm-MuiButtonBase-root gbadm-MuiIconButton-root">
              <CsvExport
                asyncExportMethod={action.doAction}
                parsedResponseType={action.parsedResponseType}
                filename={action.id}>
                {action.icon}
              </CsvExport>
            </span>
          </Tooltip>
        );
      default:
        return action.displayComponent;
    }
  };

  const renderBulkAction = (action) => {
    switch (action.type) {
      case 'downloadLink':
        return (
          <Tooltip title={action.displayName} key={action.id}>
            <span className="gbadm-MuiButtonBase-root gbadm-MuiIconButton-root">
              <CsvExport
                asyncExportMethod={action.doAction}
                parsedResponseType={action.parsedResponseType}
                filename={action.id}>
                {action.icon}
              </CsvExport>
            </span>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title={action.displayName} key={action.id}>
            <IconButton aria-label={action.displayName} onClick={action.doAction}>
              {action.icon}
            </IconButton>
          </Tooltip>
        );
    }
  };

  const globalActionItems = globalActions.map((globalAction) => {
    return (globalAction.visible &&
      <Grid item key={globalAction.id}>
        {renderGlobalAction(globalAction)}
      </Grid>
    );
  });

  return (
    <React.Fragment>
      {numSelected > 0 ? (
        <Grid container direction="row" spacing={6} alignItems="center">
          <Grid item>
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          </Grid>
          <Grid item>
            {bulkActions.map((action) => {
              return (action.visible && renderBulkAction(action));
            })}
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <Grid container direction="row" alignItems="flex-end">
            {displayGlobalMetricData && (
              <Grid item xs={12}>
                {displayGlobalMetricData()}
              </Grid>
            )}
            {tableOptions?.title && (
              <Grid item md sm xs>
                {tableOptions.title}
              </Grid>
            )}
            {!tableOptions?.hideSearchQuery && (
              <Grid item md={3} sm={4} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder={label ? `Search ${label}....` : 'Search ....'}
                  onChange={(e) => {
                    setSearchFieldValue(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key == 'Enter') onQueryChange(searchFieldValue);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={(e) => {
                            onQueryChange(searchFieldValue);
                          }}>
                          <SearchIcon style={{ color: theme.palette.grey[600] }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {onDateRangeChange && (
              <Grid item md sm xs>
                <DateRangeActions isCustomersByYear={isCustomersByYear} onDateRangeChange={onDateRangeChange} />
              </Grid>
            )}
            {globalActionItems && (
              <Grid item md sm xs>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                  {globalActionItems}
                </Grid>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DatatableToolbar;
