import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import dayjs from 'dayjs';

export default function FormattedDate(props) {
  return (
    <Tooltip title={dayjs(props.date).format('DD MMM YYYY @ hh:mm a')}>
      <Typography variant="body1" component="span" {...props}>
        {dayjs(props.date).format('DD MMM YYYY')}
      </Typography>
    </Tooltip>
  );
}
