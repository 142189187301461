import React, { useContext, useEffect, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { Box } from '@material-ui/core';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { UserContext } from '../../components/UserContext';
import { USER_ROLES } from '../../Constants';

export default function PaymentProcessor(props) {
  console.log('PaymentProcessor', props);
  const [stripeConnected, setStripeConnected] = useState(false);
  const [connectUrl, setConnectUrl] = useState(false);
  const { authenticatedUser, userRole } = useContext(UserContext);

  useEffect(() => {
    GoodbricksLegacyAPIClient.isStripeConnected(authenticatedUser).then((r) => {
      setConnectUrl(r.connectUrl);
      setStripeConnected(r.isStripeAccountConnected);
    });
  }, []);
  const handleStripeConnect = () => {
    GoodbricksLegacyAPIClient.connectStripeWithConnectUrl(authenticatedUser, connectUrl).then((r) => { });
  };
  const handleStripeDisconnect = () => {
    GoodbricksLegacyAPIClient.disconnectStripe(authenticatedUser).then((r) => {
      setStripeConnected(r.isStripeAccountConnected);
    });
  };
  return (
    userRole >= USER_ROLES.VIEW_ONLY &&
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Connect Your Payment Processor
        </Typography>
        <Box my={5} />
        <Typography variant="body2" gutterBottom>
          Click "Disconnect Stripe" button below to unlink your Stripe Account from your Goodbricks Account.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please Note: Once you disconnect, your Goodbricks account will enter read-only mode. You will not be able to
          see customer details.
        </Typography>
        <Box my={5} />
        {userRole >= USER_ROLES.SUPER_ADMIN && (stripeConnected ? (
          <Button variant="contained" onClick={handleStripeDisconnect} color="secondary">
            Disconnect Stripe
          </Button>
        ) : (
          <Button mr={2} variant="contained" href={connectUrl ? connectUrl : '#'} color="primary">
            Connect Stripe
          </Button>
        ))}
      </CardContent>
    </Card>
  );
}
