import styled from 'styled-components';
import React from 'react';

import { Card as MuiCard, CardContent, CardHeader } from '@material-ui/core';

import { spacing } from '@material-ui/system';

import { Line } from 'react-chartjs-2';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { green } from '@material-ui/core/colors';

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 400px;
`;

export default function LineChart({ incomeChartData }) {
  const data = (canvas) => {
    // const ctx = canvas.getContext('2d');
    // const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    return {
      labels: incomeChartData?.dates,
      datasets: [
        {
          label: 'Income',
          fill: true,
          backgroundColor: green['A100'],
          borderColor: green['A400'],
          data: incomeChartData?.vals,
        },
        {
          label: 'Cumulative Income',
          fill: true,
          backgroundColor: deepPurple[50],
          borderColor: deepPurple[500],
          // borderDash: [4, 4],
          data: incomeChartData?.cumVals,
        },
      ],
    };
  };
  const cumVals = incomeChartData ? incomeChartData.cumVals : [];
  const maxVal = cumVals ? parseInt(cumVals[cumVals.length - 1], 10) : 0;
  const yInt = Math.round((maxVal * 1.2) / 10 / 10000) * 10000;

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.0)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            maxTicksLimit: 10,
            stepSize: yInt,
            callback: function (value, index, values) {
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0.0375)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <Card pb={3}>
      <CardHeader title="Total revenue" />
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
