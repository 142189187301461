import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function AutoRoute({ href }) {
  const history = useHistory();
  useEffect(() => {
    history.push(href);
  });

  return <React.Fragment />;
}
