import React from 'react';

import { createGenerateClassName, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import maTheme from './theme';
import Routes from './routes/Routes';
import { SnackbarProvider } from 'notistack';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

const generateClassName = createGenerateClassName({
  seed: 'gbadm',
});

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <StylesProvider injectFirst generateClassName={generateClassName}>
      <MuiThemeProvider theme={maTheme[0]}>
        <ThemeProvider theme={maTheme[0]}>
          <SnackbarProvider>
            <Elements
              stripe={stripePromise}
              options={{
                fonts: [
                  {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400',
                  },
                ],
              }}>
              <Routes />
            </Elements>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
