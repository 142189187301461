import React from 'react';
import styled from 'styled-components';

import {
  Card as MuiCard,
  CardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from '@material-ui/core';

import { spacing } from '@material-ui/system';

import { Doughnut } from 'react-chartjs-2';
import CurrencyText from '../../components/displayValues/CurrencyText';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
import lightGreen from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';
import CauseText from '../../components/displayValues/CauseText';
import yellow from '@material-ui/core/colors/yellow';

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const StyledList = withStyles({
  root: {
    position: 'absolute',
    top: '4px',
    right: '16px',
    height: '20px',
    padding: '1px 0',
  },
})(List);

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

export default function PieChart({ id, title, value, updateData, menuOptions }) {
  const incomeByCategories = value;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index, id, val) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    updateData(event, id, val);
  };

  const menuItemOptions = menuOptions?.map((option, index) => (
    <MenuItem
      key={id + option.label}
      selected={option === 'Pyxis'}
      onClick={(event) => {
        handleMenuItemClick(event, index, id, option.value);
        setAnchorEl(null);
      }}>
      {option.label}
    </MenuItem>
  ));

  const data = {
    labels: incomeByCategories.labels,
    datasets: [
      {
        data: incomeByCategories.incomes,
        borderWidth: 5,
        backgroundColor: [
          deepPurple[500],
          blue[500],
          teal[500],
          lightGreen[500],
          red[500],
          orange[500],
          pink[500],
          pink[300],
          pink[100],
          yellow[500],
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 85,
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={4} component="span">
          {title}
        </Typography>
        <div>
          <List component="nav">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              onClick={handleClick}>
              <ListItemText
                // primary="When device is locked"
                secondary={menuOptions && menuOptions[selectedIndex]['label']}
              />
            </ListItem>
          </List>
          <Menu
            id={id + ' menu'}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}>
            {menuItemOptions}
          </Menu>
        </div>
        <ChartWrapper>
          <DoughnutInner variant="h4">
            <Typography variant="h4">Income</Typography>
            <Typography variant="caption">by cause</Typography>
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cause</TableCell>
              <TableCell align="right">Income</TableCell>
              <TableCell align="right">Transaction #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incomeByCategories &&
              incomeByCategories?.labels?.map((label, index) => {
                return (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      <CauseText label={label} />
                    </TableCell>
                    <TableCell align="right">
                      <CurrencyText amount={incomeByCategories.incomes[index]} />
                    </TableCell>
                    <TableCell align="right">{incomeByCategories.transactions[index]}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

// export default withTheme(PieChart);
