import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { TableCell, TableRow } from '@material-ui/core';

export default function ListingSkeleton(props) {
  const { rowsPerPage, columns } = props;
  const skeletonRows = [];
  for (var i = 0; i < rowsPerPage; i++) {
    skeletonRows.push(
      <TableRow key={i}>
        <TableCell colSpan={columns}>
          <Skeleton />
        </TableCell>
      </TableRow>,
    );
  }
  return <React.Fragment>{skeletonRows}</React.Fragment>;
}
