import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { useSnackbar } from 'notistack';
import { USER_ROLES } from '../../Constants';

export default function BusinessProfile() {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [businessProfile, setBusinessProfile] = useState();

  const fields = [
    {
      name: 'business-information',
      label: 'Business Information',
      fieldType: 'section-header',
    },

    {
      name: 'ein',
      label: 'EIN',
      fieldType: 'masked',
      pattern: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      readOnly: true,
      minLength: 10,
      gridWidth: 6,
      value: businessProfile?.ein ? businessProfile.ein : '',
    },
    {
      name: 'url',
      label: 'Website URL',
      fieldType: 'text',
      inputType: 'url',
      readOnly: true,
      gridWidth: 6,
      value: businessProfile?.url,
    },
    {
      name: 'name',
      label: 'Name',
      gridWidth: 6,
      value: businessProfile?.name ? businessProfile.name : '',
    },
    {
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
      gridWidth: 6,
      value: businessProfile?.phone,
    },
    {
      name: 'address-information',
      label: 'Address',
      fieldType: 'section-header',
    },
    {
      name: 'address',
      label: 'Address',
      fieldType: 'address',
      value: {
        addressApt: businessProfile?.addressApt,
        addressCity: businessProfile?.addressCity,
        addressState: businessProfile?.addressState,
        addressStreet: businessProfile?.addressStreet,
        addressZipCode: businessProfile?.addressZipCode,
      },
    },
  ];

  useEffect(() => {
    GoodbricksLegacyAPIClient.fetchBusinessSettings(authenticatedUser).then((businessProfileData) => {
      return setBusinessProfile({
        ...businessProfileData,
      });
    });
  }, []);

  const handleSaveBusinessSettings = async (data) => {
    GoodbricksLegacyAPIClient.saveBusinessSettings(authenticatedUser, data).then(
      (response) => {
        enqueueSnackbar('Settings Saved', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      },
      (rejectedResult) => {
        enqueueSnackbar('Something went wrong. Please try again... ', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      },
    );
  };

  return (
    <React.Fragment>
      {userRole >= USER_ROLES.VIEW_ONLY && businessProfile && (
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <DynamicForm
              fields={fields}
              formSubmitCallback={handleSaveBusinessSettings}
              formOptions={{
                submitButtonLabel: 'Save Settings',
                visible: (userRole >= USER_ROLES.SUPER_ADMIN)
              }}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
