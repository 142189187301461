import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import FormWizardController from '../../../components/dynamicForm/FormWizardController';
import CauseDetails from './CauseDetails';
import CauseAmountSettings from './CauseAmountSettings';
import CauseDatesAndFrequency from './CauseDatesAndFrequency';
import GoodbricksLegacyAPIClient from '../../../restClients/GoodbricksLegacyAPIClient';
import { UserContext } from '../../../components/UserContext';
import { useSnackbar } from 'notistack';
import { USER_ROLES } from '../../../Constants';
import Page401 from '../../error/Page401';

export default function CausePageEditor(props) {
  const { authenticatedUser, orgPublicId, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    match: { params },
  } = props;

  const [cause, setCause] = useState();

  const getFrequency = (causeData) => {
    return causeData.showOneTimeOptionOnly
      ? 'one-time-only'
      : causeData.showRecurringOptionOnly
        ? 'recurring-only'
        : causeData.defaultRecurringOptionTrue
          ? 'recurring-default'
          : 'one-time-default';
  };

  const updateCauseDataForFrequency = (data) => {
    switch (data.frequency) {
      case 'one-time-only':
        data.showOneTimeOptionOnly = true;
        data.showRecurringOptionOnly = false;
        data.defaultRecurringOptionTrue = false;
        data.defaultOneTimeTrue = false;
        break;
      case 'recurring-only':
        data.showOneTimeOptionOnly = false;
        data.showRecurringOptionOnly = true;
        data.defaultRecurringOptionTrue = false;
        data.defaultOneTimeTrue = false;
        break;
      case 'one-time-default':
        data.showOneTimeOptionOnly = false;
        data.showRecurringOptionOnly = false;
        data.defaultRecurringOptionTrue = false;
        data.defaultOneTimeTrue = true;
        break;
      case 'recurring-default':
        data.showOneTimeOptionOnly = false;
        data.showRecurringOptionOnly = false;
        data.defaultRecurringOptionTrue = true;
        data.defaultOneTimeTrue = false;
        break;
      default:
      // code block
    }
    return data;
  };

  const handleCauseChange = (data) => {
    data = updateCauseDataForFrequency(data);
    setCause({ ...cause, ...data });
  };

  const submitCauseChanges = () => {
    if (cause.id) {
      GoodbricksLegacyAPIClient.updateCause(authenticatedUser, {
        ...cause,
        defaultAmounts: cause.defaultAmounts.join(','),
      }).then(
        (response) => {
          enqueueSnackbar('Cause Saved Successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        },
        (error) => {
          enqueueSnackbar('Something went wrong. Please try again... ', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          console.log(error);
        },
      );
    } else {
      GoodbricksLegacyAPIClient.saveCause(authenticatedUser, {
        ...cause,
        defaultAmounts: cause.defaultAmounts.join(','),
      }).then(
        (response) => {
          enqueueSnackbar('Cause Created Successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        },
        (error) => {
          enqueueSnackbar('Something went wrong. Please try again... ', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          console.log(error);
        },
      );
    }
  };

  const stepComponents = [
    {
      component: CauseDetails,
      title: 'Cause Details',
    },
    {
      component: CauseAmountSettings,
      title: 'Amount Settings',
    },
    {
      component: CauseDatesAndFrequency,
      title: 'Dates and Frequency',
    },
  ];

  useEffect(() => {
    console.log('params.causeId', params.causeId);
    if (params.causeId) {
      GoodbricksLegacyAPIClient.retrieveCauseById(authenticatedUser, params.causeId.substr(4)).then((cause) => {
        // console.log('inside params.causeId', params.causeId);
        return setCause({
          ...cause,
          frequency: getFrequency(cause),
          organization: {
            name: orgPublicId,
            publicId: orgPublicId,
          },
        });
      });
    } else {
      const newCause = {
        amountReadOnly: false,
        collectMailingAddress: false,
        coverFeePercentage: '3',
        coverFees: true,
        currencyPrefix: '$',
        defaultAmount: 0,
        defaultAmounts: [],
        defaultRecurringOptionTrue: false,
        defaultOneTimeTrue: true,
        description: '',
        displayOrder: 999,
        featureImageUrl: '',
        featureVideoUrl: null,
        frequency: 'one-time-default',
        name: '',
        organization: { name: orgPublicId, publicId: orgPublicId },
        paymentIterations: 12,
        publicVisible: false,
        recurringInterval: 'MONTH',
        showNotes: false,
        showOnBehalfOf: false,
        showOneTimeOptionOnly: false,
        showRecurringOptionOnly: false,
        slug: '',
        startDate: null,
        summaryDescription: '',
        tags: '',
        taxDeductible: true,
        text: '',
        title: '',
        zakatEligible: false,
      };
      setCause({ ...newCause });
    }
  }, []);

  useEffect(() => {
    if (cause) {
      if (window.CauseWidget) {
        window.CauseWidget.mount({ cause: cause });
      }
    }
  }, [cause]);

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.CAMPAIGN_MANAGER ?
        <>
          <Box my={9}>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/causes" text="Causes" color="primary" />
              {!!params.causeId ? (
                <Typography component="span">Edit</Typography>
              ) : (
                <Typography component="span">New</Typography>
              )}
            </Breadcrumbs>
          </Box>
          {cause && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <FormWizardController
                      data={cause}
                      handleDataChange={handleCauseChange}
                      submitDataChanges={submitCauseChanges}
                      steps={stepComponents}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={6} style={{ background: grey[800] }} alignItems="center">
                  <Box p={10}>
                    <Grid item xs={12}>
                      {console.log('Mounting cause widget ')}
                      <div id="cause-widget" style={{ background: 'white' }} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}
