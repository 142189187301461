// @flow
import React from 'react';
import GoodbricksTextField from './fields/GoodbricksTextField';
import GoodbricksAutocompleteDropdownField from './fields/GoodbricksAutocompleteDropdownField';
import AddressTextField from './fields/AddressTextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StripeTextFieldElement from './fields/StripeTextFieldElement';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Controller } from 'react-hook-form';
import FormLabel from '@material-ui/core/FormLabel';
import CustomerAutocompleteField from './fields/CustomerAutocompleteField';
import CauseAutocompleteDropdownField from './fields/CauseAutocompleteDropdownField';
import 'react-datepicker/dist/react-datepicker.css';
import GoodbricksDateTimePickerField from './fields/GoodbricksDateTimePickerField';
import TextMaskedFormatField from './fields/TextMaskedFormatField';
import AmountTextMaskedFormatField from './fields/AmountTextMaskedFormatField';
import { PHONE_MASK } from '../../Constants';
import EmailChips from './fields/EmailChips';
import RichTextEditor from './fields/RichTextEditor';
import ACHPaymentButton from './fields/ACHPaymentButton';

export const renderer = (field, register, errors, watch, setValue, getValues, trigger, control) => {
  const {
    name,
    label,
    fieldType = 'text',
    inputType,
    fullwidth = true,
    required = true,
    rows = 4,
    pattern,
    minLength,
    maxLength,
    patternErrorMessage,
    maxLengthErrorMessage,
    minLengthErrorMessage,
    disabled = false,
    readOnly = false,
    onChange,
    value = '',
    options,
    horizontal = false,
    passwordField,
    className,
    placeholderText,
  } = field;

  switch (fieldType) {
    case 'text':
      return (
        <React.Fragment>
          <GoodbricksTextField
            key={name}
            id={name}
            name={name}
            label={label}
            type={inputType}
            // value={value}
            defaultValue={value}
            placeholder={placeholderText}
            disabled={disabled}
            onChange={onChange}
            error={errors && !!errors[name]}
            helperText={errors && errors[name] && errors[name].message}
            inputRef={
              register &&
              register({
                required: {
                  value: required,
                  message: label + ' is required.',
                },
                pattern: {
                  value: pattern
                    ? pattern
                    : inputType === 'email'
                    ? /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
                    : null,
                  message: patternErrorMessage ? patternErrorMessage : label + ' is not a valid input.',
                },
                minLength: {
                  value: minLength,
                  message: minLengthErrorMessage
                    ? minLengthErrorMessage
                    : label + ' must be at least ' + minLength + ' characters.',
                },
                maxLength: {
                  value: maxLength,
                  message: maxLengthErrorMessage
                    ? maxLengthErrorMessage
                    : label + ' must be at most' + maxLength + ' characters.',
                },
              })
            }
            inputProps={{
              readOnly: readOnly,
              'data-cy': name,
              key: name,
            }}
            fullWidth={fullwidth}
          />
        </React.Fragment>
      );

    case 'password':
      return (
        <GoodbricksTextField
          id={name}
          name={name}
          label={label}
          type="password"
          onChange={onChange}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          inputRef={register({
            required: {
              value: required,
              message: label + ' is required.',
            },
            // Explanations
            // RegEx            Description
            // (?=.*[a-z])      The string must contain at least 1 lowercase alphabetical character
            // (?=.*[A-Z])      The string must contain at least 1 uppercase alphabetical character
            // (?=.*[0-9])      The string must contain at least 1 numeric character
            // (?=.[!@#\$%\^&])    The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
            // (?=.{8,})        The string must be eight characters or longer
            pattern: {
              // value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
              value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/,
              message: patternErrorMessage ? (
                patternErrorMessage
              ) : (
                <span>
                  Password should contain
                  <br /> An uppercase letter
                  <br /> A lower case letter
                  {/*<br /> A special character*/}
                  <br /> A number
                </span>
              ),
            },
            minLength: {
              value: 8,
              message: minLengthErrorMessage ? minLengthErrorMessage : label + ' must be at least 8 characters.',
            },
            maxLength: {
              value: maxLength,
              message: maxLengthErrorMessage
                ? maxLengthErrorMessage
                : label + ' must be at most' + maxLength + ' characters.',
            },
          })}
          inputProps={{
            readOnly: readOnly,
            'data-cy': name,
          }}
          fullWidth={fullwidth}
        />
      );

    case 'password-confirm':
      return (
        <GoodbricksTextField
          id={name}
          name={name}
          label={label}
          type="password"
          onChange={onChange}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          inputRef={register({
            required: {
              value: required,
              message: label + ' is required.',
            },
            validate: (value) => {
              if (value !== watch(passwordField)) {
                return label + ' does not match.';
              }
            },
          })}
          inputProps={{
            readOnly: readOnly,
            'data-cy': name,
          }}
          fullWidth={fullwidth}
        />
      );

    case 'textarea':
      return (
        <React.Fragment>
          <GoodbricksTextField
            key={name}
            id={name}
            name={name}
            label={label}
            defaultValue={value}
            placeholder={placeholderText}
            onChange={onChange}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].message}
            multiline
            rows={rows}
            inputRef={register({
              required: {
                value: required,
                message: label + ' is required.',
              },
              pattern: {
                value: pattern,
                message: patternErrorMessage ? patternErrorMessage : label + ' is not a valid input.',
              },
              minLength: {
                value: minLength,
                message: minLengthErrorMessage
                  ? minLengthErrorMessage
                  : label + ' must be at least ' + minLength + ' characters.',
              },
              maxLength: {
                value: maxLength,
                message: maxLengthErrorMessage
                  ? maxLengthErrorMessage
                  : label + ' must be at most' + maxLength + ' characters.',
              },
            })}
            inputProps={{
              readOnly: readOnly,
              'data-cy': name,
            }}
            fullWidth={fullwidth}
            className={className}
          />
        </React.Fragment>
      );

    case 'address':
      return (
        <React.Fragment>
          <AddressTextField
            key={name}
            id={name}
            {...field}
            register={register}
            setValue={setValue}
            value={value}
            getValues={getValues}
          />
        </React.Fragment>
      );

    case 'display-text':
      return <React.Fragment>{field.content()}</React.Fragment>;

    case 'checkbox':
      return (
        <Controller
          name={name}
          type="checkbox"
          control={control}
          defaultValue={value}
          render={({ onChange: renderOnChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={name}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e);
                    } else {
                      renderOnChange(e.target.checked);
                    }
                  }}
                  checked={value ? true : false}
                />
              }
              label={label}
            />
          )}
        />
      );

    case 'datetime':
      return <GoodbricksDateTimePickerField {...field} setValue={setValue} register={register} />;

    case 'radio-group':
      return (
        <React.Fragment>
          {/*<Typography gutterBottom>{label}</Typography>*/}
          <FormLabel component="legend">{label}</FormLabel>
          <Controller
            as={
              <RadioGroup row={horizontal}>
                {options?.map((singleOption, index) => {
                  return (
                    <FormControlLabel
                      key={name + '-' + index}
                      value={singleOption.value}
                      control={<Radio onChange={onChange} />}
                      label={singleOption.label}
                    />
                  );
                })}
              </RadioGroup>
            }
            name={name}
            defaultValue={value}
            control={control}
          />
        </React.Fragment>
      );

    case 'hidden':
      return (
        <React.Fragment>
          <input id={name} name={name} value={value} type="hidden" data-cy={name} ref={register()} />
        </React.Fragment>
      );

    case 'phone':
      return (
        <Controller
          as={<TextMaskedFormatField />}
          mask={PHONE_MASK}
          label={label}
          onChange={onChange}
          readOnly={readOnly}
          name={name}
          type="tel"
          defaultValue={value}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          rules={{
            required: {
              value: required,
              message: label + ' is required.',
            },
            validate: (value) => {
              if (value.trim().length < 14) {
                return 'Phone must contain 10 digits';
              }
            },
          }}
          fullWidth={fullwidth}
          control={control}
        />
      );

    case 'masked':
      return (
        <Controller
          as={<TextMaskedFormatField />}
          mask={pattern}
          label={label}
          onChange={onChange}
          readOnly={readOnly}
          name={name}
          type="tel"
          defaultValue={value}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          rules={{
            required: {
              value: required,
              message: label + ' is required.',
            },
            validate: (value) => {
              if (value.trim().length < minLength) {
                return minLengthErrorMessage
                  ? minLengthErrorMessage
                  : label + ' must be at least ' + minLength + ' characters.';
              }
            },
          }}
          fullWidth={fullwidth}
          readOnly={readOnly}
          control={control}
        />
      );

    case 'section-header':
      return (
        <Box mt={5}>
          <Typography variant="h6" component="h2" gutterBottom color="textPrimary">
            {label}
          </Typography>
        </Box>
      );

    case 'amount':
      return (
        <Controller
          as={<AmountTextMaskedFormatField />}
          label={label}
          onChange={onChange}
          readOnly={readOnly}
          name={name}
          defaultValue={value}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          rules={{
            required: {
              value: required,
              message: label + ' is required.',
            },
          }}
          fullWidth={fullwidth}
          control={control}
        />
      );

    case 'stripe-payment':
      return (
        <React.Fragment>
          <StripeTextFieldElement id={name} fullWidth={fullwidth} />
        </React.Fragment>
      );

    case 'text-dropdown':
      return (
        <GoodbricksAutocompleteDropdownField
          setValue={setValue}
          valueAttr={field.valueAttr}
          key={name}
          id={name}
          name={name}
          label={label}
          type={inputType}
          fullwidth={fullwidth}
          options={options}
          register={register}
          required={required}
        />
      );

    case 'cause-dropdown':
      return (
        <CauseAutocompleteDropdownField
          key={name}
          id={name}
          name={name}
          label={label}
          fullwidth={fullwidth}
          register={register}
          required={required}
          setValue={setValue}
          errors={errors}
        />
      );

    case 'customerAutoComplete':
      return (
        <CustomerAutocompleteField
          key={name}
          id={name}
          register={register}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          errors={errors}
          label={label}
          name={name}
          minLength={minLength}
        />
      );
    case 'ach-link-button':
      return <ACHPaymentButton {...field} setValue={setValue} errors={errors} register={register}></ACHPaymentButton>;

    case 'emailChips':
      return <EmailChips setValue={setValue} errors={errors} register={register} {...field} />;

    case 'richTextEditor':
      return <RichTextEditor {...field}></RichTextEditor>;

    default:
      return <div>No mapped field</div>;
  }
};
