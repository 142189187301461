import React from 'react';
import ErrorText from './ErrorText';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';

export default function StatusText(props) {
  const { status } = props;

  if (status.startsWith('Error:')) {
    return <ErrorText component="span">{status.replace('Error:', '')}</ErrorText>;
  } else {
    const statusUppercase = status.toString().toUpperCase();
    switch (statusUppercase) {
      case 'SUCCEEDED':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: green[50], color: green['900'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      case 'ACTIVE':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: green[50], color: green['900'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      case 'PLEDGED':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: green[50], color: green['900'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      case 'COMPLETED':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: indigo[50], color: indigo['A700'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      case 'CANCELED':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: grey[100], color: grey['A700'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      case 'RETURNED':
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: grey[100], color: grey['A700'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
      default:
        return (
          <Chip
            label={statusUppercase}
            size="small"
            style={{ backgroundColor: grey[100], color: grey['A700'], fontSize: '0.75rem', fontWeight: 700 }}
          />
        );
    }
  }
}
