import React, { useContext, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { UserContext } from '../../../components/UserContext';
import FormWizardController from '../../../components/dynamicForm/FormWizardController';
import PledgeCsvUpload from './PledgeCsvUpload';
import PledgeCsvVerify from './PledgeCsvVerify';
import PledgeImportConfirmation from './PledgeImportConfirmation';
import { USER_ROLES } from '../../../Constants';
import Page401 from '../../error/Page401';

export default function PledgesImportWizard() {
  const { userRole } = useContext(UserContext);
  const [rowData, setRowData] = useState();
  const handleCsvDataChange = (rowData) => {
    setRowData(rowData);
  };

  const submitCsvDataChanges = () => { };
  const stepComponents = [
    {
      component: PledgeCsvUpload,
      title: 'Upload CSV',
    },
    {
      component: PledgeCsvVerify,
      title: 'Verify Extracted Pledges',
    },
    {
      component: PledgeImportConfirmation,
      title: 'Import Confirmation',
    },
  ];

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Box>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/pledgess" text="Pledgess" color="primary" />
              <Typography component="span">Import Pledges</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <FormWizardController
                    data={rowData}
                    handleDataChange={handleCsvDataChange}
                    submitDataChanges={submitCsvDataChanges}
                    steps={stepComponents}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}
