import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LabelValueGridItem from '../../components/displayValues/LabelValueGridItem';
import { UserContext } from '../../components/UserContext';
import CurrencyText from '../../components/displayValues/CurrencyText';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import FormattedDate from '../../components/displayValues/FormattedDate';

function SubscriptionMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [subscriptionData, setSubscriptionData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getSubscriptionById(authenticatedUser, rowData.id).then((response) => {
      const subscriptions = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setSubscriptionData(subscriptions[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography
          variant="body1"
          color="primary"
          gutterBottom
          data-cy={`subscription_details_label_${subscriptionData?.id}`}>
          Subscription Details
        </Typography>
        <Box my={3}>
          <Grid container direction="row" spacing={3} alignItems="center">
            <LabelValueGridItem
              label={'Subscription ID'}
              value={subscriptionData?.id}
              id={`subscription_id${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Cycles Completed'}
              value={subscriptionData?.paymentCyclesPerformed + ' of ' + subscriptionData?.paymentCyclesToPerform}
              id={`cycles_completed_${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Date Created'}
              value={<FormattedDate date={subscriptionData?.dateCreated} />}
              id={`date_created_${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Projected Total Amount'}
              value={
                subscriptionData?.projectedTotalAmount && (
                  <CurrencyText amount={subscriptionData?.projectedTotalAmount} />
                )
              }
              id={`projected_total_amount_${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Amount to Date'}
              value={subscriptionData?.amountToDate && <CurrencyText amount={subscriptionData?.amountToDate} />}
              id={`amount_to_date_${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Campaign Mode'}
              value={subscriptionData?.campaign?.mode}
              id={`campaign_mode_${subscriptionData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Notes'}
              value={subscriptionData?.notes}
              id={`notes_${subscriptionData?.id}_value`}
            />
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default SubscriptionMoreInfo;
