import React, { useContext, useEffect, useState } from 'react';
import './tax-print.css';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { UserContext } from '../../components/UserContext';
import PhoneNumber from '../../components/displayValues/PhoneNumber';
import PaymentMethod from '../../components/displayValues/PaymentMethod';
import CurrencyText from '../../components/displayValues/CurrencyText';
import FormattedDate from '../../components/displayValues/FormattedDate';
import Markdown from '../../components/Markdown';

export default function YearEndTaxReceipt(props) {
  const { businessProfile, customerId, receiptTemplate } = props;
  const [customerData, setCustomerData] = useState({});
  const { authenticatedUser } = useContext(UserContext);
  useEffect(() => {
    ElasticSearchAPIClient.getCustomerTransactionsByYear(authenticatedUser, '2023', customerId).then((response) => {
      setCustomerData(response);
    });
  }, []);
  return (
    <React.Fragment>
      <div className="print-content">
        <div className="invoice-box">
          <table cellPadding="0" className="page-header" cellSpacing="0" style={{ marginBottom: '20px' }}>
            <tbody>
              <tr>
                <td className="title" width="35%">
                  <img src={businessProfile.paymentPageLogoUrl} height="75px" />
                </td>

                <td style={{ paddingLeft: '20px' }} width="65%">
                  {businessProfile && (
                    <div>
                      <b>{businessProfile.name}</b>
                      <br />
                      {businessProfile.addressStreet}, {businessProfile.addressCity}, {businessProfile.addressState}{' '}
                      {businessProfile.addressZipCode}
                      <br />
                      EIN: {businessProfile.ein} | P: <PhoneNumber data={businessProfile.phone} />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className="top">
                <td colSpan="3">
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>YEAR END TAX RECEIPT - 2023</div>
                  <br />
                  Prepared on <FormattedDate date={new Date()} style={{ fontWeight: '700' }} /> for
                  <br />
                  {customerData.name}
                  {customerData.addressStreet ? (
                    <span>
                      <br />
                      {customerData.addressStreet} {customerData.addressApt} <br />
                      {customerData.addressCity}, {customerData.addressState} {customerData.addressZipCode}
                    </span>
                  ) : (
                    <span />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className="information">
                <td colSpan="3">
                  {/*<div id="title">*/}
                  {/*  <Markdown content={receiptTemplate?.title} />*/}
                  {/*</div>*/}
                  <div id="body" style={{ marginBottom: '20px' }}>
                    <Markdown content={receiptTemplate?.body} />
                  </div>
                  <div id="signature">
                    <Markdown content={receiptTemplate?.signature} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className="top">
                <td colSpan="3">
                  <b>
                    2023 Tax Deductible Donations - <CurrencyText amount={customerData.totalDonationAmount} />
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellPadding="0" cellSpacing="0" style={{ marginBottom: '30px' }}>
            <thead className="heading">
              <tr>
                <td width="30%">Cause</td>
                <td width="20%">Date</td>
                <td width="30%">Payment Method</td>
                <td width="20%" className="align-right">
                  Amount
                </td>
              </tr>
            </thead>
            <tbody>
              {customerData?.transactions?.map((transaction) => {
                return (
                  <tr key={transaction?._id} className="item">
                    <td style={{ textTransform: 'capitalize' }}>{transaction?._source?.category?.name}</td>
                    <td>
                      <FormattedDate date={transaction?._source?.transactionDate} />
                    </td>
                    <td>
                      <PaymentMethod data={transaction?._source?.paymentMethod} />
                    </td>
                    <td className="align-right">
                      {' '}
                      <CurrencyText amount={transaction?._source?.amount} />
                    </td>
                  </tr>
                );
              })}

              <tr className="total">
                <td></td>
                <td className="align-right" colSpan="3">
                  {console.log(customerData)}
                  Total: <CurrencyText amount={customerData.totalDonationAmount} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
