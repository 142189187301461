import { grey } from '@material-ui/core/colors';

const overrides = {
  MuiButtonGroup: {
    groupedTextHorizontal: {
      '&:not(:last-child)': {
        borderRight: 'none',
      },
    },
  },
  MuiLink: {
    root: {
      borderBottom: '1px dotted',
    },
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  MuiButton: {
    containedSizeLarge: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    outlinedSizeLarge: {
      paddingTop: '11px',
      paddingBottom: '11px',
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: grey['300'],
    },
    input: {
      '&:read-only': {
        backgroundColor: grey[300],
      },
    },
    underline: {
      '&::before': {
        borderBottom: '0px',
      },
      '&::after': {
        borderBottom: '0px',
      },
      '&:hover:before': {
        borderBottom: '0px',
      },
    },
  },
  MuiSvgIcon: {
    root: {
      // marginTop: '0.2rem',
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: '0 0 14px 0 rgba(53,64,82,.1)',
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
  },
};

export default overrides;
