import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StaticContentDatatable from '../../../components/StaticContentDatatable';
import FormattedDate from '../../../components/displayValues/FormattedDate';
import CurrencyText from '../../../components/displayValues/CurrencyText';
import StatusText from '../../../components/displayValues/StatusText';
import PhoneNumber from '../../../components/displayValues/PhoneNumber';
import Button from '@material-ui/core/Button';
import GoodbricksLegacyAPIClient from '../../../restClients/GoodbricksLegacyAPIClient';
import { UserContext } from '../../../components/UserContext';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

export default function PledgeImportConfirmation(props) {
  const { data } = props;
  const [initRowData, setInitRowData] = useState(data);
  const [stats, setStats] = useState({});
  const { authenticatedUser } = useContext(UserContext);
  const columns = [
    {
      id: 'id',
      field: 'id',
      label: 'Id',
    },
    {
      id: 'name',
      field: 'name',
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      field: 'email',
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'phone',
      field: 'phone',
      disablePadding: true,
      label: 'Phone',
      cellContent: (data) => {
        return <PhoneNumber data={data?.phone} />;
      },
    },
    {
      id: 'categoryName',
      field: 'categoryName',
      disablePadding: false,
      label: 'Cause',
    },
    {
      id: 'categoryAmount',
      field: 'categoryAmount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (data) => {
        return <CurrencyText amount={data['categoryAmount']} />;
      },
    },
    {
      id: 'pledgeDate',
      field: 'pledgeDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (data) => {
        return <FormattedDate date={data?.pledgeDate} />;
      },
    },
    {
      id: 'fulfillByDate',
      field: 'fulfillByDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (data) => {
        return <FormattedDate date={data?.fulfillByDate} />;
      },
    },
    {
      id: 'startDate',
      field: 'startDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (data) => {
        return <FormattedDate date={data?.startDate} />;
      },
    },
    {
      id: 'paymentIterations',
      field: 'paymentIterations',
      label: 'paymentIterations',
    },
    {
      id: 'status',
      field: 'status.keyword',
      disablePadding: false,
      label: 'Status',
      cellContent: (data) => {
        if (!data['status']) {
          return <StatusText status="Pending" />;
        }
        return <StatusText status={data['status']} />;
      },
    },
  ];
  const mapSeries = async (data) => {
    for (const singleRecord of data) {
      let newInitRowData = [...initRowData];
      singleRecord.paymentMethod = 'OTHER';
      if (!singleRecord.status || singleRecord.status.startsWith('Error:')) {
        singleRecord.pledgeDate = dayjs(singleRecord.pledgeDate, 'MM-DD-YYYY').valueOf();
        singleRecord.fulfillByDate = dayjs(singleRecord.fulfillByDate, 'MM-DD-YYYY').valueOf();
        singleRecord.startDate = dayjs(singleRecord.startDate, 'MM-DD-YYYY').valueOf();
        await GoodbricksLegacyAPIClient.savePledge(authenticatedUser, singleRecord)
          .then((response) => {
            newInitRowData.find((x) => x.id === singleRecord.id).status = 'Imported';
            setInitRowData(newInitRowData);
            setStats({
              total: newInitRowData.length,
              pending: newInitRowData.filter((x) => x.status === 'Pending').length,
              imported: newInitRowData.filter((x) => x.status === 'Imported').length,
              errors: newInitRowData.filter((x) => x.status?.startsWith('Error:')).length,
            });
          })
          .catch((error) => {
            newInitRowData.find((x) => x.id === singleRecord.id).status = 'Error: ' + error.response?.data?.message;
            setInitRowData(data);
            setStats({
              total: newInitRowData.length,
              pending: newInitRowData.filter((x) => x.status === 'Pending').length,
              imported: newInitRowData.filter((x) => x.status === 'Imported').length,
              errors: newInitRowData.filter((x) => x.status?.startsWith('Error:')).length,
            });
          });
      }
    }
  };
  useEffect(() => {
    mapSeries(initRowData).then((r) => {});
  }, []);
  return (
    <React.Fragment>
      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
        <Grid item></Grid>
        <Grid item>
          <Button variant="contained" color="primary" style={{ marginRight: '0.5rem' }}>
            <CSVLink
              data={initRowData ? initRowData : []}
              filename={`import-pledge-report-${dayjs(new Date()).format('MMM-DD-YYYY hhmm')}.csv`}>
              Download Results
            </CSVLink>
          </Button>
        </Grid>
      </Grid>
      <Box my={10} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {initRowData && (
            <StaticContentDatatable
              tableOptions={{
                title: (
                  <>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                      <Grid item>
                        <Typography variant="h6">Extracted Pledges</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">Total ({stats.total || 0})</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">Pending ({stats.pending || 0})</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">Imported ({stats.imported || 0})</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">Error ({stats.errors || 0})</Typography>
                      </Grid>
                    </Grid>
                  </>
                ),
                rowsPerPage: 25,
                hideSearchQuery: true,
              }}
              columns={columns}
              initRowData={initRowData}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
