import React from 'react';

export default function Address(props) {
  let addressStreet = props.data?.addressStreet || '';
  let addressApt = props.data?.addressApt ? props.data?.addressApt + ',' : '';
  let addressCity = props.data?.addressCity ? props.data?.addressCity + ',' : '';
  let addressState = props.data?.addressState || '';
  let addressZipCode = props.data?.addressZipCode || '';
  return (
    <React.Fragment>{`${addressStreet} ${addressApt} ${addressCity} ${addressState} ${addressZipCode}`}</React.Fragment>
  );
}
