import React, { useContext } from 'react';
import {
  EditOutlined,
  FilterList as FilterListIcon,
  ReceiptOutlined,
  Repeat,
  Replay,
  SaveAltOutlined,
} from '@material-ui/icons';
import { makeStyles, Typography } from '@material-ui/core';
import Navlink from '../../../components/displayValues/NavLink';
import FormattedDate from '../../../components/displayValues/FormattedDate';
import Link from '@material-ui/core/Link';
import ElasticSearchAdvancedDatatable from '../../../components/ElasticSearchAdvancedDatatable';
import { UserContext } from '../../../components/UserContext';
import EmailSettingsMoreInfo from './EmailSettingsMoreInfo';
import { USER_ROLES } from '../../../Constants';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default function EmailSettings(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const classes = useStyles();

  const singleRowActions = [
    {
      id: 'emailEettingsEdit',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Edit Email Settings',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/settings/email/' + rowData.id + '/edit'}
          text={<EditOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
      icon: <Replay fontSize="small" color="secondary" />,
    },
  ];
  const sourceFields = ['type', 'dateCreated', 'lastUpdated'];
  const columns = [
    {
      id: 'type',
      field: 'type.keyword',
      disablePadding: true,
      label: 'Type',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['type'] + ' EMAIL'}
          </Typography>
        );
      },
    },
    {
      id: 'dateCreated',
      field: 'dateCreated',
      disablePadding: false,
      label: 'Date Created',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.dateCreated} />;
      },
    },
    {
      id: 'lastUpdated',
      field: 'lastUpdated',
      disablePadding: false,
      label: 'Last Updated',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.lastUpdated} />;
      },
    },
  ];
  const onMoreInfoClick = (rowData) => {
    return <EmailSettingsMoreInfo rowData={rowData} />;
    return;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          singleRowActions={singleRowActions}
          sourceFields={sourceFields}
          columns={columns}
          indexType="template"
          onMoreInfoClick={onMoreInfoClick}
        />}
    </React.Fragment>
  );
}
