/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// sandbox
// const awsmobile = {
//   aws_project_region: 'us-west-2',
//   aws_cognito_region: 'us-west-2',
//   aws_cognito_identity_pool_id: 'us-west-2:b764f012-3328-4440-a4ab-12acbda85d2e',
//   aws_user_pools_id: 'us-west-2_GpaZ0FZZV',
//   aws_user_pools_web_client_id: '7rq6ifhclfv5qd4tuv5q5oftin',
//   oauth: {
//     domain: 'goodbricks-sandbox.auth.us-west-2.amazoncognito.com',
//     scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
//     redirectSignIn: 'https://sandbox.goodbricks.org/',
//     // redirectSignIn: 'http://localhost:3000/',
//     redirectSignOut: 'https://sandbox.goodbricks.org/',
//     // redirectSignOut: 'http://localhost:3000/',
//     responseType: 'code',
//   },
//   federationTarget: 'COGNITO_USER_POOLS',
//   API: {
//     endpoints: [
//       {
//         name: 'ElasticSearchAPI',
//         endpoint: 'https://m1e9m9lhji.execute-api.us-west-2.amazonaws.com/v1/search/goodbricks/_search',
//         // endpoint: 'https://m1e9m9lhji.execute-api.us-west-2.amazonaws.com/v1/search2',
//         // endpoint: 'https://search-gb-sandbox-mq4222nmxidd5a7ooxahxwgnn4.us-west-2.es.amazonaws.com',
//       },
//       {
//         name: 'GoodbricksAPI',
//         endpoint: 'https://m1e9m9lhji.execute-api.us-west-2.amazonaws.com/v1',
//       },
//       {
//         name: 'GoodbricksLegacyAPI',
//         endpoint: 'https://m1e9m9lhji.execute-api.us-west-2.amazonaws.com/v1/legacy',
//         // endpoint: 'http://localhost:8901',
//       },
//     ],
//   },
// };

// admin;
const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:266d4542-f0f1-48ee-9846-acf3c0ffd1fc',
  aws_user_pools_id: 'us-west-2_tzWZhzW3a',
  aws_user_pools_web_client_id: '27su5qo3svi854eoat7vp56h83',
  oauth: {
    domain: 'admin-auth.goodbricks.org',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://admin.goodbricks.org/',
    redirectSignOut: 'https://goodbricks.org/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  API: {
    endpoints: [
      {
        name: 'ElasticSearchAPI',
        endpoint: 'https://8u3t5320o8.execute-api.us-west-2.amazonaws.com/v1/search/goodbricks/_search',
      },
      {
        name: 'GoodbricksAPI',
        endpoint: 'https://8u3t5320o8.execute-api.us-west-2.amazonaws.com/v1',
      },
      {
        name: 'GoodbricksLegacyAPI',
        endpoint: 'https://8u3t5320o8.execute-api.us-west-2.amazonaws.com/v1/legacy',
      },
    ],
  },
};

export default awsmobile;
