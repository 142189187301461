import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Breadcrumbs as MuiBreadcrumbs, Link, makeStyles, Typography as MuiTypography } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import FormattedDate from '../../components/displayValues/FormattedDate';
import SubscriptionMoreInfo from '../subscription/SubscriptionMoreInfo';
import CurrencyText from '../../components/displayValues/CurrencyText';
import { UserContext } from '../../components/UserContext';
import { USER_ROLES } from '../../Constants';
import Page401 from '../error/Page401';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

function CustomerSubscriptions(props) {
  const {
    match: { params },
  } = props;
  const { userRole } = useContext(UserContext);
  const classes = useStyles();
  const sourceFields = [
    'customer.name',
    'customer.email',
    'planName',
    'paymentInterval',
    'startAt',
    'endAt',
    'amount',
    'status',
    'customer.phone',
  ];
  const columns = [
    {
      id: 'name',
      field: 'name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return <React.Fragment>{rowdata['customer']['name']}</React.Fragment>;
      },
    },
    {
      id: 'planName',
      field: 'planName',
      disablePadding: false,
      label: 'Cause',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['planName']}
          </Typography>
        );
      },
    },
    {
      id: 'paymentInterval',
      field: 'paymentInterval',
      disablePadding: false,
      label: 'Frequency',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['paymentInterval']}
          </Typography>
        );
      },
    },
    {
      id: 'startAt',
      field: 'startAt',
      disablePadding: false,
      label: 'Start',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.startAt} />;
      },
    },
    {
      id: 'endAt',
      field: 'endAt',
      disablePadding: false,
      label: 'End',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.endAt} />;
      },
    },
    {
      id: 'amount',
      field: 'amount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['amount'] ? <CurrencyText amount={rowdata['amount']} /> : '-'}</React.Fragment>;
      },
    },
    {
      id: 'status',
      field: 'status.keyword',
      disablePadding: false,
      label: 'Status',
    },
  ];
  const queryMustArray = [
    {
      term: {
        'customer.id.keyword': params.customerId,
      },
    },
  ];

  const onMoreInfoClick = (rowData) => {
    return <SubscriptionMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/customers">
              Customers
            </Link>
            <Typography>{`Customer (${params.customerId}) - Subscriptions`}</Typography>
          </Breadcrumbs>
          <Box my={5} />
          <ElasticSearchAdvancedDatatable
            columns={columns}
            sourceFields={sourceFields}
            indexType="subscription"
            queryMustArray={queryMustArray}
            onMoreInfoClick={onMoreInfoClick}
          />
        </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}

export default CustomerSubscriptions;
