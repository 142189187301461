// https://github.com/mui-org/material-ui/issues/16037
// https://gist.github.com/lfalke/1c5e7168424c8b2a65dcfba425fcc310

import React from 'react';
import StripeInput from './StripeInput';
import { CardElement } from '@stripe/react-stripe-js';
import GoodbricksTextField from './GoodbricksTextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

export default function StripeTextFieldElement(props) {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = React.useState(null);
  function handleElementChange({ complete, error }) {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
    }
  }

  const hasError = errorMessage !== null;

  return (
    <GoodbricksTextField
      error={hasError}
      helperText={hasError ? errorMessage || 'Invalid' : ''}
      onChange={handleElementChange}
      InputProps={{
        classes: { input: classes.root },
        inputProps: {
          component: CardElement,
        },
        inputComponent: StripeInput,
      }}
      {...props}
    />
  );
}
