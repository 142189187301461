export const HOUR = 'hour';
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';

export const DONORS = 'customer';
export const TRANSACTIONS = 'income_transaction';
export const SUBSCRIPTIONS = 'subscription';
export const CAUSES = 'income_category';
export const TEMPLATE = 'template';
export const PLEDGES = 'pledge';

export const PHONE_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const EMAIL_REGEX = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export const DATE_RANGES = [
  { label: '1D', value: 'now-1d/d', toolTip: 'Last 24 Hours' },
  { label: '1W', value: 'now-7d/d', toolTip: 'Last 7 Days' },
  { label: '4W', value: 'now-28d/d', toolTip: 'Last 4 Weeks' },
  { label: '3M', value: 'now-3M/M', toolTip: 'Last 3 Months' },
  { label: 'YTD', value: 'now-0y/y', toolTip: 'Year To Date' },
  { label: '12M', value: 'now-365d/d', toolTip: 'Last 12 Months' },
  { label: 'ALL', value: '1900-01-01', toolTip: 'All' },
];

export const YEAR_RANGES = [
  { label: '2019', value: '2019', toolTip: '2019' },
  { label: '2020', value: '2020', toolTip: '2020' },
  { label: '2021', value: '2021', toolTip: '2021' },
];

export const ONE_TIME_PLEDGE = 'One-Time';
export const SUBSCRIPTION_PLEDGE = 'Subscription';

export const USER_ROLES = {
  VIEW_ONLY: 1,
  CAMPAIGN_MANAGER: 10,
  FRONT_DESK_ADMIN: 50,
  SUPER_ADMIN: 100
};

// export const CUSTOM_DATE_RANGES = (number = 1, timePeriod = null) => {
//   let value = '1900-01-01';
//   let label = 'All';
//   let toolTip = 'All';
//
//   if (timePeriod !== null) {
//     if (timePeriod === HOUR) {
//       value = `now-${number.toString()}h/h`;
//       toolTip = number > 1 ? `Last ${number.toString()} Hours` : 'Past Hour';
//       label = `${number.toString()}H`;
//     } else if (timePeriod === DAY) {
//       value = `now-${number.toString()}d/d`;
//       toolTip = number > 1 ? `Last ${number.toString()} Days` : 'This Past Day';
//       label = `${number.toString()}D`;
//     } else if (timePeriod === WEEK) {
//       value = `now-${number.toString()}w/w`;
//       toolTip = number > 1 ? `Last ${number.toString()} Weeks` : 'This Past Weeks';
//       label = `${number.toString()}W`;
//     } else if (timePeriod === MONTH) {
//       value = `now-${number.toString()}M/M`;
//       toolTip = number > 1 ? `Last ${number.toString()} Months` : 'Last Month';
//       label = `${number.toString()}M`;
//     } else if (timePeriod === YEAR) {
//       value = `now-${number.toString()}y/y`;
//       toolTip = number > 1 ? `Last ${number.toString()} Years` : 'Last Year';
//       label = `${number.toString()}Y`;
//     }
//   }
//   return value;
//   // return { label, value, toolTip };
// };
