import React, { useContext } from 'react';
import { Add, EmailOutlined, Publish, ReceiptOutlined, Replay, SaveAltOutlined } from '@material-ui/icons';

import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TransactionMoreInfo from './TransactionMoreInfo';
import FormattedDate from '../../components/displayValues/FormattedDate';
import NavLink from '../../components/displayValues/NavLink';
import { useHistory } from 'react-router-dom';
import StatusText from '../../components/displayValues/StatusText';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { UserContext } from '../../components/UserContext';
import CurrencyText from '../../components/displayValues/CurrencyText';
import GlobalMetricDisplay from '../../components/displayValues/GlobalMetricDisplay';
import PaymentMethod from '../../components/displayValues/PaymentMethod';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { TRANSACTIONS, USER_ROLES } from '../../Constants';
import CauseText from '../../components/displayValues/CauseText';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default function Transactions(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);

  const classes = useStyles();
  const history = useHistory();

  const globalActions = [
    {
      id: 'download-transactions',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Transactions',
      icon: <SaveAltOutlined />,
      parsedResponseType: TRANSACTIONS,
      doAction: (filter, queryString) => {
        return ElasticSearchAPIClient.getTransactionsToDownload(
          authenticatedUser,
          filter,
          queryString,
          downloadSourceFields,
        );
      },
    },
    {
      id: 'import-transactions',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayComponent: (
        <Tooltip title="Import Bulk Transactions" key="import-transactions" data-cy={'import-transactions'}>
          <IconButton
            aria-label="Import Bulk Transactions"
            onClick={() => {
              history.push('/transactions/import');
            }}>
            <Publish />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 'transaction-new',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayComponent: (
        <Button
          onClick={() => {
            history.push('/transaction/new');
          }}
          data-cy={'add-transaction'}
          variant="contained"
          color="primary"
          startIcon={<Add />}>
          New Transaction
        </Button>
      ),
    },
  ];

  const globalMetricsDisplay = {
    aggregations: [
      {
        id: 'transaction-count',
        field: 'amount', //for elastic search query
        aggregateFunction: 'value_count',
        label: 'Count',
        dataType: 'number',
        displayOrder: 1,
      },
      {
        id: 'transaction-sum',
        field: 'amount', //for elastic search query
        aggregateFunction: 'sum',
        label: 'Total',
        dataType: 'currency',
        displayOrder: 2,
      },
      {
        id: 'transaction-avg',
        field: 'amount', //for elastic search query
        aggregateFunction: 'avg',
        label: 'Average',
        dataType: 'currency',
        displayOrder: 3,
      },
    ],
    doAction: (data) => {
      return <GlobalMetricDisplay data={data}></GlobalMetricDisplay>;
    },
  };

  const bulkActions = [
    {
      id: 'download-transactions',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Transactions',
      icon: <SaveAltOutlined />,
      parsedResponseType: TRANSACTIONS,
      doAction: (ids) => {
        return ElasticSearchAPIClient.getTransactionsByIds(authenticatedUser, ids, downloadSourceFields);
      },
    },
  ];
  const singleRowActions = [
    {
      id: 'customer-transaction',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Show Customer Transactions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <NavLink
          href={'/customers/' + rowData?.customer?.id + '/transactions'}
          text={<ReceiptOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'refund-transaction',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Refund Transaction',
      alertMessage: 'Sending refund...',
      doAction: (rowData) => {
        return GoodbricksLegacyAPIClient.refundTransactionById(authenticatedUser, rowData.id);
      },
      getUpdateRowData: (rowData) => {
        rowData['status'] = 'RETURNED';
        return rowData;
      },
      icon: <Replay fontSize="small" color="secondary" />,
    },
    {
      id: 'email-receipt',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Email Receipt',
      alertMessage: 'Sending receipt...',
      doAction: (rowData) => {
        return GoodbricksLegacyAPIClient.sendReceiptToDonorByTransactionId(authenticatedUser, rowData.id);
      },
      icon: <EmailOutlined fontSize="small" color="secondary" />,
    },
  ];
  const sourceFields = [
    'customer.name',
    'customer.email',
    'customer.phone',
    'customer.id',
    'amount',
    'publicId',
    'transactionDate',
    'category.name',
    'type',
    'paymentMethod',
    'status',
  ];

  const downloadSourceFields = [
    'customer.name',
    'customer.email',
    'customer.phone',
    'customer.id',
    'amount',
    'publicId',
    'transactionDate',
    'category.name',
    'type',
    'paymentMethod',
    'status',
    'source',
    'paymentType',
    'paymentProcessorRefundId',
  ];

  const label = 'Transactions';
  const columns = [
    {
      id: 'name',
      field: 'customer.name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['customer']['name']}
          </Typography>
        );
      },
    },
    {
      id: 'category.name',
      field: 'category.name.keyword',
      disablePadding: false,
      label: 'Cause',
      cellContent: (rowdata) => {
        return <CauseText label={rowdata['category']['name']} />;
      },
    },
    {
      id: 'paymentMethod',
      field: 'paymentMethod.card.brand.keyword',
      align: 'right',
      disablePadding: false,
      label: 'Payment Method',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return (
          <React.Fragment>
            <PaymentMethod data={rowdata['paymentMethod']} />
          </React.Fragment>
        );
      },
    },
    {
      id: 'transactionDate',
      field: 'transactionDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.transactionDate} />;
      },
    },
    {
      id: 'amount',
      field: 'amount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['amount'] ? <CurrencyText amount={rowdata['amount']} /> : '-'}</React.Fragment>;
      },
    },
    {
      id: 'type',
      field: 'type.keyword',
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'status',
      field: 'status.keyword',
      disablePadding: false,
      label: 'Status',
      cellContent: (rowdata) => {
        return <StatusText status={rowdata?.status} />;
      },
    },
  ];

  const onMoreInfoClick = (rowData) => {
    return <TransactionMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          label={label}
          bulkActions={bulkActions}
          globalActions={globalActions}
          singleRowActions={singleRowActions}
          columns={columns}
          sourceFields={sourceFields}
          indexType={TRANSACTIONS}
          onMoreInfoClick={onMoreInfoClick}
          globalMetricsDisplay={globalMetricsDisplay}
        />}
    </React.Fragment>
  );
}
