import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserContext } from '../../components/UserContext';
import LabelValueGridItem from '../../components/displayValues/LabelValueGridItem';
import PaymentMethod from '../../components/displayValues/PaymentMethod';
import PhoneNumber from '../../components/displayValues/PhoneNumber';
import CurrencyText from '../../components/displayValues/CurrencyText';
import Address from '../../components/displayValues/Address';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';

export default function CustomerMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [customerData, setCustomerData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getCustomerById(authenticatedUser, rowData.id).then((response) => {
      const customers = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setCustomerData(customers[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography variant="body1" color="primary" gutterBottom data-cy={`customer_details_label_${customerData?.id}`}>
          Customer Details
        </Typography>
        <Box my={3} />
        <Grid container direction="row" spacing={3} alignItems="center">
          <LabelValueGridItem
            label={'Address'}
            value={<Address data={customerData} />}
            id={`address_${customerData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Phone'}
            value={<PhoneNumber data={customerData?.phone} />}
            id={`phone_${customerData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Payment Method'}
            value={<PaymentMethod data={customerData?.paymentMethod} />}
            id={`paymentMethod_${customerData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Total TxnAmount'}
            value={<CurrencyText amount={customerData?.totalTransactionAmount} />}
            id={`totalTransactionAmount_${customerData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Total TxnCount'}
            value={customerData?.totalTransactionCount}
            id={`totalTransactionCount_${customerData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Average TxnAmount'}
            value={<CurrencyText amount={customerData?.totalTransactionAmount / customerData?.totalTransactionCount} />}
            id={`avgTransactionAmount_${customerData?.id}_value`}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
}
