import NumberFormat from 'react-number-format';
import React from 'react';

function CurrencyText(props) {
  return props.amount ? (
    <NumberFormat
      style={{ fontWeight: props.fontWeight ? props.fontWeight : 700 }}
      value={props.amount}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      decimalScale={2}
      fixedDecimalScale
    />
  ) : (
    <span>-</span>
  );
}

export default CurrencyText;
