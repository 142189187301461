import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import GoodbricksTextField from './GoodbricksTextField';
import ElasticSearchAPIClient from '../../../restClients/ElasticSearchAPIClient';
import { UserContext } from '../../UserContext';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export default function CauseAutocompleteDropdownField(props) {
  const { id, name, label, fullwidth, setValue, register, required, errors } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [causes, setCauseData] = useState([]);
  useEffect(() => {
    ElasticSearchAPIClient.getAllCauses(authenticatedUser).then((response) => {
      const causes = response.hits.hits.map((x) => {
        return { id: x._id, name: x._source.name, slug: x._source.slug };
      });
      setCauseData(causes);
    });
    register(
      { name: name },
      {
        required: {
          value: required,
          message: label + ' is required.',
        },
      },
    );
  }, []);

  const handleChange = (event, data) => {
    setValue(name, data?.slug);
  };
  return (
    <React.Fragment>
      <Autocomplete
        id={id}
        name={name + '-autocomplete'}
        options={causes}
        getOptionLabel={(option) => option.name}
        autoComplete
        autoHighlight
        includeInputInList
        autoSelect
        onChange={handleChange}
        renderInput={(params) => (
          <GoodbricksTextField
            {...params}
            label={label}
            fullWidth={fullwidth}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].message}
          />
        )}
        renderOption={(option) => {
          return (
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography color="secondary">{option.name}</Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </React.Fragment>
  );
}
