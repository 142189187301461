import React, { useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import { AppBar as MuiAppBar, Grid, Hidden, Toolbar, Typography } from '@material-ui/core';

import { AccountCircleOutlined, MenuOutlined } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../components/UserContext';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

function UserMenu() {
  const { userAttributes, orgName } = useContext(UserContext);
  const history = useHistory();
  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        // closeMenu();
        history.push('/auth/sign-in');
      })
      .catch((err) => console.log(err));
  };
  const changePassword = () => {
    closeMenu();
    Auth.forgotPassword(userAttributes.email).then((response) => {
      history.push('/auth/reset-password', {
        email: userAttributes.email,
      });
    });
  };
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit">
        <AccountCircleOutlined fontSize="large" />
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={changePassword}>Reset Password</MenuItem>
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle, user, title }) => {
  const { userAttributes, orgName } = useContext(UserContext);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}>
          <Grid justify="space-between" alignItems="center" container>
            <Grid item>
              <Grid justify="flex-start" alignItems="center" container spacing={6}>
                <Hidden lgUp>
                  <Grid item>
                    <MenuOutlined onClick={onDrawerToggle} data-cy="sidebar-toggle-menu-icon" />
                  </Grid>
                </Hidden>
                <Grid item md>
                  <Box my={1}></Box>
                  <Typography variant="h4" gutterBottom display="inline" color="textPrimary">
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid justify="flex-end" alignItems="center" container spacing={1}>
                <Hidden smDown>
                  <Grid item>
                    <Typography variant="h6" align="right" color="textPrimary">
                      {userAttributes?.name}
                    </Typography>
                    <Typography variant="body2" align="right" color="textPrimary">
                      {orgName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <UserMenu />
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  <Grid item>
                    <AccountCircleOutlined />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default connect()(withTheme(Header));
