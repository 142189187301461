import React from 'react';

import { Typography } from '@material-ui/core';

import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useHistory } from 'react-router-dom';
import NavLink from '../../components/displayValues/NavLink';

const fields = [
  {
    name: 'email',
    label: 'Email',
    fieldType: 'text',
    inputType: 'email',
  },
];

function ResendConfirmSignUpCode(props) {
  const history = useHistory();
  const onResendVerificationCode = async (data) => {
    try {
      await Auth.resendSignUp(data.email).then((response) => {
        history.push('/auth/confirm-sign-up', {
          email: data.email,
        });
      });
    } catch (error) {
      console.log('error resend confirming sign up', error);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" align="center" gutterBottom color={'primary'}>
        Confirm your Goodbricks account
      </Typography>
      <Box mb={5}>
        <Typography align="center" gutterBottom color="textSecondary">
          Please enter your email to resend verification code.
        </Typography>
      </Box>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formOptions={{ submitButtonLabel: 'Verify Account' }}
          formSubmitCallback={onResendVerificationCode}
        />
      </Box>
      <Box>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Already confirmed account?&nbsp;
          <NavLink href="/auth/sign-in" text="Sign In" />
        </Typography>
      </Box>
    </React.Fragment>
  );
}
export default ResendConfirmSignUpCode;
