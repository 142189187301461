import TextField from '@material-ui/core/TextField/TextField';
import React from 'react';

export default function GoodbricksTextField(props) {
  let { InputLabelProps, ...newProps } = props;
  InputLabelProps = { ...props?.InputLabelProps, shrink: true };
  return (
    <React.Fragment>
      <TextField
        // color="secondary"
        variant="filled"
        {...newProps}
        InputLabelProps={InputLabelProps}
      />
    </React.Fragment>
  );
}
