import React from 'react';
import styled from 'styled-components';

import { Box, Card as MuiCard, CardContent as MuiCardContent, Typography as MuiTypography } from '@material-ui/core';

import { spacing } from '@material-ui/system';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import CurrencyText from '../../components/displayValues/CurrencyText';

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const StyledList = withStyles({
  root: {
    position: 'absolute',
    top: '4px',
    right: '16px',
    height: '20px',
    padding: '1px 0',
  },
})(List);

function Stats({ id, title, value, dataType, updateData, menuOptions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index, id, val) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    updateData(event, id, val);
  };

  const menuItemOptions = menuOptions?.map((option, index) => (
    <MenuItem
      key={id + index}
      selected={option === 'Pyxis'}
      onClick={(event) => {
        handleMenuItemClick(event, index, id, option.value);
        setAnchorEl(null);
      }}>
      {option.label}
    </MenuItem>
  ));

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {title}
        </Typography>
        <div>
          <StyledList component="nav">
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              onClick={handleClick}>
              <ListItemText
                // primary="When device is locked"
                secondary={menuOptions && menuOptions[selectedIndex]['label']}
              />
            </ListItem>
          </StyledList>
          <Menu
            id={id + ' menu'}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}>
            {menuItemOptions}
          </Menu>
        </div>
        <Typography variant="h3" mb={3} color="secondary">
          <Box fontWeight="fontWeightRegular">
            {(() => {
              let val = value != null ? value : null;
              switch (dataType) {
                case 'currency':
                  return <CurrencyText amount={val} fontWeight={400} />;
                case 'number':
                  return val;
                default:
                  return val;
              }
            })()}
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Stats;
