import { API } from 'aws-amplify';
import { CAUSES, DONORS, SUBSCRIPTIONS, TRANSACTIONS, TEMPLATE, PLEDGES } from '../Constants';

const apiName = 'ElasticSearchAPI';
const path = '/';

const getDocumentById = (authenticatedUser, id, indexType) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    query: {
      bool: {
        must: [
          {
            term: {
              _id: id,
            },
          },
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': indexType,
            },
          },
        ],
      },
    },
  };
  return doSearch(authenticatedUser, body);
};

const getDocumentByType = (authenticatedUser, type, indexType) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: type,
              fields: ['type'],
            },
          },
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': indexType,
            },
          },
        ],
      },
    },
  };
  return doSearch(authenticatedUser, body);
};

const getDocumentsByIds = (authenticatedUser, ids, sourceFields, indexType) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 9999,
    _source: sourceFields,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': indexType,
            },
          },
        ],
        filter: {
          terms: {
            _id: ids,
          },
        },
      },
    },
  };
  return doSearch(authenticatedUser, body);
};

const getTransactionById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, TRANSACTIONS);
};

const getSubscriptionById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, SUBSCRIPTIONS);
};

const getCauseById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, CAUSES);
};

const getCustomerById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, DONORS);
};

const getTemplateById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, TEMPLATE);
};

const getTemplateByType = (authenticatedUser, type) => {
  return getDocumentByType(authenticatedUser, type, TEMPLATE);
};

const getCustomerInfoByFilterText = (authenticatedUser, inputValue) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 10,
    _source: [
      'name',
      'email',
      'phone',
      'addressStreet',
      'addressApt',
      'addressCity',
      'addressState',
      'addressZipCode',
      'paymentMethod',
    ],
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: inputValue ? '*' + inputValue + '*' : '*',
              fields: ['name', 'email', 'phone'],
            },
          },
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': DONORS,
            },
          },
        ],
      },
    },
  };
  return doSearch(authenticatedUser, body);
};

const doSearch = (authenticatedUser, searchQueryBody) => {
  const apiInit = {
    body: searchQueryBody,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, path, apiInit);
};

const getAllCauses = (authenticatedUser) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 1000,
    _source: ['name', 'slug'],
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': CAUSES,
            },
          },
        ],
      },
    },
  };
  return doSearch(authenticatedUser, body);
};

const getDataByFilter = (authenticatedUser, filter, queryString, sourceFields, indexType) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 9999,
    _source: sourceFields,
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: queryString ? '*' + queryString + '*' : '*',
            },
          },
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': indexType,
            },
          },
          {
            range: {
              dateCreated: { gte: filter.dateRange },
            },
          },
        ],
      },
    },
    sort: {
      [filter.orderBy]: filter.order,
    },
  };
  return doSearch(authenticatedUser, body);
};

const getTransactionsToDownload = (authenticatedUser, filter, queryString, sourceFields) => {
  return getDataByFilter(authenticatedUser, filter, queryString, sourceFields, TRANSACTIONS);
};

const getCustomersToDownload = (authenticatedUser, filter, queryString, sourceFields) => {
  return getDataByFilter(authenticatedUser, filter, queryString, sourceFields, DONORS);
};

const getCausesToDownload = (authenticatedUser, filter, queryString, sourceFields) => {
  return getDataByFilter(authenticatedUser, filter, queryString, sourceFields, CAUSES);
};

const getSubscriptionsToDownload = (authenticatedUser, filter, queryString, sourceFields) => {
  return getDataByFilter(authenticatedUser, filter, queryString, sourceFields, SUBSCRIPTIONS);
};

//for new donors/transactions/subscriptions .Latest Transactions
const getRecordsByDate = async (authenticatedUser, indexType, dateRangeValue, size = 1, aggregatesOnly = true) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: size,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': indexType,
            },
          },
          {
            range: {
              dateCreated: { gte: dateRangeValue },
            },
          },
        ],
      },
    },
    sort: {
      dateCreated: 'desc',
    },
  };
  let result = await doSearch(authenticatedUser, body);

  //if records need to be returned for table
  if (!aggregatesOnly) {
    const rows = result?.hits?.hits?.map((x) => {
      return { id: x._id, ...x._source };
    });
    return rows;
  } else {
    //return aggregates
    return result?.hits?.total?.value;
  }
};

//Total Revenue by Period
const getTransactionTotals = async (authenticatedUser, dateRangeValue) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 1,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': TRANSACTIONS,
            },
          },
          {
            range: {
              dateCreated: { gte: dateRangeValue },
            },
          },
        ],
      },
    },
    aggs: {
      sum: {
        sum: {
          field: 'amount',
        },
      },
    },
  };
  let result = await doSearch(authenticatedUser, body);
  let value = result?.aggregations?.sum?.value;
  return value;
};

//for expiring recurring donations
const getExpiringSubscriptions = async (authenticatedUser, dateRangeValue, size = 100) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': SUBSCRIPTIONS,
            },
          },
          {
            range: {
              endAt: { gte: 'now/d', lt: dateRangeValue },
            },
          },
        ],
      },
    },
  };
  let response = await doSearch(authenticatedUser, body);
  let result = [];
  result = response?.hits?.hits?.map((x) => {
    return { id: x._id, ...x._source };
  });
  return result;
};

//data for line chart
const getTransactionSumForGraph = async (authenticatedUser, dateRangeValue) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 1,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': TRANSACTIONS,
            },
          },
          {
            range: {
              dateCreated: { gte: dateRangeValue },
            },
          },
        ],
      },
    },
    aggs: {
      income_per_month: {
        date_histogram: {
          field: 'transactionDate',
          calendar_interval: 'month',
          format: 'yyyy-MMM',
        },
        aggs: {
          monthly_income: {
            sum: {
              field: 'amount',
            },
          },
          monthly_income_cumulative: {
            cumulative_sum: {
              buckets_path: 'monthly_income',
            },
          },
        },
      },
    },
  };
  let result = await doSearch(authenticatedUser, body);

  let incomePerMonth = result?.aggregations?.income_per_month?.buckets;
  let dates = [];
  let vals = [];
  let cumVals = [];

  incomePerMonth.forEach(function (x) {
    dates.push(x.key_as_string);
    vals.push(x.monthly_income?.value.toFixed(2));
    cumVals.push(x.monthly_income_cumulative?.value.toFixed(2));
  });

  return { dates, vals, cumVals };
};

//for doughnut chart
const getIncomeTransactionsByCategory = async (authenticatedUser, dateRangeValue, categorySplitCount = 5) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 1,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'indexType.keyword': TRANSACTIONS,
            },
          },
          {
            range: {
              dateCreated: { gte: dateRangeValue },
            },
          },
        ],
      },
    },
    aggs: {
      records_by_category: {
        terms: {
          field: 'category.name.keyword',
          size: 9999,
        },
        aggs: {
          aggregate_sum_by_category: {
            sum: {
              field: 'amount',
            },
          },
          aggregate_sum_by_category_sorted: {
            bucket_sort: {
              sort: [
                {
                  aggregate_sum_by_category: {
                    order: 'desc',
                  },
                },
              ],
            },
          },
        },
      },
    },
  };

  let result = await doSearch(authenticatedUser, body);

  let aggregatesSumByCategory = result?.aggregations?.records_by_category?.buckets;

  let labels = [];
  let incomes = [];
  let transactions = [];

  if (aggregatesSumByCategory.length < 1) {
    return { labels, incomes, transactions };
  }

  aggregatesSumByCategory.forEach(function (x) {
    labels.push(x.key);
    incomes.push(x.aggregate_sum_by_category?.value.toFixed(2));
    transactions.push(x.doc_count);
  });

  //consolidate all values apart from top 3

  let tempCategorySplitCount =
    categorySplitCount <= aggregatesSumByCategory.length ? categorySplitCount : aggregatesSumByCategory.length;

  if (aggregatesSumByCategory.length > tempCategorySplitCount) {
    labels = labels.slice(0, tempCategorySplitCount).concat('Others');
    incomes = incomes
      .slice(0, tempCategorySplitCount)
      .concat(
        incomes.slice(tempCategorySplitCount).reduce((acc, val) => (parseFloat(acc) + parseFloat(val)).toFixed(2)),
      );

    transactions = transactions
      .slice(0, tempCategorySplitCount)
      .concat(transactions.slice(tempCategorySplitCount).reduce((acc, val) => acc + val));
  }

  return { labels, incomes, transactions };
};

const getAllCustomersWithTransactionInYear = async (authenticatedUser, year) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              'accessControl.keyword': orgPublicId,
            },
          },
          {
            term: {
              'status.keyword': 'SUCCEEDED',
            },
          },
          {
            term: {
              'category.taxDeductible': true,
            },
          },
          {
            term: {
              'indexType.keyword': TRANSACTIONS,
            },
          },
        ],
        filter: [
          {
            range: {
              transactionDate: {
                gte: year + '-01-01T08:00:00Z',
                lte: Number(year)+1 + '-01-01T07:59:59Z',
              },
            },
          },
        ],
      },
    },
    aggs: {
      customerDetails: {
        terms: {
          field: 'customer.email.keyword',
          size: 99999,
          order: {
            _key: 'asc',
          },
        },
        aggs: {
          customerTotal: {
            sum: {
              field: 'amount',
            },
          },
          customerId: {
            terms: {
              field: 'customer.id.keyword',
            },
          },
          customerName: {
            terms: {
              field: 'customer.name.keyword',
            },
          },
          customerPhone: {
            terms: {
              field: 'customer.phone.keyword',
            },
          },
        },
      },
    },
  };

  let response = await doSearch(authenticatedUser, body);
  const customerDataList = [];
  const customerResponseData = response?.aggregations?.customerDetails?.buckets;
  if (customerResponseData) {
    customerResponseData.forEach((customerData) => {
      customerDataList.push({
        name: customerData.customerName.buckets[0]?.key,
        id: customerData.customerId.buckets[0]?.key,
        email: customerData.key,
        phone: customerData.customerPhone.buckets[0]?.key,
        donationTotal: customerData.customerTotal.value,
        donationCount: customerData.doc_count,
      });
    });
  }
  return customerDataList;
};

const getCustomerTransactionsByYear = async (authenticatedUser, year, customerId) => {
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const body = {
    size: 999,
    _source: [
      'name',
      'address*',
      'email',
      'amount',
      'category.name',
      'paymentMethod',
      'transactionDate',
      'paymentProcessorTransactionId',
    ],
    query: {
      bool: {
        should: [
          {
            bool: {
              must: [
                {
                  term: {
                    'organization.publicId.keyword': orgPublicId,
                  },
                },
                {
                  term: {
                    'indexType.keyword': DONORS,
                  },
                },
                {
                  term: {
                    _id: customerId,
                  },
                },
              ],
            },
          },
          {
            bool: {
              must: [
                {
                  term: {
                    'accessControl.keyword': orgPublicId,
                  },
                },
                {
                  term: {
                    'status.keyword': 'SUCCEEDED',
                  },
                },
                {
                  term: {
                    'category.taxDeductible': true,
                  },
                },
                {
                  term: {
                    'indexType.keyword': TRANSACTIONS,
                  },
                },
                {
                  term: {
                    'customer.id': customerId,
                  },
                },
              ],
              filter: [
                {
                  range: {
                    transactionDate: {
                      gte: year + '-01-01T08:00:00Z',
                      lte: Number(year)+1 + '-01-01T07:59:59Z',
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    aggs: {
      customerTotal: {
        sum: {
          field: 'amount',
        },
      },
    },
    sort: {
      transactionDate: 'asc',
    },
  };

  let response = await doSearch(authenticatedUser, body);
  console.log(response);
  let customer = response.hits.hits.find((x) => x._id.includes('cust_'));
  let transactions = response?.hits?.hits?.filter((x) => x._id.includes('tran_'));
  let totalDonationAmount = response?.aggregations?.customerTotal?.value;
  console.log(totalDonationAmount);
  const customerData = { ...customer._source, transactions: [...transactions], totalDonationAmount };
  return customerData;
};

const getCustomersByIds = (authenticatedUser, ids, sourceFields) => {
  return getDocumentsByIds(authenticatedUser, ids, sourceFields, DONORS);
};

const getCausesByIds = (authenticatedUser, ids, sourceFields) => {
  return getDocumentsByIds(authenticatedUser, ids, sourceFields, CAUSES);
};
const getTransactionsByIds = (authenticatedUser, ids, sourceFields) => {
  return getDocumentsByIds(authenticatedUser, ids, sourceFields, TRANSACTIONS);
};

const getSubscriptionsByIds = (authenticatedUser, ids, sourceFields) => {
  return getDocumentsByIds(authenticatedUser, ids, sourceFields, SUBSCRIPTIONS);
};

const getPledgeById = (authenticatedUser, id) => {
  return getDocumentById(authenticatedUser, id, PLEDGES);
};

const getPledgesToDownload = (authenticatedUser, filter, queryString, sourceFields) => {
  return getDataByFilter(authenticatedUser, filter, queryString, sourceFields, PLEDGES);
};

const getPledgesByIds = (authenticatedUser, ids, sourceFields) => {
  return getDocumentsByIds(authenticatedUser, ids, sourceFields, PLEDGES);
};

const ElasticSearchAPIClient = {
  getDocumentById,
  getDocumentsByIds,
  getCustomersByIds,
  getTransactionById,
  getSubscriptionById,
  getCauseById,
  doSearch,
  getAllCauses,
  getCustomerInfoByFilterText,
  getTemplateById,
  getCustomerById,
  getDataByFilter,
  getTransactionsToDownload,
  getRecordsByDate,
  getTransactionTotals,
  getExpiringSubscriptions,
  getTransactionSumForGraph,
  getIncomeTransactionsByCategory,
  getTransactionsByIds,
  getCustomersToDownload,
  getCausesToDownload,
  getCausesByIds,
  getSubscriptionsToDownload,
  getSubscriptionsByIds,
  getAllCustomersWithTransactionInYear,
  getCustomerTransactionsByYear,
  getTemplateByType,
  getPledgesToDownload,
  getPledgesByIds,
  getPledgeById,
};

export default ElasticSearchAPIClient;
