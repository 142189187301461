import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Datatable from './datatable';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!array) {
    return;
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function StaticContentDatatable(props) {
  const {
    tableOptions,
    bulkActions,
    globalActions,
    singleRowActions,
    onMoreInfoClick,
    columns,
    initRowData,
    dateRange,
  } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [queryString, setQueryString] = useState();
  const [state, setState] = useState({
    totalDataCount: initRowData?.length ? initRowData.length : 0,
    data: initRowData,
    filter: {
      query: '',
      order: 'desc',
      orderBy: 'dateCreated',
      page: 0,
      rowsPerPage: tableOptions?.rowsPerPage || 25,
      dateRange: dateRange,
    },
    selected: [],
  });

  useEffect(() => {
    setState({
      ...state,
      data: initRowData,
    });
  }, [initRowData]);

  const performSearch = () => {
    let updateData = stableSort(initRowData, getComparator(state.filter.order, state.filter.orderBy));
    updateData = updateData.slice(
      state.filter.page * state.filter.rowsPerPage,
      state.filter.page * state.filter.rowsPerPage + state.filter.rowsPerPage,
    );
    setState({
      ...state,
      data: updateData,
      filterProcessing: false,
    });
  };

  useEffect(() => {
    if (state.filterProcessing) {
      return;
    }
    setState({
      ...state,
      data: initRowData,
      filterProcessing: true,
    });
    performSearch();
  }, [state.filter]);

  const singleRowActionsWithModifiedDoAction = singleRowActions?.map(function (singleAction) {
    if (!singleAction.alertMessage) {
      return {
        ...singleAction,
        doAction: singleAction.doAction,
      };
    } else {
      return {
        ...singleAction,
        doAction: (rowData) => {
          const action = (key) => (
            <Button
              color="secondary"
              onClick={() => {
                closeSnackbar(key);
              }}>
              Undo
            </Button>
          );
          enqueueSnackbar(singleAction.alertMessage, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            onClose: (event, reason) => {
              if (reason === 'timeout') {
                singleAction.doAction(rowData).then(
                  (response) => {
                    if (singleAction.getUpdateRowData) {
                      const updatedRowData = singleAction.getUpdateRowData(rowData);
                      const updatedData = state.data.map((singleRowData) =>
                        singleRowData.id === updatedRowData.id ? updatedRowData : singleRowData,
                      );
                      setState({
                        ...state,
                        data: updatedData,
                      });
                    }
                  },
                  (rejectedResult) => {
                    enqueueSnackbar('Something went wrong. Please try again... ', {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  },
                );
              }
            },
            autoHideDuration: 3000,
            action,
          });
        },
      };
    }
  });

  const bulkActionsWithModifiedDoAction = bulkActions?.map(function (action) {
    return {
      ...action,
      doAction: () => {
        action.doAction(state.selected);
      },
    };
  });

  const globalActionsWithModifiedDoAction = globalActions?.map(function (action) {
    return {
      ...action,
      doAction: () => {
        action.doAction(state.data);
      },
    };
  });
  const handleQueryChange = (value) => {
    let filteredData = initRowData;
    if (value) {
      filteredData = initRowData.filter((x) => x?.email?.includes(value) || x?.name?.includes(value));
    }
    setQueryString(value);
    setState({
      ...state,
      data: filteredData,
    });
  };

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (state.filter.orderBy === property && state.filter.order === 'desc') {
      order = 'asc';
    }
    const updatedFilter = {
      ...state.filter,
      orderBy,
      order,
      page: 0,
    };
    setState({ ...state, filter: updatedFilter });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setState({ ...state, selected: state.data.map((n) => n.id) });
      return;
    }
    setState({ ...state, selected: [] });
  };

  const handleClick = (event, id) => {
    const { selected } = state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setState({ ...state, selected: newSelected });
  };

  const handleChangePage = (event, page) => {
    const updatedFilter = {
      ...state.filter,
      page,
    };
    setState({ ...state, filter: updatedFilter });
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedFilter = {
      ...state.filter,
      rowsPerPage: event.target.value,
      page: 0,
    };
    setState({
      ...state,
      filter: updatedFilter,
    });
  };

  return (
    <React.Fragment>
      {initRowData && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Datatable
              data-cy={'static-content-datatable'}
              tableOptions={tableOptions}
              columns={columns}
              totalDataCount={state.totalDataCount}
              data={state.data}
              filter={state.filter}
              filterActionHandlers={{
                disabled: state.filterProcessing,
                handleRequestSort: handleRequestSort,
                handleChangePage: handleChangePage,
                handleChangeRowsPerPage: handleChangeRowsPerPage,
              }}
              selected={state.selected}
              bulkActions={bulkActionsWithModifiedDoAction}
              globalActions={globalActionsWithModifiedDoAction}
              singleRowActions={singleRowActionsWithModifiedDoAction}
              onMoreInfoClick={onMoreInfoClick}
              selectionActionHandlers={{
                handleSelectAllClick: handleSelectAllClick,
                handleClick: handleClick,
              }}
              // onDateRangeChange={handleDateRangeChange}
              onQueryChange={handleQueryChange}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
