import React, { useContext, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import DynamicForm from '../components/dynamicForm/DynamicForm';
import Box from '@material-ui/core/Box';
import GoodbricksAPIClient from '../restClients/GoodbricksAPIClient';
import { UserContext } from '../components/UserContext';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

function OrgSetup(props) {
  const history = useHistory();
  const { authenticatedUser } = useContext(UserContext);
  const handleSubmitOrgInfoClick = async (data) => {
    GoodbricksAPIClient.registerOrg(authenticatedUser, data).then((r) => {
      try {
        Auth.currentAuthenticatedUser().then((cognitoUser) => {
          Auth.currentSession().then((currentSession) => {
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
              console.log('Post Register session', session);
              history.push('/');
            });
          });
        });
      } catch (e) {
        console.log('Unable to refresh Token', e);
      }
    });
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((cognitoUser) => {
      Auth.currentSession().then((currentSession) => {
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          console.log('Refresh session', session);
          history.push('/');
        });
      });
    });
  }, []);

  const fields = [
    {
      // gridWidth: 6,
      name: 'name',
      label: 'Organization Name',
      fieldType: 'text',
      errorMessage: 'Please enter your organization name.',
    },
    {
      gridWidth: 7,
      name: 'email',
      label: 'Email',
      fieldType: 'text',
      inputType: 'email',
    },
    {
      gridWidth: 5,
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
      minLength: 14,
    },
    {
      gridWidth: 6,
      name: 'url',
      label: 'Website URL',
      fieldType: 'text',
      inputType: 'url',
    },
    {
      gridWidth: 6,
      name: 'ein',
      label: 'EIN',
      fieldType: 'masked',
      pattern: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      minLength: 9,
    },
  ];
  return (
    <React.Fragment>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        Lets finish your setup. We need some information about your organization.
      </Typography>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formSubmitCallback={handleSubmitOrgInfoClick}
          formOptions={{ submitButtonLabel: 'Complete Setup' }}
        />
      </Box>
    </React.Fragment>
  );
}

export default OrgSetup;
