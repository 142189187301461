import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useHistory } from 'react-router-dom';
import NavLink from '../../components/displayValues/NavLink';

function ResetPassword(props) {
  const fields = [
    {
      name: 'code',
      label: 'Verification Code',
      fieldType: 'text',
      inputType: 'password',
    },
    {
      name: 'password',
      label: 'New Password',
      fieldType: 'password',
    },
    {
      name: 'confirm-password',
      label: 'Confirm New Password',
      fieldType: 'password-confirm',
      inputType: 'password',
      passwordField: 'password',
    },
  ];
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();
  const handleForgotPasswordClick = async (data) => {
    const email = props?.location?.state?.email;
    try {
      setErrorMessage(null);
      Auth.forgotPasswordSubmit(email, data.code, data.password)
        .then((response) => {
          history.push('/auth/sign-in');
        })
        .catch((error) => {
          setErrorMessage(error.message);
          console.log(error);
        });
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" align="center" gutterBottom color={'primary'}>
        Reset your password
      </Typography>
      <Box mb={5}>
        <Typography align="center" gutterBottom color="textSecondary">
          We have sent a password reset code to your email. <br />
          Please enter it below to reset your password.
        </Typography>
      </Box>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formSubmitCallback={handleForgotPasswordClick}
          formOptions={{ submitButtonLabel: 'Set New Password' }}
          errorMessage={errorMessage}
        />
      </Box>
      <Box>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Didn't receive a verification code?&nbsp;
          <NavLink href="/auth/forgot-password" text="Resend code" />
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default ResetPassword;
