import React from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import TableCell from '@material-ui/core/TableCell/TableCell';
import SingleRowActions from './SingleRowActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function DatatableRow(props) {
  const { rowData, columns, bulkActions, singleRowActions, onRowClick, isSelected, onMoreInfoClick } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow
        data-cy={rowData.id}
        className={classes.root}
        hover
        // onClick={(event) => onRowClick(event, rowData.publicId)}
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}>
        {bulkActions && bulkActions.some(x => x.visible) && (
          <TableCell padding="checkbox">
            <Checkbox checked={isSelected} color="default" onChange={(event) => onRowClick(event, rowData.id)} />
          </TableCell>
        )}
        {onMoreInfoClick && (
          <TableCell padding="checkbox">
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column) => {
          return (
            <TableCell
              data-cy={`${column.id}`}
              key={column.id}
              component="th"
              align={column.align ? column.align : 'left'}
              padding={column.disablePadding ? 'none' : 'default'}>
              <Typography variant="body2" component="span">
                {column.cellContent ? column.cellContent(rowData) : rowData[column.id]}
              </Typography>
            </TableCell>
          );
        })}
        {singleRowActions && singleRowActions.some(x=>x.visible) && (
          <TableCell key="single-actions">
            <SingleRowActions singleRowActions={singleRowActions} rowData={rowData} />
          </TableCell>
        )}
      </TableRow>
      {onMoreInfoClick && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {onMoreInfoClick(rowData)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default DatatableRow;
