import React from 'react';
import GoodbricksTextField from './GoodbricksTextField';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMaskedInput from './TextMaskedInput';
// First, you need to create the `numberMask` with your desired configurations
const numberMask = createNumberMask({
  prefix: '$',
  suffix: '', // This will put the dollar sign at the end, with a space.
  allowDecimal: true,
});

export default function AmountTextMaskedFormatField(props) {
  const { name, label, value, onChange, readOnly, mask, ...others } = props;
  return (
    <GoodbricksTextField
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      id={name}
      InputProps={{
        inputComponent: TextMaskedInput,
      }}
      inputProps={{
        readOnly: readOnly,
        'data-cy': name,
        mask: numberMask,
      }}
      {...others}
    />
  );
}
