import React, { useState } from 'react';
import GoogleAddressAutocomplete from './GoogleAddressAutocomplete';
import GoodbricksTextField from './GoodbricksTextField';
import Grid from '@material-ui/core/Grid';

export default function AddressTextField(props) {
  const { id, name, register, value, setValue, getValues } = props;
  const [shrinkState, setShrinkState] = useState(true);
  const handleAddressChange = (address) => {
    setValue(name + 'Street', (address['street_number'] || '') + ' ' + (address['route'] || ''));
    setValue(name + 'City', address['locality']);
    setValue(name + 'State', address['administrative_area_level_1']);
    setValue(name + 'ZipCode', address['postal_code']);
    setValue(name + 'Apt', '');
    setShrinkState(true);
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={5}>
        <Grid item xs={8}>
          <GoogleAddressAutocomplete
            onAddressChange={handleAddressChange}
            autoComplete="off"
            key={id + '_street'}
            label={'Street'}
            name={name + 'Street'}
            defaultValue={value[name + 'Street']}
            inputRef={register()}
            fullWidth
            InputLabelProps={{ shrink: shrinkState }}
            getValues={getValues}
          />
        </Grid>
        <Grid item xs={4}>
          <GoodbricksTextField
            label={'Apt, Suite, Unit,etc'}
            autoComplete="off"
            key={id + '_apt'}
            name={name + 'Apt'}
            defaultValue={value[name + 'Apt']}
            inputRef={register()}
            fullWidth
            InputLabelProps={{ shrink: shrinkState }}
          />
        </Grid>
        <Grid item xs={5}>
          <GoodbricksTextField
            label={'City'}
            autoComplete="off"
            key={id + '_city'}
            name={name + 'City'}
            defaultValue={value[name + 'City']}
            inputRef={register()}
            fullWidth
            InputLabelProps={{ shrink: shrinkState }}
          />
        </Grid>
        <Grid item xs={3}>
          <GoodbricksTextField
            label={'State'}
            autoComplete="off"
            key={id + '_state'}
            name={name + 'State'}
            defaultValue={value[name + 'State']}
            inputRef={register()}
            fullWidth
            InputLabelProps={{ shrink: shrinkState }}
          />
        </Grid>
        <Grid item xs={4}>
          <GoodbricksTextField
            label={'Zip Code'}
            autoComplete="off"
            key={id + '_zipCode'}
            name={name + 'ZipCode'}
            defaultValue={value[name + 'ZipCode']}
            inputRef={register()}
            fullWidth
            InputLabelProps={{ shrink: shrinkState }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
