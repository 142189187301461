import React from 'react';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export default function SingleRowActions(props) {
  const { singleRowActions, rowData } = props;
  const handleClick = (event, doAction, rowData) => {
    event.stopPropagation();
    if (doAction && rowData) {
      doAction(rowData);
    }
  };
  const singleRowActionItems = singleRowActions.map((singleRowAction, index) => (
    singleRowAction.visible &&
    <Tooltip title={singleRowAction.displayName} key={singleRowAction.id}>
      {singleRowAction.type === 'link' ? (
        <Button aria-label={singleRowAction.displayName} size="small">
          {singleRowAction.onLinkDisplay(rowData)}
        </Button>
      ) : (
        <Button
          aria-label={singleRowAction.displayName}
          onClick={(e) => {
            handleClick(e, singleRowAction.doAction, rowData);
          }}
          size="small">
          {singleRowAction.icon}
        </Button>
      )}
    </Tooltip>
  ));

  return (
    <ButtonGroup color="default" size="small" variant="text">
      {singleRowActionItems}
    </ButtonGroup>
  );
}
