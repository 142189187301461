import React, { useState } from 'react';
import styled from 'styled-components';

import { Button as MuiButton, Grid, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useRouteMatch } from 'react-router-dom';
import NavLink from '../../components/displayValues/NavLink';

const FacebookButton = styled(MuiButton)`
  background-color: #4267b2;
  color: white;
  &:hover {
    background-color: #3257a2;
  }
`;

const GoogleButton = styled(MuiButton)`
  background-color: #db4437;
  color: white;
  &:hover {
    background-color: #cb3427;
  }
`;

const OrDivider = styled.div`
  &:after,
  &:before {
    background-color: rgba(0, 0, 0, 0.12);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 45%;
  }
  &:before {
    right: 0.5em;
    margin-left: 3%;
  }
  &:after {
    left: 0.5em;
  }
`;

const fields = [
  {
    name: 'email',
    label: 'Email',
    fieldType: 'text',
    inputType: 'email',
    errorMessage: 'Please enter your email',
    // placeholder: 'name@example.com',
  },
  {
    name: 'password',
    label: 'Password',
    fieldType: 'text',
    inputType: 'password',
    errorMessage: 'Please enter a valid password',
    // placeholder: '********',
  },
];

function SignIn() {
  const [errorMessage, setErrorMessage] = useState();
  const match = useRouteMatch('/auth/sign-in');
  const handleSignInClick = async (data) => {
    try {
      await Auth.signIn(data.email, data.password).then(() => {
        if (!match?.isExact) {
          window.location.reload(false);
        } else {
          window.location = '/';
        }
      });
    } catch (error) {
      setErrorMessage(error.message);
      console.log('error confirming sign up', error);
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" align="center" gutterBottom color={'primary'}>
        Welcome Back
      </Typography>
      <Typography align="center" gutterBottom color="textSecondary">
        Sign In with your email and password
      </Typography>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formOptions={{ submitButtonLabel: 'Sign In' }}
          formSubmitCallback={handleSignInClick}
          errorMessage={errorMessage}
        />
      </Box>
      <Box mb={5}>
        <Grid container>
          <Grid item xs>
            <NavLink href="/auth/forgot-password" text="Forgot password" />
          </Grid>
          <Grid item>
            <Typography component="h2" variant="body1" align="center" gutterBottom>
              Need an account?&nbsp;
              <NavLink href="/auth/sign-up" text="Sign Up" />
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <OrDivider>
        <Typography variant="h6" align="center" color="textSecondary" component="span">
          or
        </Typography>
      </OrDivider>
      <Box my={5} />
      <Grid container direction="row" spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h6" align="center" gutterBottom>
              Sign In with your social account
            </Typography>
          </Box>
          <Box mb={5}>
            <Typography align="center" gutterBottom color="textSecondary">
              We won't post to your account without your permission
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <GoogleButton onClick={() => Auth.federatedSignIn({ provider: 'Google' })} fullWidth size="large">
            Login with Google
          </GoogleButton>
        </Grid>
        <Grid item xs>
          <FacebookButton onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })} fullWidth size="large">
            Login with Facebook
          </FacebookButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SignIn;
