import React, { useEffect } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash.debounce';
import { Autocomplete } from '@material-ui/lab';
import GoodbricksTextField from './GoodbricksTextField';
import ElasticSearchAPIClient from '../../../restClients/ElasticSearchAPIClient';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleAddressAutocomplete(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState(props.defaultValue);
  const [options, setOptions] = React.useState([]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // useEffect(() => {
  //   if(!inputValue){
  //     props.getValues("addressStreet")?.then( res => {
  //         setInputValue(res)
  //       }
  //     )
  //   }
  // }, []);

  const parseAddress = (addressComponent) => {
    let addressTypes = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let address = {};
    for (let i = 0; i < addressComponent.length; i++) {
      let addressType = addressComponent[i].types[0];
      if (addressTypes[addressType]) {
        address[addressType] = addressComponent[i][addressTypes[addressType]];
      }
    }

    return address;
  };

  const handleAddressChange = (event, value) => {
    if (['click', 'keydown'].includes(event.type)) {
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: value?.place_id }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const parsedAddress = parseAddress(results[0].address_components);
          props.onAddressChange(parsedAddress);

          (parsedAddress['street_number'] || parsedAddress['route']) &&
            setInputValue((parsedAddress['street_number'] || '') + ' ' + (parsedAddress['route'] || ''));
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  };

  const fetch = React.useMemo(
    () =>
      debounce((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoHighlight
      includeInputInList
      autoSelect
      freeSolo
      onChange={handleAddressChange}
      renderInput={(params) => {
        const { inputProps, ...restParams } = params;
        inputProps.value = inputValue;
        return (
          <GoodbricksTextField
            autoComplete="off"
            inputProps={inputProps}
            {...restParams}
            label="Street"
            //   variant="outlined"
            fullWidth
            onChange={handleInputChange}
          />
        );
      }}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
