import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LabelValueGridItem from '../../components/displayValues/LabelValueGridItem';
import { UserContext } from '../../components/UserContext';
import PhoneNumber from '../../components/displayValues/PhoneNumber';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';

export default function TransactionMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [transactionData, setTransactionData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getTransactionById(authenticatedUser, rowData.id).then((response) => {
      const transactions = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setTransactionData(transactions[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography
          variant="body1"
          color="primary"
          gutterBottom
          data-cy={`transaction_details_label_${transactionData?.id}`}>
          Transaction Details
        </Typography>
        <Box my={3} />
        <Grid container direction="row" spacing={3} alignItems="center">
          <LabelValueGridItem
            label={'TxnId'}
            value={transactionData?.publicId}
            id={`transaction_id_${transactionData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Source'}
            value={transactionData?.source}
            id={`source_${transactionData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Reference ID'}
            value={transactionData?.paymentProcessorTransactionId || '-'}
            id={`referenceId_${transactionData?.paymentProcessorTransactionId}_value`}
          />
          <LabelValueGridItem
            label={'Refund ID'}
            value={transactionData?.paymentProcessorRefundId}
            id={`refundId_${transactionData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Email'}
            value={transactionData?.customer.email}
            id={`email_${transactionData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Phone'}
            value={<PhoneNumber data={transactionData?.customer.phone} />}
            id={`phone_${transactionData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Notes'}
            value={transactionData?.notes}
            id={`notes_${transactionData?.id}_value`}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
}
