// CauseDetails.jsx
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import DynamicFormFragment from '../../../components/dynamicForm/DynamicFormFragment';
import { ArrowBackOutlined, ArrowForwardOutlined, SaveOutlined } from '@material-ui/icons';

function CauseDetails(props) {
  const { data, nextStep, handleDataChange, submitDataChanges } = props;
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const dataFields = [
    'name',
    'title',
    'externalCategoryId',
    'description',
    'summaryDescription',
    'featureImageUrl',
    'featureVideoUrl',
    'slug',
    'publicVisible',
  ];
  const dataCheckboxFields = ['zakatEligible', 'taxDeductible'];

  const { register, handleSubmit, errors, setValue, control, watch } = useForm({ mode: 'onBlur' });

  const saveAndContinue = () => {
    nextStep();
  };

  const saveCause = () => {
    submitDataChanges();
  };

  const handleFieldDataChange = (event) => {
    if (event.target.name === 'name' && event.target.value !== '') {
      setButtonDisabled(false);
    } else if (event.target.name === 'name' && event.target.value === '') {
      setButtonDisabled(true);
    }
    handleDataChange({ [event.target.name]: event.target.value });
  };

  const handleCheckboxDataChange = (event) => {
    setValue(event.target.name, event.target.checked ? true : false);
    handleDataChange({ [event.target.name]: event.target.checked ? true : false });
  };

  useEffect(() => {
    setButtonDisabled(data?.name === '');
    dataFields.map((field) => setValue(field, data[field]));
  }, []);

  // for checkboxes
  useEffect(() => {
    dataCheckboxFields.map((field) => {
      setValue(field, data[field] ? true : false);
    });
  }, []);

  const fields = [
    {
      name: 'name',
      label: 'Name',
      minLength: 3,
      required: false,
      disabled: data?.id ? true : false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'slug',
      label: 'Slug',
      pattern: /^[ A-Za-z0-9_-]*$/,
      patternErrorMessage: "Only alphanumeric, '-' and '_' are allowed",
      required: false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'externalCategoryId',
      label: 'External Category Id',
      required: false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'title',
      label: 'Title (Shared on social media)',
      minLength: 3,
      required: false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'description',
      label: 'Description',
      fieldType: 'richTextEditor',
      required: false,
      placeholder: 'Description',
      onChange: handleDataChange,
      value: data['description'] ? data['description'] : '',
    },
    {
      name: 'featureImageUrl',
      label: 'Feature Image Url (Shared on social media)',
      required: false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'featureVideoUrl',
      label: 'Feature Video',
      required: false,
      onChange: handleFieldDataChange,
    },
    {
      name: 'summaryDescription',
      label: 'Summary Text (Shared on social media)',
      fieldType: 'textarea',
      rows: 2,
      required: false,
      maxLength: 250,
      onChange: handleFieldDataChange,
    },
    {
      gridWidth: 12,
      name: 'publicVisible',
      label: 'Show On Kiosk',
      fieldType: 'checkbox',
      value: data['publicVisible'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 12,
      name: 'zakatEligible',
      label: 'Zakat Eligible',
      fieldType: 'checkbox',
      value: data['zakatEligible'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 12,
      name: 'taxDeductible',
      label: 'Tax Deductible',
      fieldType: 'checkbox',
      value: data['taxDeductible'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
  ];

  return (
    <React.Fragment>
      <form>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Box my={5} />
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  data-cy={'Submit'}
                  color="default"
                  disabled
                  startIcon={<ArrowBackOutlined />}>
                  Back
                </Button>
              </Grid>
              <Box display="flex" justifyContent="flex-end" spacing={2}>
                <Box p={1}>
                  <Button
                    type="submit"
                    onClick={handleSubmit(saveAndContinue)}
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    disabled={buttonDisabled}
                    endIcon={<ArrowForwardOutlined />}>
                    Next
                  </Button>
                </Box>
                <Box p={1}>
                  <Button
                    type="submit"
                    onClick={handleSubmit(saveCause)}
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    disabled={buttonDisabled}
                    startIcon={<SaveOutlined />}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box my={10} />
          <DynamicFormFragment fields={fields} register={register} errors={errors} control={control} watch={watch} />
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default CauseDetails;
