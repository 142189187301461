import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Grid, Typography } from '@material-ui/core';
import { UserContext } from '../../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../../restClients/GoodbricksLegacyAPIClient';
import NavLink from '../../../components/displayValues/NavLink';
import DynamicForm from '../../../components/dynamicForm/DynamicForm';
import { useSnackbar } from 'notistack';
import ElasticSearchAPIClient from '../../../restClients/ElasticSearchAPIClient';
import { USER_ROLES } from '../../../Constants';
import Page401 from '../../error/Page401';

export default function EmailSettingsEdit(props) {
  const {
    match: { params },
  } = props;
  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const submitEmailSettingsChanges = (data) => {
    GoodbricksLegacyAPIClient.saveEmailSettings(authenticatedUser, data).then(
      (response) => {
        enqueueSnackbar('Email  Saved successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      },
      (error) => {
        enqueueSnackbar('Something went wrong. Please try again... ', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        console.log(error);
      },
    );
  };

  const [emailSetting, setEmailSetting] = useState();

  const fields = [
    {
      name: 'email-settings',
      label: emailSetting?.type ? emailSetting.type + ' EMAIL' : '',
      value: emailSetting?.type ? emailSetting.type : '',
      fieldType: 'section-header',
    },
    {
      name: 'type',
      fieldType: 'hidden',
      value: emailSetting?.type ? emailSetting.type : '',
    },
    {
      name: 'bcc',
      label: 'BCC',
      fieldType: 'emailChips',
      placeholderText: 'Please type BCC email and press enter',
      value: emailSetting?.bcc ? emailSetting.bcc.split(',') : '',
    },
    {
      name: 'whitelistEmailAddresses',
      label: 'White Listed Email Addresses',
      fieldType: 'emailChips',
      placeholderText: 'Please type Whitelist email address and press enter',
      value: emailSetting?.whitelistEmailAddresses ? emailSetting.whitelistEmailAddresses.split(',') : '',
    },
    {
      name: 'subject',
      label: 'Subject',
      required: false,
      value: emailSetting?.subject ? emailSetting.subject : '',
    },
    {
      name: 'title',
      label: 'Title',
      required: false,
      value: emailSetting?.title ? emailSetting.title : '',
    },
    {
      name: 'body',
      label: 'Body',
      fieldType: 'textarea',
      value: emailSetting?.body ? emailSetting.body : '',
    },
    {
      name: 'signature',
      label: 'Signature',
      fieldType: 'textarea',
      value: emailSetting?.signature ? emailSetting.signature : '',
    },
  ];

  useEffect(() => {
    ElasticSearchAPIClient.getTemplateById(authenticatedUser, params.id).then((response) => {
      const setting = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      return setEmailSetting({
        ...setting[0],
      });
    });
  }, []);

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Box my={9}>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/settings/templates" text="Email Templates" color="primary" />
              {!!emailSetting ? (
                <Typography component="span">Edit</Typography>
              ) : (
                <Typography component="span">New</Typography>
              )}
            </Breadcrumbs>
          </Box>
          {emailSetting && (
            <Grid container spacing={6}>
              <Grid item xs={12} md={8}>
                <DynamicForm
                  fields={fields}
                  formSubmitCallback={submitEmailSettingsChanges}
                  formOptions={{
                    acceptStripePayment: false,
                    submitButtonLabel: 'Save Email Settings',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}
