import React from 'react';
import { Table, TablePagination } from '@material-ui/core';
import styled from 'styled-components';
import DatatableToolbar from './DatatableToolbar';
import DatatableHeader from './DatatableHeader';
import DatatableBody from './DatatableBody';
import Box from '@material-ui/core/Box';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

export default function Datatable(props) {
  const {
    tableOptions,
    selected,
    isLoading,
    columns,
    data,
    filter,
    filterActionHandlers,
    bulkActions,
    globalActions,
    displayGlobalMetricData,
    singleRowActions,
    totalDataCount,
    selectionActionHandlers,
    onDateRangeChange,
    onQueryChange,
    onMoreInfoClick,
    label,
    isCustomersByYear,
  } = props;
  const emptyRows = filter.rowsPerPage - Math.min(filter.rowsPerPage, data.length);
  return (
    <React.Fragment>
      {!tableOptions?.hideToolbar && (
        <DatatableToolbar
          tableOptions={tableOptions}
          numSelected={selected.length}
          bulkActions={bulkActions}
          globalActions={globalActions}
          displayGlobalMetricData={displayGlobalMetricData}
          onDateRangeChange={onDateRangeChange}
          onQueryChange={onQueryChange}
          label={label}
          isCustomersByYear={isCustomersByYear}
        />
      )}
      {!tableOptions?.hideToolbar && <Box my={5} />}
      <TableWrapper>
        <Table aria-labelledby="tableTitle">
          <DatatableHeader
            columns={columns}
            numSelected={selected.length}
            order={filter.order}
            orderBy={filter.orderBy}
            onSelectAllClick={selectionActionHandlers.handleSelectAllClick}
            onRequestSort={filterActionHandlers.handleRequestSort}
            rowCount={Math.min(filter.rowsPerPage, data.length)}
            onMoreInfoClick={onMoreInfoClick}
            bulkActions={bulkActions}
            singleRowActions={singleRowActions}
          />
          <DatatableBody
            columns={columns}
            data={data}
            isLoading={isLoading}
            rowsPerPage={filter.rowsPerPage}
            bulkActions={bulkActions}
            singleRowActions={singleRowActions}
            onRowClick={selectionActionHandlers.handleClick}
            selected={selected}
            emptyRows={emptyRows}
            onMoreInfoClick={onMoreInfoClick}
          />
        </Table>
      </TableWrapper>
      {!tableOptions?.hidePagination && (
        <TablePagination
          SelectProps={{ 'data-cy': 'table-pagination' }}
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={totalDataCount}
          rowsPerPage={filter.rowsPerPage}
          page={filter.page}
          backIconButtonProps={{
            disabled: filter.page === 0 || filterActionHandlers.disabled,
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            disabled: filter.rowsPerPage * (filter.page + 1) >= totalDataCount || filterActionHandlers.disabled,
            'aria-label': 'Next Page',
          }}
          onChangePage={filterActionHandlers.handleChangePage}
          onChangeRowsPerPage={filterActionHandlers.handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
}
