import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function LabelValueGridItem({ label, value, id }) {
  return (
    <Grid item xs={3} lg={2}>
      <Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom data-cy={label + '_label'}>
        {label}
      </Typography>
      <span data-cy={id}>{value ? value : '-'}</span>
    </Grid>
  );
}

export default LabelValueGridItem;
