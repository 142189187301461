import { API } from 'aws-amplify';
import { SUBSCRIPTION_PLEDGE } from '../Constants';

const apiName = 'GoodbricksLegacyAPI';
const path = '/api';

const cancelSubscriptionById = (authenticatedUser, subscriptionId) => {
  let apiPath = path + '/subscription/' + subscriptionId.substr(4) + '/cancel';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const emailTaxReceiptByCustomerId = (authenticatedUser, customerId) => {
  let apiPath = path + '/customer/' + customerId.substr(5) + '/emailTaxReceipt';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const refundTransactionById = (authenticatedUser, transaction) => {
  let apiPath = path + '/transaction/' + transaction.substr(5) + '/refund';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const sendReceiptToDonorByTransactionId = (authenticatedUser, transaction) => {
  let apiPath = path + '/transaction/' + transaction.substr(5) + '/email';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const sendYearEndReceiptToDonor = (authenticatedUser, customerId, year) => {
  let apiPath = path + '/customer/' + customerId.substr(5) + '/emailYearEndTaxReceipt/year/' + year;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const retrieveCauseById = (authenticatedUser, causeId) => {
  let apiPath = path + '/incomeCategory/' + causeId;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const retrieveCustomerById = (authenticatedUser, customerId) => {
  let apiPath = path + '/customer/' + customerId;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const updateCause = (authenticatedUser, data) => {
  let apiPath = path + '/incomeCategory';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.put(apiName, apiPath, apiInit);
};

const updateCustomer = (authenticatedUser, data) => {
  let apiPath = path + '/customer';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.put(apiName, apiPath, apiInit);
};

const saveCause = (authenticatedUser, data) => {
  let apiPath = path + '/incomeCategory';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const saveTransaction = (authenticatedUser, data) => {
  if (data.id) {
    data.publicId = data.id;
  }
  data.name = data.name ? data.name : data.slug;
  const currentDate = new Date();
  if (data.transactionDate >= currentDate.getTime()) {
    data.scheduledDate = data.transactionDate;
    data.transactionDate = undefined;
  }
  let apiPath = path + '/income';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const saveTransactionNoEmail = (authenticatedUser, data) => {
  if (data.id) {
    data.publicId = data.id;
  }
  if (data.referenceId) {
    data.otherTransactionReferenceId = data.referenceId;
  }
  data.name = data.name ? data.name : data.slug;
  let apiPath = path + '/income/noEmail';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const saveSubscription = (authenticatedUser, data) => {
  let apiPath = path + '/subscription';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const saveBusinessSettings = (authenticatedUser, data) => {
  let apiPath = path + '/setting/update/businessProfile';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const fetchBusinessSettings = (authenticatedUser) => {
  let apiPath = path + '/setting/businessProfile';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
      // Authorization: `Basic d2VibWFzdGVyQGljc2Qub3JnOlNhbGFhbTEyMw==`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const retrieveEmailSettingsById = (authenticatedUser, id) => {
  let apiPath = path + '/setting/subscriptionEmail'; //replace id with subscriptionEmail ;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const saveEmailSettings = (authenticatedUser, data) => {
  let apiPath = path + '/setting/update/emailSetting';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const updateCreditCard = (authenticatedUser, data) => {
  let apiPath = path + '/customer/updateCreditCard';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.put(apiName, apiPath, apiInit);
};

const isStripeConnected = (authenticatedUser) => {
  let apiPath = path + '/stripe/connect';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const disconnectStripe = (authenticatedUser) => {
  let apiPath = path + '/stripe/disconnect';
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const connectStripeWithConnectUrl = (authenticatedUser, url) => {
  return fetch(url).then((res) => {
    res.json();
  });
};

const confirmConnect = (authenticatedUser, queryString) => {
  let apiPath = path + 'api/stripe/webhook/connect?' + queryString;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.get(apiName, apiPath, apiInit);
};

const plaidCreateLinkToken = () => {
  return fetch(process.env.REACT_APP_GOODBRICKS_URL + '/api/stripe/create_link_token');
};
const createBankToken = (body) => {
  return fetch(`${process.env.REACT_APP_GOODBRICKS_URL}/api/stripe/bank_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

const savePledge = (authenticatedUser, data) => {
  data.isPledge = true;
  if (data.id) {
    data.publicId = data.id;
  }
  if (data.subscribe == SUBSCRIPTION_PLEDGE) {
    data.subscribe = true;
  } else {
    data.subscribe = false;
  }
  let apiPath = path + '/pledge';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const syncPledgeTransactions = (authenticatedUser, pledgeId) => {
  let apiPath = path + `/pledge/${pledgeId.substr(5)}/sync/transactions`;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const sendPledgeReminder = (authenticatedUser, pledgeId) => {
  let apiPath = path + `/pledge/${pledgeId.substr(5)}/send/reminder`;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const cancelPledge = (authenticatedUser, pledgeId) => {
  let apiPath = path + `/pledge/${pledgeId.substr(5)}/cancel`;
  const apiInit = {
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, apiPath, apiInit);
};

const GoodbricksLegacyAPIClient = {
  cancelSubscriptionById,
  emailTaxReceiptByCustomerId,
  refundTransactionById,
  sendReceiptToDonorByTransactionId,
  sendYearEndReceiptToDonor,
  retrieveCauseById,
  retrieveCustomerById,
  saveCause,
  updateCause,
  saveTransaction,
  saveTransactionNoEmail,
  saveSubscription,
  updateCustomer,
  saveBusinessSettings,
  fetchBusinessSettings,
  saveEmailSettings,
  retrieveEmailSettingsById,
  updateCreditCard,
  isStripeConnected,
  disconnectStripe,
  connectStripeWithConnectUrl,
  confirmConnect,
  plaidCreateLinkToken,
  createBankToken,
  savePledge,
  syncPledgeTransactions,
  sendPledgeReminder,
  cancelPledge,
};

export default GoodbricksLegacyAPIClient;
