import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Sidebar from '../../components/Sidebar';
import Footer from './Footer';

import { spacing } from '@material-ui/system';
import { CssBaseline, Hidden, isWidthUp, Paper as MuiPaper, withWidth } from '@material-ui/core';
import Header from './Header';
import { withAuthenticator } from 'aws-amplify-react';
import { UserContext } from '../../components/UserContext';
import StandaloneSignIn from '../../pages/auth/StandaloneSignIn';
import AutoRoute from '../../components/AutoRoute';
import { USER_ROLES } from '../../Constants';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { children, routes, authData: authenticatedUser } = props;
  if (!authenticatedUser) {
    return <AutoRoute href={'/auth/sign-in'} />;
  }
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const orgName = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_name'];
  const customUserRole = authenticatedUser?.signInUserSession?.idToken?.payload['custom:user_role'];
  const userRole = customUserRole ? USER_ROLES[customUserRole] : USER_ROLES.SUPER_ADMIN;
  const userAttributes = authenticatedUser?.signInUserSession?.idToken?.payload;
  if (!orgPublicId) {
    return <AutoRoute href={'/auth/org-setup'} />;
  }
  const userContextPayload = { authenticatedUser, userAttributes, orgPublicId, orgName, userRole: userRole };

  const { headerTitle } = children.props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <UserContext.Provider value={userContextPayload}>
        <Drawer>
          <Hidden lgUp implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden mdDown implementation="css">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </Drawer>
        <AppContent>
          <Header onDrawerToggle={handleDrawerToggle} user={authenticatedUser} title={headerTitle} />
          <MainContent p={isWidthUp('lg', props.width) ? 5 : 3}>
            {/*<Box my={5} px={5}>*/}
            {children}
            {/*</Box>*/}
          </MainContent>
          <Footer />
        </AppContent>
      </UserContext.Provider>
    </Root>
  );
};

// - confirmSignIn
// - confirmSignUp -
// - forgotPassword
// - loading
// - requireNewPassword
// - signedOut
// - signedUp
// - signIn -
// - signUp -
// - verifyContact
export default withAuthenticator(withWidth()(Dashboard), false, [<StandaloneSignIn />]);
