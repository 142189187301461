import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import NavLink from '../../components/displayValues/NavLink';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useSnackbar } from 'notistack';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { PHONE_MASK } from '../../Constants';
import { conformToMask } from 'react-text-mask';
import CopyToClipboard from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  FileCopyOutlined,
} from '@material-ui/icons';

function Marketing(props) {
  const theme = useTheme();
  const { orgPublicId, authenticatedUser } = useContext(UserContext);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    match: { params },
  } = props;
  const donatePageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/donate/${orgPublicId}/${params.slug}`;
  const causePageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/cause/${orgPublicId}/${params.slug}`;
  const campaignPageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/campaign/${orgPublicId}/${params.slug}`;
  return (
    <React.Fragment>
      <Box my={9}>
        <Breadcrumbs aria-label="Breadcrumb">
          <NavLink href="/causes" text="Causes" color="primary" />
          {<Typography component="span">Markerting</Typography>}
        </Breadcrumbs>
      </Box>
      <Grid container spacing={6}>
        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={donatePageUrl} color="primary">
              Donate Page URL
            </a>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={donatePageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={donatePageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={causePageUrl} color="primary">
              Cause Page URL
            </a>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={causePageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={causePageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {/* Need to show only when campaign is enabled */}
        {/* <Grid item xs={12}>
                    <a target='_blank' href={campaignPageUrl} color="primary" > Campaign Page URL</a>
                    <CopyToClipboard
                        text={campaignPageUrl}
                        onCopy={() => {
                            setCopied(true);
                            enqueueSnackbar('Copied Campaign URL', {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                            });
                        }}>
                        <span className="gbadm-MuiTypography-root gbadm-MuiTypography-body1 gbadm-MuiTypography-colorSecondary">
                            <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                        </span>
                    </CopyToClipboard>
                </Grid> */}
      </Grid>
    </React.Fragment>
  );
}

export default Marketing;
