import MaskedInput from 'react-text-mask';
import React from 'react';

export default function TextMaskedInput(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      // mask={mask}
      // placeholderChar={'\u2000'}
      // showMask
      // readOnly={readOnly}
      {...other}
    />
  );
}
