import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Datatable from './datatable';
import { UserContext } from './UserContext';
import ElasticSearchAPIClient from '../restClients/ElasticSearchAPIClient';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

export default function ElasticSearchAdvancedDatatable(props) {
  const {
    label,
    tableOptions,
    bulkActions,
    globalActions,
    singleRowActions,
    onMoreInfoClick,
    columns,
    sourceFields,
    queryMustArray,
    dateRange,
    indexType,
    globalMetricsDisplay = {},
  } = props;
  const { authenticatedUser, orgPublicId } = useContext(UserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [queryString, setQueryString] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    totalDataCount: 0,
    data: [],
    filter: {
      query: '',
      order: 'desc',
      orderBy: 'dateCreated',
      page: 0,
      rowsPerPage: tableOptions?.rowsPerPage || 25,
      dateRange: dateRange,
    },
    selected: [],
    aggregateData: [],
  });

  const globalMetricsAggregations = globalMetricsDisplay.hasOwnProperty('aggregations')
    ? globalMetricsDisplay.aggregations
    : [];

  const performSearch = async () => {
    setIsLoading(true);
    //Prepare Aggregation query if present
    const aggsQuery = {};
    const aggsLookup = {}; //to lookup other attributes of each id returned from elastic search aggregate query
    for (let eachAggregationObj of globalMetricsAggregations) {
      aggsLookup[eachAggregationObj.id] = eachAggregationObj;
      aggsQuery[eachAggregationObj.id] = {
        [eachAggregationObj.aggregateFunction]: {
          field: eachAggregationObj.field,
        },
      };
    }

    const body = {
      from: state.filter.page * state.filter.rowsPerPage,
      size: state.filter.rowsPerPage,
      // _source: sourceFields,
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: queryString ? '*' + queryString + '*' : '*',
              },
            },
            {
              term: {
                'accessControl.keyword': orgPublicId,
              },
            },
            {
              term: {
                'indexType.keyword': indexType,
              },
            },
            {
              range: {
                dateCreated: { gte: state.filter.dateRange },
              },
            },
          ].concat(queryMustArray ? queryMustArray : []),
        },
      },
      aggs: aggsQuery,
      sort: {
        [state.filter.orderBy]: state.filter.order,
      }, // replace this with attributes you need
    };
    try {
      let response = await ElasticSearchAPIClient.doSearch(authenticatedUser, body);
      const transactions = response?.hits?.hits?.map((x) => {
        return { id: x._id, ...x._source };
      });

      //parse aggregation response
      const aggsResult = response?.aggregations ? response.aggregations : {};

      let parsedAggsResult = [];
      for (const [key, value] of Object.entries(aggsResult)) {
        parsedAggsResult.push({ ...aggsLookup[key], value: value.value });
      }
      //sort based on displayOrder
      parsedAggsResult.sort((a, b) => a.displayOrder - b.displayOrder);

      setState({
        ...state,
        totalDataCount: response.hits.total.value,
        data: transactions,
        filterProcessing: false,
        aggregateData: parsedAggsResult,
      });
      setIsLoading(false);
    } catch (e) {
      setState({
        ...state,
        filterProcessing: false,
      });
      enqueueSnackbar('Something went wrong. Please try again... ', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state.filterProcessing) {
      return;
    }
    setState({
      ...state,
      filterProcessing: true,
    });
    performSearch();
  }, [state.filter]);

  const singleRowActionsWithModifiedDoAction = singleRowActions?.map(function (singleAction) {
    if (!singleAction.alertMessage) {
      return {
        ...singleAction,
        doAction: singleAction.doAction,
      };
    } else {
      return {
        ...singleAction,
        doAction: (rowData) => {
          const action = (key) => (
            <Button
              color="secondary"
              onClick={() => {
                closeSnackbar(key);
              }}>
              Undo
            </Button>
          );
          enqueueSnackbar(singleAction.alertMessage, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            onClose: (event, reason) => {
              if (reason === 'timeout') {
                singleAction.doAction(rowData).then(
                  (response) => {
                    if (singleAction.getUpdateRowData) {
                      const updatedRowData = singleAction.getUpdateRowData(rowData);
                      const updatedData = state.data.map((singleRowData) =>
                        singleRowData.id === updatedRowData.id ? updatedRowData : singleRowData,
                      );
                      setState({
                        ...state,
                        data: updatedData,
                      });
                    }
                  },
                  (rejectedResult) => {
                    enqueueSnackbar('Something went wrong. Please try again... ', {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  },
                );
              }
            },
            autoHideDuration: 5000,
            action,
          });
        },
      };
    }
  });

  const bulkActionsWithModifiedDoAction = bulkActions?.map(function (action) {
    return {
      ...action,
      doAction: () => {
        return action.doAction(state.selected);
      },
    };
  });

  const globalActionsWithModifiedDoAction = globalActions?.map(function (action) {
    return {
      ...action,
      doAction: () => {
        return action.doAction(state.filter, queryString);
      },
    };
  });

  const handleDateRangeChange = (value) => {
    const updatedFilter = {
      ...state.filter,
      dateRange: value,
      page: 0,
    };
    setState({
      ...state,
      filter: updatedFilter,
    });
  };

  const handleQueryChange = (value) => {
    setQueryString(value);
    const updatedFilter = {
      ...state.filter,
      page: 0,
    };
    setState({
      ...state,
      filter: updatedFilter,
    });
  };

  const displayGlobalMetricData = () => {
    return globalMetricsDisplay.hasOwnProperty('doAction') ? globalMetricsDisplay.doAction(state.aggregateData) : null;
  };

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (state.filter.orderBy === property && state.filter.order === 'desc') {
      order = 'asc';
    }
    const updatedFilter = {
      ...state.filter,
      orderBy,
      order,
      page: 0,
    };
    setState({ ...state, filter: updatedFilter });
    console.log('property ', orderBy, 'order', order);
    // setState({...state, order, orderBy});
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setState({ ...state, selected: state.data.map((n) => n.id) });
      return;
    }
    setState({ ...state, selected: [] });
  };

  const handleClick = (event, id) => {
    const { selected } = state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setState({ ...state, selected: newSelected });
  };

  const handleChangePage = (event, page) => {
    const updatedFilter = {
      ...state.filter,
      page,
    };
    setState({ ...state, filter: updatedFilter });
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedFilter = {
      ...state.filter,
      rowsPerPage: event.target.value,
      page: 0,
    };
    setState({
      ...state,
      filter: updatedFilter,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Datatable
            data-cy={indexType}
            tableOptions={tableOptions}
            columns={columns}
            totalDataCount={state.totalDataCount}
            data={state.data}
            filter={state.filter}
            filterActionHandlers={{
              disabled: state.filterProcessing,
              handleRequestSort: handleRequestSort,
              handleChangePage: handleChangePage,
              handleChangeRowsPerPage: handleChangeRowsPerPage,
            }}
            selected={state.selected}
            isLoading={isLoading}
            bulkActions={bulkActionsWithModifiedDoAction}
            globalActions={globalActionsWithModifiedDoAction}
            displayGlobalMetricData={displayGlobalMetricData}
            singleRowActions={singleRowActionsWithModifiedDoAction}
            onMoreInfoClick={onMoreInfoClick}
            selectionActionHandlers={{
              handleSelectAllClick: handleSelectAllClick,
              handleClick: handleClick,
            }}
            onDateRangeChange={handleDateRangeChange}
            onQueryChange={handleQueryChange}
            label={label}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
