import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import customStyles from '../../theme/customStyles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Tooltip } from '@material-ui/core';

export default function SlugText(props) {
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = customStyles();
  return (
    <>
      <CopyToClipboard
        text={props.label}
        onCopy={() => {
          setCopied(true);
          enqueueSnackbar('Copied slug: ' + props.label, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        }}>
        <span>
          <Tooltip title="Click to copy">
            <Typography component="span" className={classes.underlineDotted}>
              {props.label}
            </Typography>
          </Tooltip>
        </span>
      </CopyToClipboard>
    </>
  );
}
