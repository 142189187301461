import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';
import useTheme from '@material-ui/core/styles/useTheme';

export default function Markdown(props) {
  const theme = useTheme();
  return (
    <ReactMarkdown
      source={props.content}
      renderers={{
        heading: (props) => (
          <Typography variant={`h${props.level}`} {...props} gutterBottom style={{ marginBottom: theme.spacing(5) }} />
        ),
        paragraph: (props) => (
          <Typography
            paragraph={true}
            variant="body1"
            gutterBottom
            {...props}
            style={{ marginBottom: theme.spacing(5) }}
          />
        ),
        image: (props) => {
          const { src } = props;
          const imageSrcParts = src.split('=');
          return (
            <a href={imageSrcParts[0]} target="_blank" rel="noopener noreferrer">
              <img alt={props.alt} src={imageSrcParts[0]} width={imageSrcParts[1] + '%'} />
            </a>
          );
        },
        blockquote: (props) => {
          return (
            <blockquote
              style={{
                padding: theme.spacing(0, 5),
                margin: 0,
                borderLeft: '0.5rem solid ' + theme.palette.grey[100],
              }}>
              {props.children}
            </blockquote>
          );
        },
        thematicBreak: (props) => <hr {...props} style={{ marginBottom: theme.spacing(5) }} />,
      }}
    />
  );
}
