import React from 'react';
import VisaLogo from '../../assets/paymentMethod/stripe_visa.svg';
import MasterCardLogo from '../../assets/paymentMethod/stripe_mastercard.svg';
import DiscoverLogo from '../../assets/paymentMethod/stripe_discover.svg';
import AmexLogo from '../../assets/paymentMethod/stripe_amex.svg';
import JcbLogo from '../../assets/paymentMethod/stripe_jcb.svg';
import UnionPayLogo from '../../assets/paymentMethod/stripe_unionpay.svg';
import DinersLogo from '../../assets/paymentMethod/stripe_diners.svg';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import Typography from '@material-ui/core/Typography';

export default function PaymentMethod(props) {
  const { brand, last4 } = props.data?.card ? props.data.card : { brand: '', last4: '' };
  switch (brand) {
    case 'Visa':
      return (
        <React.Fragment>
          <img src={VisaLogo} alt="Visa" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'MasterCard':
      return (
        <React.Fragment>
          <img src={MasterCardLogo} alt="MasterCard" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'Discover':
      return (
        <React.Fragment>
          <img src={DiscoverLogo} alt="Discover" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'American Express':
      return (
        <React.Fragment>
          <img src={AmexLogo} alt="Amex" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'UnionPay':
      return (
        <React.Fragment>
          <img src={UnionPayLogo} alt="UnionPay" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'JCB':
      return (
        <React.Fragment>
          <img src={JcbLogo} alt="JCB" />
          {` ...${last4}`}
        </React.Fragment>
      );
    case 'Diners Club':
      return (
        <React.Fragment>
          <img src={DinersLogo} alt="Diners Club" />
          {` ...${last4}`}
        </React.Fragment>
      );
    default:
      if (props?.data?.type === 'BANK') {
        return (
          <>
            <AccountBalanceIcon fontSize="small" />
            {` ...${last4}`}
          </>
        );
      } else {
        return (
          <>
            <LocalAtmOutlinedIcon />
            <Typography component="span" style={{ verticalAlign: 'top' }}>
              {' OTHER'}
            </Typography>
          </>
        );
      }
  }

  return;
}
