import React from 'react';
import styled from 'styled-components';

import { Grid, Hidden, List, ListItem as MuiListItem, ListItemText } from '@material-ui/core';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.grey[100]};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem component="a" href="https://support.goodbricks.org/contact-support/">
                <ListItemText primary="Support" />
              </ListItem>
              <ListItem component="a" href="https://support.goodbricks.org/">
                <ListItemText primary="Help Center" />
              </ListItem>
              <ListItem component="a" href="http://support.goodbricks.org/knowledge-base/privacy-policy/">
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem component="a" href="http://support.goodbricks.org/knowledge-base/terms-of-use/">
                <ListItemText primary="Terms of Service" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem>
              <ListItemText primary={'Copyright © ' + new Date().getFullYear() + ' Goodbricks • All rights reserved'} />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
