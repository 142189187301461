import React, { useEffect, useRef, useMemo, useState, useContext } from 'react';
import { UserContext } from '../../UserContext';
import Typography from '@material-ui/core/Typography';
import debounce from 'lodash.debounce';
import { Autocomplete } from '@material-ui/lab';
import GoodbricksTextField from './GoodbricksTextField';
import ElasticSearchAPIClient from '../../../restClients/ElasticSearchAPIClient';
import { Grid } from '@material-ui/core';
import { conformToMask } from 'react-text-mask';
import { PHONE_MASK } from '../../../Constants';

export default function CustomerAutocompleteField(props) {
  const { id, setValue, getValues, label, onChange, name, trigger, errors, register } = props;
  const [inputValue, setInputValue] = useState('');
  const firstLoad = useRef(false);
  const [options, setOptions] = useState([]);

  const { authenticatedUser } = useContext(UserContext);

  const handleInputChange = (event) => {
    setValue(name, event.target.value);
    setInputValue(event.target.value);
  };

  const handleAutocompleteChange = (event, value) => {
    if (['click', 'keydown'].includes(event.type)) {
      if (onChange) {
        onChange(value);
      }
      if (value) {
        name === 'name' ? setInputValue(value?.name) : setInputValue(value?.email);
        setValue('name', value?.name);
        setValue('firstName', value?.name);
        setValue('email', value?.email);
        if (value?.phone) {
          setValue('phone', conformToMask(value?.phone, PHONE_MASK, { guide: false }).conformedValue);
        } else {
          setValue('phone', '');
        }
        if (value?.paymentMethod?.card?.last4) {
          setValue(
            'defaultPaymentMethod',
            `${value?.paymentMethod.card.brand} ... *** ${value?.paymentMethod.card.last4}`,
          );
          setValue('paymentMethod', 'DEFAULT');
        } else {
          setValue('defaultPaymentMethod', '');
          setValue('paymentMethod', 'CARD');
        }
        setValue('addressStreet', value?.addressStreet);
        setValue('addressApt', value?.addressApt);
        setValue('addressCity', value?.addressCity);
        setValue('addressState', value?.addressState);
        setValue('addressZipCode', value?.addressZipCode);
      }
    }
  };

  const fetchCustomer = useMemo(
    () =>
      debounce((input, callback) => {
        ElasticSearchAPIClient.getCustomerInfoByFilterText(authenticatedUser, input.input).then(callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;
    if (firstLoad.current) {
      trigger(name);
    }

    fetchCustomer({ input: inputValue }, (results) => {
      const emails = results.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      if (active) {
        setOptions(emails || []);
      }
    });

    firstLoad.current = true;
    return () => {
      active = false;
    };
  }, [inputValue, fetchCustomer]);

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.email)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoHighlight
      includeInputInList
      autoSelect
      freeSolo
      onChange={handleAutocompleteChange}
      data-cy={id}
      renderInput={(params) => {
        const { inputProps, ...restParams } = params;

        const formValue = getValues(name);
        inputProps.value = formValue ? formValue : inputValue;

        return (
          <GoodbricksTextField
            autoComplete="off"
            inputProps={inputProps}
            helperText={errors[name] && errors[name].message}
            error={errors && !!errors[name]}
            inputRef={
              register &&
              register(
                { name: name },
                {
                  required: {
                    value: true,
                    message: label + ' is required.',
                  },
                  pattern: {
                    value: name === 'email' ? /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/ : null,
                    message: label + ' is not a valid input.',
                  },
                  minLength: {
                    value: name === 'name' ? 3 : null,
                    message: label + ' must be at least 3 characters.',
                  },
                },
              )
            }
            {...restParams}
            label={label}
            fullWidth
            onChange={handleInputChange}
          />
        );
      }}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography style={{ fontWeight: 700 }} color="secondary">
                {option.name}
              </Typography>
              <Typography variant="body2" color="secondary">
                {option.email}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
