import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

export default function NavLink(props) {
  return (
    <Link to={props.href}>
      <Typography color={props.color || 'secondary'} component="span">
        {props.text}
      </Typography>
    </Link>
  );
}
