import { makeStyles } from '@material-ui/core';

const customStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  underlineDotted: {
    borderBottom: '1px dotted',
    textDecoration: 'none',
  },
}));

export default customStyles;
