import React from 'react';
import Box from '@material-ui/core/Box';
import { Grid, Typography, withWidth } from '@material-ui/core';
import GoodbricksLogo from '../assets/goodbricks-logo.svg';
import { withAuthenticator } from 'aws-amplify-react';
import StandaloneSignIn from '../pages/auth/StandaloneSignIn';
import { UserContext } from '../components/UserContext';
import AutoRoute from '../components/AutoRoute';
import { USER_ROLES } from '../Constants';

function PrivateFullwidthLayout(props) {
  const { children, authData: authenticatedUser } = props;
  // console.log('authenticatedUser', authenticatedUser);
  if (!authenticatedUser) {
    return <AutoRoute href={'/auth/sign-in'} />;
  }
  const orgPublicId = authenticatedUser?.signInUserSession?.idToken?.payload['custom:org_public_id'];
  const userAttributes = authenticatedUser?.signInUserSession?.idToken?.payload;
  const customUserRole = authenticatedUser?.signInUserSession?.idToken?.payload['custom:user_role'];  
  const userRole = customUserRole ? USER_ROLES[customUserRole] : USER_ROLES.SUPER_ADMIN;
  if (orgPublicId) {
    return <AutoRoute href={'/'} />;
  }
  const userContextPayload = { authenticatedUser, userAttributes, orgPublicId, userRole: userRole };

  return (
    <UserContext.Provider value={userContextPayload}>
      <Grid
        container
        direction="row"
        // alignItems="center"
        justify="center"
        style={{ marginTop: '2vh' }}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Box px={5}>
            <Box my={10} />
            <Typography component="h2" variant="body1" align="center" gutterBottom>
              <img src={GoodbricksLogo} alt="Goodbricks" style={{ height: '2rem' }} />
            </Typography>
            <Box my={5} />
            {children}
            <Box my={10} />
            <Grid item xs={12}>
              <Box mt={10}>
                <Grid container item xs={12} justify="center">
                  <Typography color="textSecondary" gutterBottom>
                    Copyright © {new Date().getFullYear()} Goodbricks • All rights reserved
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </UserContext.Provider>
  );
}

export default withAuthenticator(withWidth()(PrivateFullwidthLayout), false, [<StandaloneSignIn />]);
