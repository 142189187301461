import { Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { EMAIL_REGEX } from '../../../Constants';
import GoodbricksTextField from './GoodbricksTextField';

export default function EmailChips(props) {
  let { name, value, setValue, label, errors, register, patternErrorMessage } = props;
  let textfieldName = `chipTextfield`;

  const isValidEmail = (emailAddress) => {
    return EMAIL_REGEX.test(emailAddress);
  };

  useEffect(() => {
    if (value) {
      setValue(name, value.join());
    }
  }, [value]);

  const handleAutocompleteChange = (event, values) => {
    if (['keydown', 'click'].includes(event.type)) {
      values.map((value, idx) => {
        if (!isValidEmail(value.trim())) {
          values.splice(idx, 1);
        }
      });
      setValue(name, values.join());
    }
  };
  return (
    <React.Fragment>
      <Autocomplete
        id={name}
        key={name}
        name={name + '-Autocomplete'}
        multiple
        onChange={handleAutocompleteChange}
        options={[]}
        defaultValue={value}
        freeSolo
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => {
            if (isValidEmail(option.trim()))
              return <Chip variant="outlined" size="small" label={option} {...getTagProps({ index })} />;
          });
        }}
        renderInput={(params) => (
          <GoodbricksTextField
            name={textfieldName}
            id={textfieldName}
            key={textfieldName}
            label={label}
            error={errors && !!errors[textfieldName]}
            helperText={errors && errors[textfieldName] && errors[textfieldName].message}
            inputRef={
              register &&
              register({
                pattern: {
                  value: EMAIL_REGEX,
                  message: patternErrorMessage ? patternErrorMessage : label + ' is not a valid input.',
                },
              })
            }
            inputProps={{
              'data-cy': textfieldName,
              key: textfieldName,
            }}
            {...params}
            fullWidth
          />
        )}
      />
    </React.Fragment>
  );
}
