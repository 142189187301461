import React from 'react';
import NumberFormat from 'react-number-format';

export default function PhoneNumber(props) {
  const number = props.data;
  return (
    <React.Fragment>
      <NumberFormat value={number} format="(###) ###-####" displayType="text" />
    </React.Fragment>
  );
}
