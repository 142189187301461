import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import GoodbricksLogo from '../assets/goodbricks-logo.svg';

function PublicFullwidthLayout({ children }) {
  return (
    <Grid
      container
      direction="row"
      // alignItems="center"
      justify="center"
      style={{ marginTop: '2vh' }}>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Box px={5}>
          <Box my={10} />
          <Typography
            component="h2"
            variant="body1"
            align="center"
            gutterBottom>
            <img
              src={GoodbricksLogo}
              alt="Goodbricks"
              style={{ height: '2rem' }}
            />
          </Typography>
          <Box my={5} />
          {children}
          <Box my={10} />
          <Grid item xs={12}>
            <Box mt={10}>
              <Grid container item xs={12} justify="center">
                <Typography color="textSecondary" gutterBottom>
                  Copyright © {new Date().getFullYear()} Goodbricks • All rights
                  reserved
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PublicFullwidthLayout;
