// FormWizardController.jsx
import React, { Component, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Box from '@material-ui/core/Box';

function FormWizardController(props) {
  const { data, handleDataChange, submitDataChanges, steps } = props;
  const [stepIndex, setStepIndex] = useState(0);

  const nextStep = () => {
    setStepIndex(stepIndex + 1);
  };

  const prevStep = () => {
    setStepIndex(stepIndex - 1);
  };

  const getCurrentStepComponent = () => {
    const Component = steps[stepIndex].component;
    return (
      <Component
        prevStep={prevStep}
        nextStep={nextStep}
        handleDataChange={handleDataChange}
        data={data}
        submitDataChanges={submitDataChanges}
      />
    );
  };

  return (
    <React.Fragment>
      <Stepper activeStep={stepIndex} alternativeLabel>
        {steps.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel onClick={() => setStepIndex(index)}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box my={10} />
      {getCurrentStepComponent()}
    </React.Fragment>
  );
}

export default FormWizardController;
