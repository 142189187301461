import { API } from 'aws-amplify';

const apiName = 'GoodbricksAPI';

const registerOrg = (authenticatedUser, data) => {
  // const orgPublicId = authenticatedUser?.attributes['custom:org_public_id'];
  const path = '/org/register/';
  const apiInit = {
    body: data,
    headers: {
      Authorization: `Bearer ${authenticatedUser.signInUserSession.idToken.jwtToken}`,
    },
  };
  return API.post(apiName, path, apiInit);
};

const GoodbricksAPIClient = {
  registerOrg,
};

export default GoodbricksAPIClient;
