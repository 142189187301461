import React, { useState } from 'react';

import { Typography } from '@material-ui/core';

import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import NavLink from '../../components/displayValues/NavLink';

const fields = [
  {
    name: 'code',
    label: 'Verification Code',
    fieldType: 'text',
    inputType: 'password',
  },
];

function ConfirmSignUp(props) {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const email = props?.location?.state?.email;
  const handleVerifyAccountClick = async (data) => {
    setErrorMessage('');
    try {
      await Auth.confirmSignUp(email, data.code).then((response) => {
        history.push('/auth/sign-in');
      });
    } catch (error) {
      setErrorMessage(error.message);
      console.log('error confirming sign up', error);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" align="center" gutterBottom color={'primary'}>
        Confirm your Goodbricks account
      </Typography>
      <Box mb={5}>
        <Typography align="center" gutterBottom color="textSecondary">
          We have sent a verification code to your email. <br />
          Please enter it below to reset your password.
        </Typography>
      </Box>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formOptions={{ submitButtonLabel: 'Verify Account' }}
          formSubmitCallback={handleVerifyAccountClick}
          errorMessage={errorMessage}
        />
      </Box>
      <Box>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Didn't receive a verification code?&nbsp;
          <Link
            onClick={() => history.push('/auth/resend-confirm-sign-up-code')}
            variant="body1"
            color="secondary">
            Resend code
          </Link>
        </Typography>
      </Box>
      <Box>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Already have an account?&nbsp;
          <NavLink href={'/auth/sign-in'} text={'Sign In'} />
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default ConfirmSignUp;
