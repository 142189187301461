import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { dashboardRoutes, fullWidthPrivateRoutes, fullWidthPublicRoutes, hiddenRoutes } from './index';

import PrivateAdminLayout from '../layouts/privateAdminLayout';
import PublicFullwidthLayout from '../layouts/PublicFullwidthLayout';
import Page404 from '../pages/error/Page404';
import PrivateFullwidthLayout from '../layouts/PrivateFullwidthLayout';

const childRoutes = (Layout, routes) =>
  routes.map((props, index) => {
    const { children, path, headerTitle, name, component: Component } = props;
    return children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            return (
              <Layout>
                <Component {...props} headerTitle={headerTitle ? headerTitle : name} />
              </Layout>
            );
          }}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => {
          return (
            <Layout>
              <Component {...props} headerTitle={headerTitle ? headerTitle : name} />
            </Layout>
          );
        }}
      />
    );
  });

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(PrivateAdminLayout, dashboardRoutes)}
      {childRoutes(PrivateAdminLayout, hiddenRoutes)}
      {childRoutes(PrivateFullwidthLayout, fullWidthPrivateRoutes)}
      {childRoutes(PublicFullwidthLayout, fullWidthPublicRoutes)}
      <Route
        render={() => (
          <PublicFullwidthLayout>
            <Page404 />
          </PublicFullwidthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
