import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { Breadcrumbs as MuiBreadcrumbs, Link, makeStyles, Typography as MuiTypography } from '@material-ui/core';

import { spacing } from '@material-ui/system';
import Box from '@material-ui/core/Box';
import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import FormattedDate from '../../components/displayValues/FormattedDate';
import PaymentMethod from '../../components/displayValues/PaymentMethod';
import TransactionMoreInfo from '../income/TransactionMoreInfo';
import CurrencyText from '../../components/displayValues/CurrencyText';
import { UserContext } from '../../components/UserContext';
import { USER_ROLES } from '../../Constants';
import Page401 from '../error/Page401';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

function SubscriptionTransactions(props) {
  const {
    match: { params },
  } = props;
  const { userRole } = useContext(UserContext);
  const classes = useStyles();
  const sourceFields = [
    'customer.name',
    'customer.email',
    'customer.phone',
    'amount',
    'publicId',
    'transactionDate',
    'category.name',
    'type',
    'paymentMethod',
  ];
  const columns = [
    {
      id: 'name',
      field: 'name.keyword',
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return <React.Fragment>{rowdata['customer']['name']}</React.Fragment>;
      },
    },
    {
      id: 'category.name',
      field: 'category.name.keyword',
      disablePadding: false,
      label: 'Cause',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['category']['name']}
          </Typography>
        );
      },
    },
    {
      id: 'paymentMethod',
      field: 'paymentMethod',
      align: 'right',
      disablePadding: false,
      label: 'Payment Method',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return (
          <React.Fragment>
            <PaymentMethod data={rowdata['paymentMethod']} />
          </React.Fragment>
        );
      },
    },
    {
      id: 'transactionDate',
      field: 'transactionDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.transactionDate} />;
      },
    },
    {
      id: 'amount',
      field: 'amount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['amount'] ? <CurrencyText amount={rowdata['amount']} /> : '-'}</React.Fragment>;
      },
    },
    {
      id: 'type',
      field: 'type.keyword',
      disablePadding: false,
      label: 'Type',
    },
  ];
  const queryMustArray = [
    {
      term: {
        'subscription.id.keyword': params.subscriptionId,
      },
    },
  ];

  const onMoreInfoClick = (rowData) => {
    return <TransactionMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {userRole >= USER_ROLES.FRONT_DESK_ADMIN ?
        <>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/subscriptions">
              Subscriptions
            </Link>
            <Typography>{`Subscription (${params.subscriptionId}) - Transactions`}</Typography>
          </Breadcrumbs>
          <Box my={5} />
          <ElasticSearchAdvancedDatatable
            // tableOptions={{ rowsPerPage: 5, hidePagination: true, hideToolbar: true }}
            columns={columns}
            sourceFields={sourceFields}
            indexType="income_transaction"
            queryMustArray={queryMustArray}
            onMoreInfoClick={onMoreInfoClick}
          />
        </> : <Page401></Page401>
      }
    </React.Fragment>
  );
}

export default SubscriptionTransactions;
