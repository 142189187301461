import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import DynamicForm from '../../components/dynamicForm/DynamicForm';

import { UserContext } from '../../components/UserContext';
import { useSnackbar } from 'notistack';

import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { USER_ROLES } from '../../Constants';
import Page401 from '../error/Page401';

export default function SubscriptionNew() {
  // const { authenticatedUser } = useContext(UserContext);
  // const [transactionData, setTransactionData] = useState();

  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const frequencyOptions = [{ name: 'MONTH' }, { name: 'DAY' }, { name: 'WEEK' }, { name: 'YEAR' }];
  const [paymentMethod, setPaymentMethod] = useState('');
  useEffect(() => {
    setPaymentMethod('CARD');
  }, []);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const fields = [
    {
      name: 'customer-information',
      label: 'Customer Information',
      fieldType: 'section-header',
    },
    {
      name: 'customerAutoComplete',
      label: 'Type here to load an existing customer...',
      fieldType: 'customerAutoComplete',
    },
    {
      name: 'name',
      label: 'Full Name',
      minLength: 3,
    },
    {
      name: 'email',
      label: 'Email',
      inputType: 'email',
    },
    {
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
    },
    {
      name: 'address-information',
      label: 'Address',
      fieldType: 'section-header',
    },
    {
      name: 'address',
      label: 'Address',
      fieldType: 'address',
      value: {
        addressApt: '',
        addressCity: '',
        addressState: '',
        addressStreet: '',
        addressZipCode: '',
      },
    },
    {
      name: 'cause-information',
      label: 'Cause Information',
      fieldType: 'section-header',
    },
    {
      gridWidth: 8,
      name: 'planNames',
      label: 'Cause',
      fieldType: 'cause-dropdown',
    },
    {
      gridWidth: 4,
      name: 'planAmounts',
      label: 'Amount',
      fieldType: 'amount',
    },
    {
      name: 'subInformation',
      label: 'Subscription Information',
      fieldType: 'section-header',
    },
    {
      name: 'startDate',
      label: 'Start Date',
      fieldType: 'datetime',
      gridWidth: 6,
    },
    {
      name: 'paymentInterval',
      label: 'Frequency',
      fieldType: 'text-dropdown',
      options: frequencyOptions,
      valueAttr: 'name',
      gridWidth: 4,
    },
    {
      name: 'paymentIterations',
      label: 'Iterations',
      inputType: 'number',
      gridWidth: 2,
      value: 0,
    },
    {
      name: 'payment-information',
      label: 'Payment Information',
      fieldType: 'section-header',
    },
    {
      name: 'paymentMethod',
      fieldType: 'radio-group',
      value: 'CARD',
      horizontal: true,
      onChange: handlePaymentMethodChange,
      options: [
        {
          label: 'Default',
          value: 'DEFAULT',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
      ],
    },
    {
      name: 'stripe-payment',
      fieldType: 'stripe-payment',
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['CARD'],
      },
    },
    {
      name: 'defaultPaymentMethod',
      label: 'Default Payment Method',
      disabled: true,
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['DEFAULT'],
      },
    },
    {
      name: 'saveAsDefault',
      label: 'Save as default',
      fieldType: 'checkbox',
      value: true,
      visibleWhenFieldValue: {
        field: 'paymentMethod',
        valueIn: ['CARD'],
      },
    },
    {
      name: 'sendEmail',
      label: 'Send Email To Customer',
      fieldType: 'checkbox',
      value: true,
    },
    {
      name: 'notes',
      label: 'Comments',
      fieldType: 'textarea',
      required: false,
    },
  ];
  const handleSubmitNewSubscription = async (data) => {
    try {
      const response = await GoodbricksLegacyAPIClient.saveSubscription(authenticatedUser, data);
      enqueueSnackbar('New subscription Added', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again... ', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      {userRole >= USER_ROLES.SUPER_ADMIN ?
        <>
          <Box my={9}>
            <Breadcrumbs aria-label="Breadcrumb">
              <NavLink href="/subscriptions" text="Subscriptions" color="primary" />
              <Typography component="span">New</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={6}>
            {/*<Grid item xs={12} md={8}>*/}
            {/*  <DividerWithSpacing />*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={6}>
              <DynamicForm
                fields={fields}
                formSubmitCallback={handleSubmitNewSubscription}
                formOptions={{
                  acceptStripePayment: (data) => {
                    return data.paymentMethod === 'CARD';
                  },
                  submitButtonLabel: 'Create New Subscription',
                }}
              />
            </Grid>
          </Grid>
        </> : <Page401></Page401>
      }
    </React.Fragment>
  );
}
