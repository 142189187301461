import { red } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

const ErrorText = withStyles({
  root: {
    color: red[500],
  },
})(Typography);

export default ErrorText;
