import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import GoodbricksTextField from './GoodbricksTextField';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export default function GoodbricksAutocompleteDropdownField(props) {
  const { id, name, label, fullwidth, setValue, valueAttr, options = [], register, required } = props;
  // console.log(props);

  register(
    { name: name },
    {
      required: {
        value: required,
        message: label + ' is required.',
      },
    },
  );

  const handleChange = (event, data) => {
    setValue(name, data[valueAttr]);
  };
  return (
    <Autocomplete
      id={id}
      name={name + '-autocomplete'}
      options={options}
      getOptionLabel={(option) => option.name}
      autoComplete
      autoHighlight
      includeInputInList
      autoSelect
      data-cy={id}
      onChange={handleChange}
      renderInput={(params) => <GoodbricksTextField {...params} label={label} fullWidth={fullwidth} />}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography color="secondary">{option.name}</Typography>
            </Grid>
          </Grid>
        );
      }}
      getOptionSelected={(option, value) => option.name === value.name}
    />
  );
}
