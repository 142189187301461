import React, { useContext } from 'react';
import { Add, Cancel, ReceiptOutlined, SaveAltOutlined } from '@material-ui/icons';
import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import SubscriptionMoreInfo from './SubscriptionMoreInfo';
import { makeStyles, Typography } from '@material-ui/core';
import Navlink from '../../components/displayValues/NavLink';
import FormattedDate from '../../components/displayValues/FormattedDate';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { UserContext } from '../../components/UserContext';
import CurrencyText from '../../components/displayValues/CurrencyText';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import GlobalMetricDisplay from '../../components/displayValues/GlobalMetricDisplay';
import { SUBSCRIPTIONS, USER_ROLES } from '../../Constants';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import CauseText from '../../components/displayValues/CauseText';
import StatusText from '../../components/displayValues/StatusText';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default function Subscriptions(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();
  const bulkActions = [
    {
      id: 'downoad-subscriptions',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Subscriptions',
      icon: <SaveAltOutlined />,
      parsedResponseType: SUBSCRIPTIONS,
      doAction: (ids) => {
        return ElasticSearchAPIClient.getSubscriptionsByIds(authenticatedUser, ids, downloadSourceFields);
      },
    },
  ];
  const singleRowActions = [
    {
      id: 'show-transactions',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Show Transactions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/subscriptions/' + rowData.id + '/transactions'}
          text={<ReceiptOutlined fontSize="small" color="secondary" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'cancel-subscription',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Cancel Subscription',
      alertMessage: 'Canceling Subscription...',
      doAction: (rowData) => {
        return GoodbricksLegacyAPIClient.cancelSubscriptionById(authenticatedUser, rowData.id);
      },
      getUpdateRowData: (rowData) => {
        rowData['status'] = 'Canceled';
        return rowData;
      },
      icon: <Cancel fontSize="small" color="secondary" />,
    },
  ];
  const globalActions = [
    {
      id: 'downoad-subscriptions',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Subscriptions',
      icon: <SaveAltOutlined />,
      parsedResponseType: SUBSCRIPTIONS,
      doAction: (filter, queryString) => {
        return ElasticSearchAPIClient.getSubscriptionsToDownload(
          authenticatedUser,
          filter,
          queryString,
          downloadSourceFields,
        );
      },
    },
    {
      id: 'global-action-2',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayComponent: (
        <Button
          onClick={() => {
            history.push('/subscription/new');
          }}
          data-cy={'add-subscription'}
          variant="contained"
          color="primary"
          startIcon={<Add />}>
          New Subscription
        </Button>
      ),
    },
  ];
  const globalMetricsDisplay = {
    aggregations: [
      {
        id: 'subscription-count',
        field: 'amount', //for elastic search query
        aggregateFunction: 'value_count',
        label: 'Count',
        dataType: 'number',
        displayOrder: 1,
      },
      {
        id: 'subscription-sum',
        field: 'amount', //for elastic search query
        aggregateFunction: 'sum',
        label: 'Total',
        dataType: 'currency',
        displayOrder: 2,
      },
      {
        id: 'subscription-avg',
        field: 'amount', //for elastic search query
        aggregateFunction: 'avg',
        label: 'Average',
        dataType: 'currency',
        displayOrder: 3,
      },
    ],
    doAction: (data) => {
      return <GlobalMetricDisplay data={data}></GlobalMetricDisplay>;
    },
  };
  const sourceFields = [
    'customer.name',
    'customer.email',
    'planName',
    'paymentInterval',
    'startAt',
    'endAt',
    'amount',
    'status',
    'customer.phone',
  ];

  const downloadSourceFields = [
    'customer.name',
    'customer.email',
    'planName',
    'paymentInterval',
    'startAt',
    'endAt',
    'amount',
    'status',
    'paymentCyclesPerformed',
    'paymentCyclesToPerform',
    'projectedTotalAmount',
    'amountToDate',
    'campaign.mode',
  ];

  const label = 'Subscriptions';
  const columns = [
    {
      id: 'name',
      field: 'customer.name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['customer']['name']}
          </Typography>
        );
      },
    },
    {
      id: 'planName',
      field: 'planName.keyword',
      disablePadding: false,
      label: 'Cause',
      cellContent: (rowdata) => {
        return <CauseText label={rowdata['planName']} />;
      },
    },
    {
      id: 'paymentInterval',
      field: 'paymentInterval.keyword',
      disablePadding: false,
      label: 'Frequency',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['paymentInterval']}
          </Typography>
        );
      },
    },
    {
      id: 'startAt',
      field: 'startAt',
      disablePadding: false,
      label: 'Start',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.startAt} />;
      },
    },
    {
      id: 'endAt',
      field: 'endAt',
      disablePadding: false,
      label: 'End',
      cellContent: (rowdata) => {
        return (
          <React.Fragment>{rowdata['endAt'] ? <FormattedDate date={rowdata?.endAt} /> : 'No end date'}</React.Fragment>
        );
      },
    },
    {
      id: 'amount',
      field: 'amount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (rowdata) => {
        return <React.Fragment>{rowdata['amount'] ? <CurrencyText amount={rowdata['amount']} /> : '-'}</React.Fragment>;
      },
    },
    {
      id: 'status',
      field: 'status.keyword',
      disablePadding: false,
      label: 'Status',
      cellContent: (rowdata) => {
        return <StatusText status={rowdata['status']} />;
      },
    },
  ];
  const onMoreInfoClick = (rowData) => {
    return <SubscriptionMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          label={label}
          bulkActions={bulkActions}
          singleRowActions={singleRowActions}
          globalActions={globalActions}
          columns={columns}
          sourceFields={sourceFields}
          indexType={SUBSCRIPTIONS}
          onMoreInfoClick={onMoreInfoClick}
          globalMetricsDisplay={globalMetricsDisplay}
        />}
    </React.Fragment>
  );
}
