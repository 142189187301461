import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import DynamicFormFragment from '../../../components/dynamicForm/DynamicFormFragment';
import { ArrowBackOutlined, ArrowForwardOutlined, SaveOutlined } from '@material-ui/icons';

function CauseDatesAndFrequency(props) {
  const { data, prevStep, handleDataChange, submitDataChanges } = props;
  const dataFields = ['startDate', 'endDate', 'frequency', 'paymentIterations'];
  const dataCheckboxFields = [];
  const { register, handleSubmit, errors, setValue, control, watch } = useForm({ mode: 'onBlur' });

  const saveCause = () => {
    submitDataChanges();
  };

  const handleBackClick = (event) => {
    prevStep();
  };

  const handleFieldDataChange = (event) => {
    handleDataChange({ [event.target.name]: event.target.value });
  };

  const handleDateFieldDataChange = (name, date) => {
    handleDataChange({ [name]: date });
  };

  useEffect(() => {
    dataFields.map((field) => setValue(field, data[field]));
  }, []);

  // for checkboxes
  useEffect(() => {
    dataCheckboxFields.map((field) => {
      setValue(field, data[field] ? true : false);
    });
  }, []);

  const fields = [
    {
      name: 'frequency',
      label: 'Payment Frequency Type',
      fieldType: 'radio-group',
      value: data['frequency'],
      onChange: handleFieldDataChange,
      options: [
        {
          label: 'One Time Only',
          value: 'one-time-only',
        },
        {
          label: 'One Time Default',
          value: 'one-time-default',
        },
        {
          label: 'Recurring Only',
          value: 'recurring-only',
        },
        {
          label: 'Recurring Default',
          value: 'recurring-default',
        },
      ],
    },
    {
      name: 'recurringInterval',
      label: 'Recurring Interval',
      fieldType: 'radio-group',
      value: data['recurringInterval'],
      onChange: handleFieldDataChange,
      options: [
        {
          label: 'Daily',
          value: 'DAY',
        },
        {
          label: 'Weekly',
          value: 'WEEK',
        },
        {
          label: 'Monthly',
          value: 'MONTH',
        },
        {
          label: 'Year',
          value: 'Year',
        },
      ],
      visibleWhenFieldValue: {
        field: 'frequency',
        valueIn: ['recurring-only', 'one-time-default', 'recurring-default'],
      },
    },
    {
      gridWidth: 6,
      name: 'startDate',
      label: 'Start Date',
      fieldType: 'datetime',
      required: false,
      fullwidth: true,
      value: data['startDate'],
      onChange: handleDateFieldDataChange,
    },
    {
      gridWidth: 6,
      name: 'endDate',
      label: 'End Date',
      fieldType: 'datetime',
      required: false,
      fullwidth: true,
      value: data['endDate'],
      onChange: handleDateFieldDataChange,
    },
    {
      gridWidth: 12,
      name: 'paymentIterations',
      label: 'Payment Cycles',
      required: false,
      onChange: handleFieldDataChange,
      fullwidth: false,
      visibleWhenFieldValue: {
        field: 'frequency',
        valueIn: ['recurring-only', 'one-time-default', 'recurring-default'],
      },
    },
  ];

  return (
    <React.Fragment>
      <form>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
          <Box my={5} />
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  data-cy={'back'}
                  color="default"
                  onClick={handleBackClick}
                  startIcon={<ArrowBackOutlined />}>
                  Back
                </Button>
              </Grid>
              <Box display="flex" justifyContent="flex-end" spacing={2}>
                <Box p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    disabled
                    endIcon={<ArrowForwardOutlined />}>
                    Next
                  </Button>
                </Box>
                <Box p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    onClick={handleSubmit(saveCause)}
                    startIcon={<SaveOutlined />}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box my={10} />
          <DynamicFormFragment
            fields={fields}
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default CauseDatesAndFrequency;
