import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import NavLink from '../../components/displayValues/NavLink';

function SignUp(props) {
  const [errorMessage, setErrorMessage] = useState();
  let history = useHistory();
  const handleSignUpClick = async (data) => {
    setErrorMessage(null);
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
          name: data.name,
          // 'custom:org_id': '5',
          // 'custom:org_public_id': 'icsd.org',
        },
      }).then((response) => {
        history.push('/auth/confirm-sign-up', {
          email: data.email,
        });
      });
    } catch (error) {
      setErrorMessage(error.message);
      console.log('error signing up:', error);
    }
  };

  const fields = [
    {
      // gridWidth: 6,
      name: 'name',
      label: 'Name',
      fieldType: 'text',
      // pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    {
      name: 'email',
      label: 'Email Address',
      fieldType: 'text',
      inputType: 'email',
    },
    {
      name: 'password',
      label: 'Password',
      fieldType: 'password',
    },
    {
      name: 'password-confirm',
      label: 'Confirm Password',
      fieldType: 'password-confirm',
      passwordField: 'password',
    },
  ];
  return (
    <React.Fragment>
      <Typography variant="h6" align="center" gutterBottom color={'primary'}>
        Create an account
      </Typography>
      <Box mb={5}>
        <Typography align="center" gutterBottom color="textSecondary">
          Empower your organization. Have an account?&nbsp;
          <NavLink href="/auth/sign-in" text="Sign In" />
        </Typography>
      </Box>
      <Box my={10} />
      <Box my={5}>
        <DynamicForm
          fields={fields}
          formSubmitCallback={handleSignUpClick}
          formOptions={{ submitButtonLabel: 'Sign Up' }}
          errorMessage={errorMessage}
        />
      </Box>
      <Box>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          Already signed up? <NavLink href="/auth/resend-confirm-sign-up-code" text="Confirm Sign Up" />
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default SignUp;
