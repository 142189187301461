import React, { useContext, useEffect, useState } from 'react';

import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';

import { CardHeader, Grid, makeStyles } from '@material-ui/core';

import LineChart from './LineChart';
import DoughnutChart from './DoughnutChart';
import Stats from './Stats';
import { UserContext } from '../../components/UserContext';
import { DONORS, SUBSCRIPTIONS, TRANSACTIONS, USER_ROLES } from '../../Constants';
import CurrencyText from '../../components/displayValues/CurrencyText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router-dom';
import CauseText from '../../components/displayValues/CauseText';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  table: {
    minWidth: 650,
  },
}));

export default function Dashboard() {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();

  const [newTransactions, setNewTransactions] = useState('-');
  const [newSubscriptions, setNewSubscriptions] = useState('-');
  const [newDonors, setNewDonors] = useState('-');
  const [totalRevenue, setTotalRevenue] = useState('-');
  const [incomeChartData, setIncomeChartData] = useState([]);
  const [latestTransactions, setLatestTransactions] = useState([]);
  const [latestSubscriptions, setLatestSubscriptions] = useState([]);
  const [incomeByCategories, setincomeByCategories] = useState([]);

  const dashboardTiles = [
    {
      id: 'new-transactions',
      title: 'New Transactions',
      menuOptions: [
        { label: 'Last 7 days', value: 'now-7d/d' },
        { label: 'Last 30 days', value: 'now-30d/d' },
        { label: 'Last 365 days', value: 'now-365d/d' },
        { label: 'All Time', value: '1900-01-01' },
      ],
      value: newTransactions,
      dataType: 'number',
    },
    {
      id: 'new-subscriptions',
      title: 'New Subscriptions',
      menuOptions: [
        { label: 'Last 7 days', value: 'now-7d/d' },
        { label: 'Last 30 days', value: 'now-30d/d' },
        { label: 'Last 365 days', value: 'now-365d/d' },
        { label: 'All Time', value: '1900-01-01' },
      ],
      value: newSubscriptions,
      dataType: 'number',
    },
    {
      id: 'new-donors',
      title: 'New Donors',
      menuOptions: [
        { label: 'Last 7 days', value: 'now-7d/d' },
        { label: 'Last 30 days', value: 'now-30d/d' },
        { label: 'Last 365 days', value: 'now-365d/d' },
        { label: 'All Time', value: '1900-01-01' },
      ],
      value: newDonors,
      dataType: 'number',
    },
    {
      id: 'period-total',
      title: 'Period Total',
      menuOptions: [
        { label: 'Last 7 days', value: 'now-7d/d' },
        { label: 'Last 30 days', value: 'now-30d/d' },
        { label: 'Last 365 days', value: 'now-365d/d' },
        { label: 'All Time', value: '1900-01-01' },
      ],
      value: totalRevenue,
      dataType: 'currency',
    },
  ];

  const dashboardDoughnutData = {
    id: 'top-income',
    title: 'Top Income',
    menuOptions: [
      { label: 'Last 7 days', value: 'now-7d/d' },
      { label: 'Last 30 days', value: 'now-30d/d' },
      { label: 'Last 365 days', value: 'now-365d/d' },
      { label: 'All Time', value: '1900-01-01' },
    ],
    value: incomeByCategories,
  };

  const updateData = async (event, metric, dateRange) => {
    switch (metric) {
      case 'new-transactions':
        getNewTxns(dateRange);
        break;
      case 'new-subscriptions':
        getNewSubs(dateRange);
        break;
      case 'new-donors':
        getNewDonors(dateRange);
        break;
      case 'period-total':
        getTotalRevenueByPeriod(dateRange);
        break;
      case 'top-income':
        getIncomeByCat(dateRange);
        break;
    }
  };

  const getNewTxns = async (dateRange = 'now-7d/d') => {
    try {
      const newTransactions = await ElasticSearchAPIClient.getRecordsByDate(authenticatedUser, TRANSACTIONS, dateRange);
      setNewTransactions(newTransactions);
    } catch (e) {
      console.log(e);
    }
  };

  const getNewSubs = async (dateRange = 'now-7d/d') => {
    try {
      const newSubscriptions = await ElasticSearchAPIClient.getRecordsByDate(
        authenticatedUser,
        SUBSCRIPTIONS,
        dateRange,
      );
      setNewSubscriptions(newSubscriptions);
    } catch (e) {
      console.log(e);
    }
  };

  const getNewDonors = async (dateRange = 'now-7d/d') => {
    try {
      const newDonors = await ElasticSearchAPIClient.getRecordsByDate(authenticatedUser, DONORS, dateRange);
      setNewDonors(newDonors);
    } catch (e) {
      console.log(e);
    }
  };

  const getTotalRevenueByPeriod = async (dateRange = 'now-7d/d') => {
    try {
      const totalRevenue = await ElasticSearchAPIClient.getTransactionTotals(authenticatedUser, dateRange);
      setTotalRevenue(totalRevenue);
    } catch (e) {
      console.log(e);
    }
  };

  const getIncomeChartData = async (dateRange = 'now-365d/d') => {
    try {
      const incomeChartData = await ElasticSearchAPIClient.getTransactionSumForGraph(authenticatedUser, dateRange);
      setIncomeChartData(incomeChartData);
    } catch (e) {
      console.log(e);
    }
  };

  const getLatestTxns = async (dateRange = 'now-30d/d') => {
    try {
      const latestTransactions = await ElasticSearchAPIClient.getRecordsByDate(
        authenticatedUser,
        TRANSACTIONS,
        dateRange,
        4,
        false,
      ); //last 5 transactions,
      setLatestTransactions(latestTransactions);
    } catch (e) {
      console.log(e);
    }
  };

  const getLatestSubs = async (dateRange = 'now-30d/d') => {
    try {
      const latestSubscriptions = await ElasticSearchAPIClient.getRecordsByDate(
        authenticatedUser,
        SUBSCRIPTIONS,
        dateRange,
        4,
        false,
      ); //last 5 transactions,
      setLatestSubscriptions(latestSubscriptions);
    } catch (e) {
      console.log(e);
    }
  };

  const getIncomeByCat = async (dateRange = 'now-7d/d') => {
    try {
      const incomeByCategories = await ElasticSearchAPIClient.getIncomeTransactionsByCategory(
        authenticatedUser,
        dateRange,
        9,
      ); //for doughnut chart,
      setincomeByCategories(incomeByCategories);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNewDonors();
    getNewTxns();
    getNewSubs();
    getTotalRevenueByPeriod();
    getIncomeChartData();
    getLatestSubs();
    getLatestTxns();
    getIncomeByCat();
  }, []);

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <Grid container spacing={6}>
          {/*<Grid item xs={12} sm={12} md={3}>*/}
          <Grid item xs={12} md={3}>
            <Grid container spacing={6}>
              {dashboardTiles.map((option) => (
                <Grid item key={option.id} item xs={12}>
                  <Stats
                    id={option.id}
                    title={option.title}
                    value={option.value}
                    dataType={option.dataType}
                    menuOptions={option.menuOptions}
                    updateData={updateData}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <LineChart incomeChartData={incomeChartData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <DoughnutChart
                  id={dashboardDoughnutData.id}
                  title={dashboardDoughnutData.title}
                  value={dashboardDoughnutData.value}
                  menuOptions={dashboardDoughnutData.menuOptions}
                  updateData={updateData}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardHeader title="Latest Transactions" />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="Latest Transactions Table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Cause</TableCell>
                          <TableCell align="left">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {latestTransactions.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.customer.name}</TableCell>
                            <TableCell align="left">
                              {row.category.slug ? <CauseText label={row.category.name} /> : '-'}
                            </TableCell>
                            <TableCell align="left">
                              <CurrencyText amount={row.amount} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardHeader title="Latest Subscriptions" />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="Latest Subscriptions Table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Cause</TableCell>
                          <TableCell align="left">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {latestSubscriptions.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.customer.name}</TableCell>
                            <TableCell align="left">
                              {row.category.slug ? <CauseText label={row.category.name} /> : '-'}
                            </TableCell>
                            <TableCell align="left">
                              <CurrencyText amount={row.amount} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
}
