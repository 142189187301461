import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useReactToPrint } from 'react-to-print';
import YearEndTaxReceipt from './YearEndTaxReceipt';
import Typography from '@material-ui/core/Typography';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';

export default function YearEndTaxReceiptPrintPreview(props) {
  const { authenticatedUser } = useContext(UserContext);
  const customerIds = props?.location?.state?.custIds;
  const [businessProfile, setBusinessProfile] = useState({});
  const [receiptTemplate, setReceiptTemplate] = useState({});
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    GoodbricksLegacyAPIClient.fetchBusinessSettings(authenticatedUser).then((businessProfileData) => {
      return setBusinessProfile({
        ...businessProfileData,
      });
    });

    ElasticSearchAPIClient.getTemplateByType(authenticatedUser, 'YEAR_END_TAX_RECEIPT').then((template) => {
      let receiptTemplateData = template?.hits?.hits[0]?._source;
      const updatedSignature = receiptTemplateData?.signature?.replace(/\n/gi, '  \n');
      receiptTemplateData = { ...receiptTemplateData, signature: updatedSignature };
      return setReceiptTemplate({
        ...receiptTemplateData,
      });
    });
  }, []);

  return (
    <React.Fragment>
      <Typography paragraph={true} variant="body1" gutterBottom>
        {' '}
        Generating Tax Receipt. This may take a few minutes...
      </Typography>
      <Button variant={'contained'} color="primary" onClick={handlePrint}>
        Print
      </Button>
      <Box id="tax-receipt" ref={componentRef}>
        {customerIds.map((customerId) => (
          <YearEndTaxReceipt
            key={customerId}
            businessProfile={businessProfile}
            receiptTemplate={receiptTemplate}
            customerId={customerId}
          />
        ))}
        <footer>
          <hr />
          <div>
            Generated by Goodbricks for <b>{businessProfile.name}</b>
          </div>
        </footer>
      </Box>
    </React.Fragment>
  );
}
