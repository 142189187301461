import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LabelValueGridItem from '../../../components/displayValues/LabelValueGridItem';
import { UserContext } from '../../../components/UserContext';

import ElasticSearchAPIClient from '../../../restClients/ElasticSearchAPIClient';

export default function EmailSettingsMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [templateData, setTemplateData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getTemplateById(authenticatedUser, rowData.id).then((response) => {
      const templateData = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setTemplateData(templateData[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography variant="body1" color="primary" gutterBottom data-cy={`template_details_label_${templateData?.id}`}>
          Template Details
        </Typography>
        <Box my={3} />
        <Grid container direction="row" spacing={3} alignItems="center">
          <LabelValueGridItem label={'BCC Email'} value={templateData?.bcc} id={`bccEmail_${templateData?.id}_value`} />
          <LabelValueGridItem
            label={'White Listed Email'}
            value={templateData?.whitelistEmailAddresses}
            id={`whitelistEmailAddresses_${templateData?.id}_value`}
          />
          <LabelValueGridItem
            label={'Subject'}
            value={templateData?.subject}
            id={`subject_${templateData?.id}_value`}
          />
          <LabelValueGridItem label={'Title'} value={templateData?.title} id={`title_${templateData?.id}_value`} />
          <LabelValueGridItem
            label={'Signature'}
            value={templateData?.signature}
            id={`subject_${templateData?.id}_value`}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
}
