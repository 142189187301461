import React, { useContext } from 'react';
import {
  EditOutlined,
  ReceiptOutlined,
  Repeat,
  Replay,
  SaveAltOutlined,
  Publish,
  Add,
  EmailOutlined,
  Sync,
  Cancel,
  PrintOutlined,
} from '@material-ui/icons';
import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import Navlink from '../../components/displayValues/NavLink';
import FormattedDate from '../../components/displayValues/FormattedDate';
import { UserContext } from '../../components/UserContext';
import Link from '@material-ui/core/Link';
import GlobalMetricDisplay from '../../components/displayValues/GlobalMetricDisplay';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { ONE_TIME_PLEDGE, PLEDGES, SUBSCRIPTION_PLEDGE, USER_ROLES } from '../../Constants';
import customStyles from '../../theme/customStyles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CauseText from '../../components/displayValues/CauseText';
import CurrencyText from '../../components/displayValues/CurrencyText';
import StatusText from '../../components/displayValues/StatusText';
import PledgeMoreInfo from './PledgeMoreInfo';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';

export default function Pledges(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const classes = customStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isInvalidEmail = (email) => {
    return !email || email.length <= 0 || email.includes('@caretapps.com') || email.includes('goodbricks');
  };

  const globalActions = [
    {
      id: 'downoad-pledges',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Pledges',
      icon: <SaveAltOutlined />,
      parsedResponseType: PLEDGES,
      doAction: (filter, queryString) => {
        return ElasticSearchAPIClient.getPledgesToDownload(
          authenticatedUser,
          filter,
          queryString,
          downloadSourceFields,
        );
      },
    },
    {
      id: 'import-pledges',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayComponent: (
        <Tooltip title="Import Bulk Pledges" key="import-pledges" data-cy={'import-pledges'}>
          <IconButton
            aria-label="Import Bulk Pledges"
            onClick={() => {
              history.push('/pledges/import');
            }}>
            <Publish />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 'pledge-new',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayComponent: (
        <Button
          onClick={() => {
            history.push('/pledge/new');
          }}
          data-cy={'add-pledge'}
          variant="contained"
          color="primary"
          startIcon={<Add />}>
          New Pledge
        </Button>
      ),
    },
  ];

  const bulkActions = [
    {
      id: 'downoad-pledges',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Pledges',
      icon: <SaveAltOutlined />,
      parsedResponseType: PLEDGES,
      doAction: (ids) => {
        return ElasticSearchAPIClient.getPledgesByIds(authenticatedUser, ids, downloadSourceFields);
      },
    },
    // {
    //   id: 'send-invoices',
    //   type: 'link',
    //   displayName: 'Send Selected Invoices',
    //   icon: <EmailOutlined />,
    //   doAction: (ids) => {
    //     ids.forEach((id) => {
    //       console.log('sending', id);
    //       // GoodbricksLegacyAPIClient.sendYearEndReceiptToDonor(authenticatedUser, id, '2020').then((response) => {
    //       //   enqueueSnackbar('Sent tax receipt to : ' + response.customerEmail, {
    //       //     variant: 'success',
    //       //     anchorOrigin: {
    //       //       vertical: 'top',
    //       //       horizontal: 'center',
    //       //     },
    //       //   });
    //       // });
    //     });
    //   },
    // },
  ];

  const singleRowActions = [
    {
      id: 'show-transactions',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Show Customer Transactions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/pledges/' + rowData.id + '/transactions'}
          text={<ReceiptOutlined fontSize="small" color="secondary" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'sync-transaction',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Sync Transaction',
      //alertMessage: 'Syncing transaction...',
      doAction: (rowData) => {
        enqueueSnackbar('Syncing Pledge Transactions', {
          variant: 'default',
          anchorOrigin: {
            autoHideDuration: 1500,
            vertical: 'top',
            horizontal: 'center',
          },
        });
        GoodbricksLegacyAPIClient.syncPledgeTransactions(authenticatedUser, rowData.id).then(
          (response) => {
            enqueueSnackbar('Synced Transactions', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
          (error) => {
            enqueueSnackbar('Synced Transactions Error', error, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
        );
      },
      icon: <Sync disabled fontSize="small" color="secondary" />,
    },
    {
      id: 'send-reminder',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      displayName: 'Send Reminder',
      doAction: (rowData) => {
        enqueueSnackbar('Sending Reminder', {
          variant: 'default',
          anchorOrigin: {
            autoHideDuration: 1500,
            vertical: 'top',
            horizontal: 'center',
          },
        });
        GoodbricksLegacyAPIClient.sendPledgeReminder(authenticatedUser, rowData.id).then(
          (response) => {
            enqueueSnackbar('Reminder has been sent', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
          (error) => {
            enqueueSnackbar('Error in sending reminder', error, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
        );
      },
      icon: <EmailOutlined disabled fontSize="small" color="secondary" />,
    },
    {
      id: 'cancel-pledge',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Cancel Pledge',
      doAction: (rowData) => {
        enqueueSnackbar('Canceling Pledge', {
          variant: 'default',
          anchorOrigin: {
            autoHideDuration: 1500,
            vertical: 'top',
            horizontal: 'center',
          },
        });
        GoodbricksLegacyAPIClient.cancelPledge(authenticatedUser, rowData.id).then(
          (response) => {
            enqueueSnackbar('Pledge has been canceled', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
          (error) => {
            enqueueSnackbar('Error in canceling Pledge', error, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          },
        );
      },
      icon: <Cancel disabled fontSize="small" color="secondary" />,
    },
  ];
  const globalMetricsDisplay = {
    aggregations: [
      {
        id: 'pledge-count',
        field: 'balanceAmount', //for elastic search query
        aggregateFunction: 'value_count',
        label: 'Count',
        dataType: 'number',
        displayOrder: 1,
      },
      {
        id: 'pledge-sum',
        field: 'balanceAmount', //for elastic search query
        aggregateFunction: 'sum',
        label: 'Total',
        dataType: 'currency',
        displayOrder: 2,
      },
      {
        id: 'pledge-avg',
        field: 'balanceAmount', //for elastic search query
        aggregateFunction: 'avg',
        label: 'Average',
        dataType: 'currency',
        displayOrder: 3,
      },
    ],
    doAction: (data) => {
      return <GlobalMetricDisplay data={data}></GlobalMetricDisplay>;
    },
  };
  const label = 'Customers';
  const sourceFields = [
    'customer.name',
    'category.name',
    'pledgeAmount',
    'pledgedDate',
    'fulfillByDate',
    'paymentInterval',
    'status',
    'subscribe',
  ];
  const downloadSourceFields = [
    'publicId',
    'customer.name',
    'customer.email',
    'customer.phone',
    'fulfilledAmount',
    'balanceAmount',
    'category.name',
    'pledgeAmount',
    'pledgedDate',
    'fulfillByDate',
    'paymentInterval',
    'status',
    'subscribe',
    'source',
  ];
  const columns = [
    {
      id: 'name',
      field: 'customer.name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['customer']) {
          return '';
        }
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['customer']['name']}
          </Typography>
        );
      },
    },
    {
      id: 'cause',
      field: 'category.name.keyword',
      disablePadding: false,
      label: 'Cause',
      cellContent: (rowdata) => {
        return <CauseText label={rowdata['category']['name']} />;
      },
    },
    {
      id: 'pledgeAmount',
      field: 'pledgeAmount',
      align: 'right',
      disablePadding: false,
      label: 'Amount Pledge',
      cellContent: (rowdata) => {
        return (
          <React.Fragment>
            {rowdata['pledgeAmount'] ? <CurrencyText amount={rowdata['pledgeAmount']} /> : '-'}
          </React.Fragment>
        );
      },
    },
    {
      id: 'pledgedDate',
      field: 'pledgedDate',
      disablePadding: false,
      label: 'Date Pledge',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.pledgedDate} />;
      },
    },
    {
      id: 'fulfillByDate',
      field: 'fulfillByDate',
      disablePadding: false,
      label: 'Date to Fulfill',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.fulfillByDate} />;
      },
    },
    {
      id: 'subscribe',
      field: 'subscribe',
      disablePadding: false,
      label: 'Type',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['subscribe'] ? SUBSCRIPTION_PLEDGE : ONE_TIME_PLEDGE}
          </Typography>
        );
      },
    },
    {
      id: 'status',
      field: 'status.keyword',
      disablePadding: false,
      label: 'Status',
      cellContent: (rowdata) => {
        return <StatusText status={rowdata['status']} />;
      },
    },
  ];
  const onMoreInfoClick = (rowData) => {
    return <PledgeMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          label={label}
          bulkActions={bulkActions}
          globalActions={globalActions}
          singleRowActions={singleRowActions}
          columns={columns}
          sourceFields={sourceFields}
          indexType={PLEDGES}
          onMoreInfoClick={onMoreInfoClick}
          globalMetricsDisplay={globalMetricsDisplay}
        />}
    </React.Fragment>
  );
}
