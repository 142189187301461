import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

export default function PledgeCsvUpload(props) {
  const { nextStep, handleDataChange } = props;
  const [fileDetails, setFileDetails] = useState({});
  const handleFileUpload = (data, fileInfo) => {
    handleDataChange(data);
    setFileDetails({
      fileInfo: fileInfo,
      rowCount: data.length,
    });
  };

  const papaParseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    // transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
          <Grid item></Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={nextStep} disabled={!fileDetails.rowCount}>
              Review Extracted Records
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box my={10} />
      <Grid container spacing={6} justify="center">
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Import Pledges
              </Typography>
              <Typography variant="body2" gutterBottom component="span">
                <ol>
                  <li>
                    Download sample file from{' '}
                    <Link href="https://s3-us-west-2.amazonaws.com/cdn.goodbricks.org/static/media/goodbricks/transaction_import_sample.csv">
                      here
                    </Link>
                  </li>
                  <li>Add pledges to import</li>
                  <li>Upload updated CSV file.</li>
                </ol>
              </Typography>
              <Box my={5} />
              <Button variant="contained" component="label">
                Upload File
                {/*<input type="file" style={{ display: 'none' }} />*/}
                <CSVReader
                  cssClass="react-csv-input"
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={handleFileUpload}
                  parserOptions={papaParseOptions}
                />
              </Button>
            </CardContent>
          </Card>
          <Box my={5} />
          {fileDetails.fileInfo && (
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Uploaded File Details
                </Typography>
                <Typography variant="body2" gutterBottom component="span">
                  <ul>
                    <li> Name: {fileDetails.fileInfo.name}</li>
                    <li> Size: {fileDetails.fileInfo.size} B</li>
                    <li> # of Records: {fileDetails.rowCount}</li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
