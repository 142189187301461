// CauseAmountSettings.jsx
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import DynamicFormFragment from '../../../components/dynamicForm/DynamicFormFragment';
import { ArrowBackOutlined, ArrowForwardOutlined, SaveOutlined } from '@material-ui/icons';

function CauseAmountSettings(props) {
  const { data, nextStep, prevStep, handleDataChange, submitDataChanges } = props;
  const dataFields = ['defaultAmount', 'coverFeePercentage', 'goal', 'campaignStartDate', 'campaignEndDate'];
  const dataCheckboxFields = ['amountReadOnly', 'coverFees', 'showNotes', 'showOnBehalfOf', 'campaignEnabled'];
  const { register, handleSubmit, errors, setValue, control, watch } = useForm({ mode: 'onBlur' });

  const saveAndContinue = (data) => {
    nextStep();
  };
  const saveCause = () => {
    submitDataChanges();
  };
  const handleBackClick = (event) => {
    prevStep();
  };
  const handleAmountDataChange = (event) => {
    let defaultAmounts = data.defaultAmounts;
    const index = event.target?.name?.substr(-1);
    defaultAmounts[index] = event.target.value;
    handleDataChange({ defaultAmounts: defaultAmounts });
  };

  const handleCheckboxDataChange = (event) => {
    setValue(event.target.name, event.target.checked ? true : false);
    handleDataChange({ [event.target.name]: event.target.checked ? true : false });
  };

  const handleFieldDataChange = (event) => {
    handleDataChange({ [event.target.name]: event.target.value });
  };

  const handleDateFieldDataChange = (name, date) => {
    handleDataChange({ [name]: date });
  };

  useEffect(() => {
    dataFields.map((field) => setValue(field, data[field]));
  }, []);

  // for default amounts
  useEffect(() => {
    data.defaultAmounts.map((amount, index) => {
      setValue('defaultAmounts-' + index, amount);
    });
  }, []);

  // for checkboxes
  useEffect(() => {
    dataCheckboxFields.map((field) => {
      setValue(field, data[field] ? true : false);
    });
  }, []);

  const fields = [
    {
      gridWidth: 3,
      name: 'defaultAmounts-0',
      label: 'Amount 1',
      required: false,
      onChange: handleAmountDataChange,
    },
    {
      gridWidth: 3,
      name: 'defaultAmounts-1',
      label: 'Amount 2',
      required: false,
      onChange: handleAmountDataChange,
    },
    {
      gridWidth: 3,
      name: 'defaultAmounts-2',
      label: 'Amount 3',
      required: false,
      onChange: handleAmountDataChange,
    },
    {
      gridWidth: 3,
      name: 'defaultAmounts-3',
      label: 'Amount 4',
      required: false,
      onChange: handleAmountDataChange,
    },
    {
      gridWidth: 12,
      name: 'defaultAmount',
      label: 'Default Amount',
      required: false,
      onChange: handleFieldDataChange,
      fullwidth: false,
    },
    {
      gridWidth: 4,
      name: 'amountReadOnly',
      label: 'Disable Amount Edits',
      fieldType: 'checkbox',
      value: data['amountReadOnly'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 4,
      name: 'showNotes',
      label: 'Allow donor comments',
      fieldType: 'checkbox',
      value: data['showNotes'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 4,
      name: 'collectMailingAddress',
      label: 'Collect mailing address',
      fieldType: 'checkbox',
      value: data['collectMailingAddress'] ? data['collectMailingAddress'] : false,
      onChange: handleCheckboxDataChange,
    },
    // {
    //   gridWidth: 12,
    //   name: 'showOnBehalfOf',
    //   label: 'Dedicate my donation in honor or in memory of someone',
    //   fieldType: 'checkbox',
    //   value: data['showOnBehalfOf'] ? true : false,
    //   onChange: handleCheckboxDataChange,
    // },
    {
      gridWidth: 4,
      name: 'showScheduleTransaction',
      label: 'Allow scheduled transactions',
      fieldType: 'checkbox',
      value: data['showScheduleTransaction'] ? data['showScheduleTransaction'] : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 4,
      name: 'showAnonymousOption',
      label: 'Show anonymous/public donation option',
      fieldType: 'checkbox',
      value: data['showAnonymousOption'] ? data['showAnonymousOption'] : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 4,
      name: 'showAllowCommunication',
      label: 'Include communication preference check',
      fieldType: 'checkbox',
      value: data['showAllowCommunication'] ? data['showAllowCommunication'] : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 12,
      name: 'campaignEnabled',
      label: 'Enable Campaign ',
      required: false,
      fieldType: 'checkbox',
      value: data['campaignEnabled'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 4,
      name: 'goal',
      label: 'Campaign Goal',
      required: false,
      onChange: handleFieldDataChange,
      fullwidth: false,
      visibleWhenFieldValue: {
        field: 'campaignEnabled',
        valueIn: [true],
      },
    },
    {
      gridWidth: 4,
      name: 'campaignStartDate',
      label: 'Campaign Start Date',
      fieldType: 'datetime',
      required: false,
      fullwidth: true,
      value: data['campaignStartDate'],
      onChange: handleDateFieldDataChange,
      visibleWhenFieldValue: {
        field: 'campaignEnabled',
        valueIn: [true],
      },
    },
    {
      gridWidth: 4,
      name: 'campaignEndDate',
      label: 'Campaign End Date',
      fieldType: 'datetime',
      required: false,
      fullwidth: true,
      value: data['campaignEndDate'],
      onChange: handleDateFieldDataChange,
      visibleWhenFieldValue: {
        field: 'campaignEnabled',
        valueIn: [true],
      },
    },
    {
      gridWidth: 12,
      name: 'coverFees',
      label: 'Show option to cover processing fees',
      fieldType: 'checkbox',
      value: data['coverFees'] ? true : false,
      onChange: handleCheckboxDataChange,
    },
    {
      gridWidth: 8,
      name: 'coverFeePercentage',
      label: 'Cover Fees Percentage',
      required: false,
      onChange: handleFieldDataChange,
      visibleWhenFieldValue: {
        field: 'coverFees',
        valueIn: [true],
      },
      fullwidth: false,
    },
  ];

  return (
    <React.Fragment>
      <form>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
          <Box my={5} />
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  data-cy={'back'}
                  color="default"
                  onClick={handleBackClick}
                  startIcon={<ArrowBackOutlined />}>
                  Back
                </Button>
              </Grid>
              <Box display="flex" justifyContent="flex-end" spacing={2}>
                <Box p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    onClick={handleSubmit(saveAndContinue)}
                    endIcon={<ArrowForwardOutlined />}>
                    Next
                  </Button>
                </Box>
                <Box p={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    data-cy={'Submit'}
                    color="secondary"
                    onClick={handleSubmit(saveCause)}
                    startIcon={<SaveOutlined />}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box my={10} />
          <DynamicFormFragment
            fields={fields}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default CauseAmountSettings;
