import React from 'react';
import GoodbricksTextField from './GoodbricksTextField';
import TextMaskedInput from './TextMaskedInput';

export default function TextMaskedFormatField(props) {
  const { name, label, value, onChange, readOnly, mask, ...others } = props;
  return (
    <GoodbricksTextField
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      id={name}
      InputProps={{
        inputComponent: TextMaskedInput,
      }}
      inputProps={{
        readOnly: readOnly,
        'data-cy': name,
        mask: mask,
      }}
      {...others}
    />
  );
}
