import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  CAUSES,
  DONORS,
  ONE_TIME_PLEDGE,
  PLEDGES,
  SUBSCRIPTIONS,
  SUBSCRIPTION_PLEDGE,
  TRANSACTIONS,
} from '../Constants';

const formattedDate = (date) => dayjs(date).format('YYYY-MM-DD hh:mm a');

export default function CsvExport(props) {
  const { asyncExportMethod, disable, filename, parsedResponseType, children } = props;

  const [csvData, setCsvData] = useState(false);
  const csvInstance = useRef();
  const parseResponse = (parsedResponseType, response) => {
    switch (parsedResponseType) {
      case TRANSACTIONS:
        const transactions = response?.hits?.hits?.map((x) => {
          return {
            id: x._id,
            publicId: x._source.publicId,
            transactionDate: formattedDate(x._source.transactionDate),
            categoryName: x._source.category.name,
            amount: x._source.amount,
            name: x._source.customer.name,
            email: x._source.customer.email,
            phone: x._source.customer.phone,
            paymentDetail: x._source.paymentType,
            type: x._source.type,
            status: x._source.status,
            source: x._source.source,
            refundId: x._source.paymentProcessorRefundId,
          };
        });
        return transactions;
      case PLEDGES:
        const pledges = response?.hits?.hits?.map((x) => {
          return {
            id: x._id,
            publicId: x._source.publicId,
            name: x._source.customer.name,
            email: x._source.customer.email,
            phone: x._source.customer.phone,
            categoryName: x._source.category.name,
            pledgeAmount: x._source.pledgeAmount,
            amountToDate: x._source.fulfilledAmount,
            balanceAmount: x._source.balanceAmount,
            pledgeDate: formattedDate(x._source.pledgeDate),
            fulfillByDate: x._source.fulfillByDate ? formattedDate(x._source.fulfillByDate) : '',
            type: x._source.subscribe ? SUBSCRIPTION_PLEDGE : ONE_TIME_PLEDGE,
            status: x._source.status,
            source: x._source.source,
          };
        });
        return pledges;
      case DONORS:
        const customers = response?.hits?.hits?.map((x) => {
          let paymentMethod = x._source.paymentMethod
            ? `${x._source.paymentMethod.card.brand} - ${x._source.paymentMethod.card.last4}`
            : 'N/A';
          return {
            id: x._id,
            name: x._source.name,
            email: x._source.email,
            phone: x._source.phone,
            addressStreet: x._source.addressStreet,
            addressApt: x._source.addressApt,
            addressCity: x._source.addressCity,
            addressState: x._source.addressState,
            addressZipCode: x._source.addressZipCode,
            lastTransactionDate: formattedDate(x._source.lastTransactionDate),
            customerSince: x._source.dateCreated,
            paymentMethod: paymentMethod,
            totalTransactionAmount: x._source.totalTransactionAmount,
            totalTransactionCount: x._source.totalTransactionCount,
          };
        });
        return customers;
      case CAUSES:
        const causes = response?.hits?.hits?.map((x) => {
          return {
            id: x._id,
            name: x._source.name,
            transactionCount: x._source.totalTransactionCount,
            lastTransaction: x._source.lastTransactionDate,
            grossAmount: x._source.totalTransactionAmount,
            totalCustomers: x._source.totalCustomerCount,
            taxDeductible: x._source.taxDeductible ? 'Yes' : 'No',
            zakatEligible: x._source.zakatEligible ? 'Yes' : 'No',
            displayOnKiosk: x._source.displayOnKiosk ? 'Yes' : 'No',
            displayOrder: x._source.displayOrder,
          };
        });
        return causes;
      case SUBSCRIPTIONS:
        const subscriptions = response?.hits?.hits?.map((x) => {
          return {
            id: x._id,
            name: x._source.customer.name,
            planName: x._source.planName,
            frequency: x._source.paymentInterval,
            start: x._source.startAt,
            end: x._source.endAt,
            amount: x._source.amount,
            status: x._source.status,
            cyclesCompleted: x._source.paymentCyclesPerformed + ' of ' + x._source.paymentCyclesToPerform,
            projectedTotalAmount: x._source.projectedTotalAmount,
            amountToDate: x._source.amountToDate,
            campaignMode: x._source.campaign.mode,
          };
        });
        return subscriptions;
      default:
        const defaultResponse = response?.hits?.hits?.map((x) => {
          return { id: x._id, ...x._source };
        });
        return defaultResponse;
    }
  };
  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const currentDate = new Date(Date.now());
  const formatedDate = dayjs(currentDate).format('MMM-DD-YYYY hhmm');

  return (
    <React.Fragment>
      <div
        onClick={async () => {
          if (disable) {
            return;
          }
          const response = await asyncExportMethod();
          setCsvData(parseResponse(parsedResponseType, response));
        }}>
        {children}
      </div>
      {csvData ? (
        <CSVLink
          data={csvData}
          headers={csvData.headers}
          filename={`goodbricks-${filename}-${formatedDate}.csv`}
          ref={csvInstance}
        />
      ) : undefined}
    </React.Fragment>
  );
}
