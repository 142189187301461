import React from 'react';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StaticContentDatatable from '../../../components/StaticContentDatatable';
import FormattedDate from '../../../components/displayValues/FormattedDate';
import CurrencyText from '../../../components/displayValues/CurrencyText';
import PhoneNumber from '../../../components/displayValues/PhoneNumber';
import Button from '@material-ui/core/Button';

export default function TransactionCsvVerify(props) {
  const { data, nextStep, prevStep } = props;

  const columns = [
    {
      id: 'id',
      field: 'id',
      label: 'Id',
    },
    {
      id: 'name',
      field: 'name',
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      field: 'email',
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'phone',
      field: 'phone',
      disablePadding: true,
      label: 'Phone',
      cellContent: (data) => {
        return <PhoneNumber data={data?.phone} />;
      },
    },
    {
      id: 'categoryName',
      field: 'categoryName',
      disablePadding: false,
      label: 'Cause',
    },
    {
      id: 'categoryAmount',
      field: 'categoryAmount',
      align: 'right',
      disablePadding: false,
      label: 'Amount',
      cellContent: (data) => {
        return <CurrencyText amount={data['categoryAmount']} />;
      },
    },
    {
      id: 'transactionDate',
      field: 'transactionDate',
      disablePadding: false,
      label: 'Date',
      cellContent: (data) => {
        return <FormattedDate date={data?.transactionDate} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" color="default" onClick={prevStep}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={nextStep}>
            Start Transaction Import
          </Button>
        </Grid>
      </Grid>
      <Box my={10} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {data && (
            <StaticContentDatatable
              // bulkActions={bulkActions}
              // globalActions={globalActions}
              // singleRowActions={singleRowActions}
              tableOptions={{
                title: <Typography variant="h6">Extracted Transactions</Typography>,
                rowsPerPage: 25,
                hideSearchQuery: true,
              }}
              columns={columns}
              initRowData={data}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
