import React, { useContext } from 'react';
import { EditOutlined, ReceiptOutlined, Repeat, Replay, SaveAltOutlined } from '@material-ui/icons';
import ElasticSearchAdvancedDatatable from '../../components/ElasticSearchAdvancedDatatable';
import CustomerMoreInfo from './CustomerMoreInfo';
import { Typography } from '@material-ui/core';
import Navlink from '../../components/displayValues/NavLink';
import FormattedDate from '../../components/displayValues/FormattedDate';
import { UserContext } from '../../components/UserContext';
import Link from '@material-ui/core/Link';
import GlobalMetricDisplay from '../../components/displayValues/GlobalMetricDisplay';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import { DONORS, USER_ROLES } from '../../Constants';
import customStyles from '../../theme/customStyles';

export default function Customers(props) {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const classes = customStyles();

  const globalActions = [
    {
      id: 'downoad-customers',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Customers',
      icon: <SaveAltOutlined />,
      parsedResponseType: DONORS,
      doAction: (filter, queryString) => {
        return ElasticSearchAPIClient.getCustomersToDownload(
          authenticatedUser,
          filter,
          queryString,
          downloadSourceFields,
        );
      },
    },
  ];

  const bulkActions = [
    {
      id: 'downoad-customers',
      visible: (userRole >= USER_ROLES.FRONT_DESK_ADMIN),
      type: 'downloadLink',
      displayName: 'Download Customers',
      icon: <SaveAltOutlined />,
      parsedResponseType: DONORS,
      doAction: (ids) => {
        return ElasticSearchAPIClient.getCustomersByIds(authenticatedUser, ids, downloadSourceFields);
      },
    },
  ];
  const singleRowActions = [
    {
      id: 'show-transactions',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Show Transactions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/customers/' + rowData.id + '/transactions'}
          text={<ReceiptOutlined fontSize="small" color="secondary" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'show-subscriptions',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Show Subscriptions',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/customers/' + rowData.id + '/subscriptions'}
          text={<Repeat fontSize="small" color="secondary" style={{ marginTop: '0.3rem' }} />}
        />
      ),
    },
    {
      id: 'customer-editor',
      visible: (userRole >= USER_ROLES.SUPER_ADMIN),
      displayName: 'Edit Customer',
      type: 'link',
      onLinkDisplay: (rowData) => (
        <Navlink
          href={'/customer/' + rowData.id + '/editor'}
          text={<EditOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />}
        />
      ),
      icon: <Replay fontSize="small" color="secondary" />,
    },
  ];
  const globalMetricsDisplay = {
    aggregations: [
      {
        id: 'customer-count',
        field: 'email.keyword', //for elastic search query
        aggregateFunction: 'value_count',
        label: 'Total Contributors',
        dataType: 'number',
        displayOrder: 1,
      },
      {
        id: 'customer-transaction-amount',
        field: 'totalTransactionAmount', //for elastic search query
        aggregateFunction: 'sum',
        label: 'Total Amount',
        dataType: 'currency',
        displayOrder: 2,
      },
      {
        id: 'customer-transaction-amount-avg',
        field: 'totalTransactionAmount', //for elastic search query
        aggregateFunction: 'avg',
        label: 'Avg Amount',
        dataType: 'currency',
        displayOrder: 3,
      },
    ],
    doAction: (data) => {
      return <GlobalMetricDisplay data={data}></GlobalMetricDisplay>;
    },
  };
  const label = 'Customers';
  const sourceFields = ['name', 'email', 'addressZipCode', 'planName', 'paymentInterval', 'dateCreated'];
  const downloadSourceFields = [
    'name',
    'email',
    'phone',
    'addressStreet',
    'addressApt',
    'addressCity',
    'addressState',
    'addressZipCode',
    'paymentMethod',
    'totalTransactionAmount',
    'totalTransactionCount',
    'lastTransactionDate',
    'dateCreated',
  ];
  const columns = [
    {
      id: 'name',
      field: 'name.keyword',
      disablePadding: true,
      label: 'Name',
      cellContent: (rowdata) => {
        if (!rowdata['name']) {
          return '';
        }
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['name']}
          </Typography>
        );
      },
    },
    {
      id: 'email',
      field: 'email.keyword',
      disablePadding: false,
      label: 'Email',
      cellContent: (rowdata) => {
        return (
          <Link href={'mailto:' + rowdata['email']} className={classes.underlineDotted} color="textPrimary">
            {rowdata['email']}
          </Link>
        );
      },
    },
    {
      id: 'addressZipCode',
      field: 'addressZipCode.keyword',
      disablePadding: false,
      label: 'ZipCode',
      cellContent: (rowdata) => {
        return (
          <Typography component="span" className={classes.capitalize}>
            {rowdata['addressZipCode']}
          </Typography>
        );
      },
    },
    {
      id: 'dateCreated',
      field: 'dateCreated',
      disablePadding: false,
      label: 'Customer Since',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.dateCreated} />;
      },
    },
    {
      id: 'lastTransactionDate',
      field: 'lastTransactionDate',
      disablePadding: false,
      label: 'Last TxnDate',
      cellContent: (rowdata) => {
        return <FormattedDate date={rowdata?.lastTransactionDate} />;
      },
    },
  ];
  const onMoreInfoClick = (rowData) => {
    return <CustomerMoreInfo rowData={rowData} />;
  };

  return (
    <React.Fragment>
      {
        userRole >= USER_ROLES.VIEW_ONLY &&
        <ElasticSearchAdvancedDatatable
          label={label}
          bulkActions={bulkActions}
          globalActions={globalActions}
          singleRowActions={singleRowActions}
          columns={columns}
          sourceFields={sourceFields}
          indexType={DONORS}
          onMoreInfoClick={onMoreInfoClick}
          globalMetricsDisplay={globalMetricsDisplay}
        />}
    </React.Fragment>
  );
}
