import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel';
import * as React from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[100],
  },
}))(TableCell);

class DatatableHeader extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      columns,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onMoreInfoClick,
      bulkActions,
      singleRowActions,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {bulkActions && bulkActions.some(x => x.visible) && (
            <StyledTableCell padding="checkbox">
              <Checkbox
                color="default"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </StyledTableCell>
          )}
          {onMoreInfoClick && <StyledTableCell padding="checkbox" />}
          {columns.map((column) => (
            <StyledTableCell
              data-cy={`${column.label}_headerlabel`}
              key={column.id}
              align={column.align ? column.align : 'left'}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.field ? order : false}>
              {column.disableSort ? (
                column.label
              ) : (
                <Tooltip
                  title="Sort"
                  placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.field}
                    direction={order}
                    onClick={this.createSortHandler(column.field)}>
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              )}
            </StyledTableCell>
          ))}
          {singleRowActions && singleRowActions.some(x => x.visible) && (
            <StyledTableCell key="single-actions" data-cy={`single_action_label`}>
              Actions
            </StyledTableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
}

DatatableHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default DatatableHeader;
