import React from 'react';
import { renderer } from './renderer';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ErrorText from '../displayValues/ErrorText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// field attributes
// name
// label
// inputType
// onChange
// errorMessage
// required
// pattern
// minLength
// maxLength
// fullwidth

export default function DynamicForm(props) {
  const { fields, formOptions, formSubmitCallback, errorMessage } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { register, watch, errors, handleSubmit, formState, setValue, getValues, control, trigger, reset } = useForm({
    mode: 'onBlur',
  });
  const doSubmitForm = async (data) => {
    if (!formOptions?.acceptStripePayment) {
      return formSubmitCallback(data);
    }
    if (typeof formOptions?.acceptStripePayment == 'function' && !formOptions?.acceptStripePayment(data)) {
      return formSubmitCallback(data);
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.error('Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.');
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement._empty) {
      const { token } = await stripe.createToken(cardElement);
      if (!token?.id) {
        return false;
      }
      const updateData = {
        ...data,
        token: token.id,
        cardFunding: token.card?.funding,
        clientIp: token.client_ip,
        cardAddressZipCode: token.card?.address_zip,
      };
      return formSubmitCallback(updateData);
    }
    return formSubmitCallback(data);
  };

  const showSubmitButton = formOptions.visible !== undefined ? formOptions.visible : true;

  return (
    <form onSubmit={handleSubmit(doSubmitForm)} autoComplete="off">
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
        {fields.map((field) => {
          const { name, gridWidth = 12 } = field;
          const watchFieldValue = field.visibleWhenFieldValue ? watch(field.visibleWhenFieldValue.field) : null;
          if (field.fieldType === 'hidden') {
            return <div key={name}>{renderer(field, register, errors, watch, setValue)}</div>;
          }
          return (
            (!field.visibleWhenFieldValue || field.visibleWhenFieldValue.valueIn.includes(watchFieldValue)) && (
              <Grid item xs={gridWidth} key={name}>
                {renderer(field, register, errors, watch, setValue, getValues, trigger, control)}
              </Grid>
            )
          );
        })}
        <Grid item xs={12}>
          <Box my={3} />
          {showSubmitButton && (<Button
            size="large"
            type="submit"
            variant="contained"
            data-cy={'Submit'}
            color="primary"
            disabled={formState.isSubmitting}
            fullWidth>
            {formState.isSubmitting ? (
              <>
                <CircularProgress size={20} color="inherit" style={{ marginRight: '12px' }} />
                <Typography align="center" variant="h6">
                  Processing ...
                </Typography>
              </>
            ) : (
              formOptions.submitButtonLabel
            )}
          </Button>)}
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Box>
              <ErrorText component="h2" variant="body1" align="center" gutterBottom>
                {errorMessage}
              </ErrorText>
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
