import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import LabelValueGridItem from '../../components/displayValues/LabelValueGridItem';
import { UserContext } from '../../components/UserContext';
import CurrencyText from '../../components/displayValues/CurrencyText';
import ElasticSearchAPIClient from '../../restClients/ElasticSearchAPIClient';
import Link from '@material-ui/core/Link';
import PhoneNumber from '../../components/displayValues/PhoneNumber';

function PledgeMoreInfo(props) {
  const { rowData } = props;
  const { authenticatedUser } = useContext(UserContext);
  const [pledgeData, setPledgeData] = useState();

  useEffect(() => {
    ElasticSearchAPIClient.getPledgeById(authenticatedUser, rowData.id).then((response) => {
      const pledges = response.hits.hits.map((x) => {
        return { id: x._id, ...x._source };
      });
      setPledgeData(pledges[0]);
    });
  }, []);

  return (
    <React.Fragment>
      <Box my={3}>
        <Typography variant="body1" color="primary" gutterBottom data-cy={`pledge_details_label_${pledgeData?.id}`}>
          Pledge Details
        </Typography>
        <Box my={3}>
          <Grid container direction="row" spacing={3} alignItems="center">
            <LabelValueGridItem label={'Pledge ID'} value={pledgeData?.id} id={`pledge_id_${pledgeData?.id}_value`} />
            <LabelValueGridItem
              label={'Amount to Date'}
              value={pledgeData?.fulfilledAmount && <CurrencyText amount={pledgeData?.fulfilledAmount} />}
              id={`fulfilled_amount_${pledgeData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Balance Amount'}
              value={pledgeData?.balanceAmount && <CurrencyText amount={pledgeData?.balanceAmount} />}
              id={`balance_amount_${pledgeData?.id}_value`}
            />
            <LabelValueGridItem label={'Source'} value={pledgeData?.source} id={`source_${pledgeData?.id}_value`} />
            <LabelValueGridItem
              label={'Email'}
              value={<Link href={'mailto:' + pledgeData?.customer?.email}>{pledgeData?.customer?.email || ''}</Link>}
              id={`customer_email_${pledgeData?.id}_value`}
            />
            <LabelValueGridItem
              label={'Phone'}
              value={<PhoneNumber data={pledgeData?.customer?.phone} />}
              id={`customer_phone_${pledgeData?.id}_value`}
            />
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default PledgeMoreInfo;
