import React from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import DatatableRow from './DatatableRow';
import ListingSkeleton from '../ListingSkeleton';

function DatatableBody(props) {
  const isSelected = (id) => {
    return props.selected.indexOf(id) !== -1;
  };
  const {
    data,
    columns,
    isLoading,
    rowsPerPage,
    bulkActions,
    singleRowActions,
    onRowClick,
    emptyRows,
    onMoreInfoClick,
  } = props;
  return (
    <TableBody>
      {isLoading ? (
        <ListingSkeleton rowsPerPage={rowsPerPage} columns={columns.length + 3} />
      ) : (
        data.map((rowData) => {
          const isSelectedTrue = isSelected(rowData.id);
          return (
            <DatatableRow
              key={rowData.id}
              rowData={rowData}
              isSelected={isSelectedTrue}
              onRowClick={onRowClick}
              columns={columns}
              bulkActions={bulkActions}
              singleRowActions={singleRowActions}
              onMoreInfoClick={onMoreInfoClick}
            />
          );
        })
      )}
      {data.length == 0 && !isLoading && (
        <TableRow>
          <TableCell colSpan={columns.length + 2}>No records to display</TableCell>
        </TableRow>
      )}
      {emptyRows > 0 && (
        <TableRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={columns.length + 2} />
        </TableRow>
      )}
    </TableBody>
  );
}

export default DatatableBody;
