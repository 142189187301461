import React, { useContext, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavLink from '../../components/displayValues/NavLink';
import Typography from '@material-ui/core/Typography';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import { UserContext } from '../../components/UserContext';
import GoodbricksLegacyAPIClient from '../../restClients/GoodbricksLegacyAPIClient';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { ONE_TIME_PLEDGE, SUBSCRIPTION_PLEDGE, USER_ROLES } from '../../Constants';
import Page401 from '../error/Page401';

export default function PledgeNew() {
  const { authenticatedUser, userRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const pledgeTypeOptions = [{ name: ONE_TIME_PLEDGE }, { name: SUBSCRIPTION_PLEDGE }];
  const frequencyOptions = [{ name: 'Weekly' }, { name: 'Monthly' }, { name: 'Quarterly' }, { name: 'Annual' }];

  const fields = [
    {
      name: 'customer-information',
      label: 'Customer Information',
      fieldType: 'section-header',
    },
    {
      name: 'name',
      label: 'Full Name',
      fieldType: 'customerAutoComplete',
    },
    {
      name: 'email',
      label: 'Email',
      fieldType: 'customerAutoComplete',
    },
    {
      name: 'phone',
      label: 'Phone',
      fieldType: 'phone',
    },
    {
      name: 'address-information',
      label: 'Address',
      fieldType: 'section-header',
    },
    {
      name: 'address',
      label: 'Address',
      fieldType: 'address',
      value: {
        addressApt: '',
        addressCity: '',
        addressState: '',
        addressStreet: '',
        addressZipCode: '',
      },
    },
    {
      name: 'cause-information',
      label: 'Cause Information',
      fieldType: 'section-header',
    },
    {
      gridWidth: 8,
      name: 'categoryName',
      label: 'Cause',
      fieldType: 'cause-dropdown',
    },
    {
      gridWidth: 4,
      name: 'categoryAmount',
      label: 'Amount',
      fieldType: 'amount',
    },
    {
      name: 'pledge-information',
      label: 'Pledge Information (Optional)',
      fieldType: 'section-header',
    },
    {
      name: 'pledgedDate',
      label: 'Pledge Date',
      fieldType: 'datetime',
      gridWidth: 4,
    },
    {
      name: 'fulfillByDate',
      label: 'To be fulfilled by',
      fieldType: 'datetime',
      gridWidth: 4,
    },
    {
      name: 'subscribe',
      label: 'Type',
      value: ONE_TIME_PLEDGE,
      fieldType: 'text-dropdown',
      options: pledgeTypeOptions,
      valueAttr: 'name',
      gridWidth: 4,
    },
    {
      name: 'subscription-information',
      label: 'Subscription Information (Optional)',
      fieldType: 'section-header',
      visibleWhenFieldValue: {
        field: 'subscribe',
        valueIn: [SUBSCRIPTION_PLEDGE],
      },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      fieldType: 'datetime',
      gridWidth: 4,
      visibleWhenFieldValue: {
        field: 'subscribe',
        valueIn: [SUBSCRIPTION_PLEDGE],
      },
    },
    {
      name: 'paymentInterval',
      label: 'Frequency',
      fieldType: 'text-dropdown',
      options: frequencyOptions,
      valueAttr: 'name',
      gridWidth: 4,
      visibleWhenFieldValue: {
        field: 'subscribe',
        valueIn: [SUBSCRIPTION_PLEDGE],
      },
    },
    {
      name: 'paymentIterations',
      label: 'Iterations',
      fieldType: 'text',
      gridWidth: 4,
      inputType: 'number',
      value: 0,
      visibleWhenFieldValue: {
        field: 'subscribe',
        valueIn: [SUBSCRIPTION_PLEDGE],
      },
    },
    {
      name: 'sendCommunications',
      label: 'Send Email To Customer',
      fieldType: 'checkbox',
      value: true,
    },
    {
      name: 'notes',
      label: 'Comments',
      fieldType: 'textarea',
      required: false,
    },
  ];

  const handleSubmitNewPledge = async (data) => {
    console.log(data);
    await GoodbricksLegacyAPIClient.savePledge(authenticatedUser, data).then(
      (response) => {
        enqueueSnackbar('Pledge Added Successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        history.push('/Pledge/new');
      },
      (error) => {
        enqueueSnackbar('Something went wrong. Please try again... ', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        console.log(error);
      },
    );
  };

  return (
    <React.Fragment> {
      userRole >= USER_ROLES.SUPER_ADMIN ?
      <>
        <Breadcrumbs aria-label="Breadcrumb">
          <NavLink href="/pledges" text="Pledges" color="primary" />
          <Typography component="span">New</Typography>
        </Breadcrumbs>

        <Grid container spacing={6}>
          {/*<Grid item xs={12} md={8}>*/}
          {/*<DividerWithSpacing />*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={6}>
            <DynamicForm
              fields={fields}
              formSubmitCallback={handleSubmitNewPledge}
              formOptions={{
                acceptStripePayment: false,
                submitButtonLabel: 'Create New Pledge',
              }}
            />
          </Grid>
        </Grid>
      </> : <Page401></Page401>
    }
    </React.Fragment>
  );
}
