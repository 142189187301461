import { blue, green, grey } from '@material-ui/core/colors';
import deepPurple from '@material-ui/core/colors/deepPurple';

const darkVariant = {
  name: 'Dark',
  palette: {
    primary: {
      // main: '#21ce99',
      // main: '#4be1a0',
      main: green['A400'],
      // main: '#00dd61',
      contrastText: '#FFF',
    },
    secondary: {
      // main: blue[500],
      // main: '#00a1c9',
      // main: '#0062FF',
      // main: '#44a4ff',
      main: deepPurple[700],
      // main: '#4e48e0',
      // main: "#21ce99",
      // main: "#252525",
      contrastText: '#FFF',
    },
  },
  header: {
    color: grey[500],
    background: '#FFFFFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: '#202020',
    header: {
      color: grey[200],
      background: '#202020',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#202020',
      online: {
        background: green[500],
      },
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    // background: '#F7F9FC',
    background: '#FFF',
  },
};

const variants = [darkVariant];

export default variants;
