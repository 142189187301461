import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../theme/react-datepicker-overrides.css';
import GoodbricksTextField from './GoodbricksTextField';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';

export default function GoodbricksDateTimePickerField(props) {
  const { name, value, register, setValue, label, onChange, fullwidth, noMinDate } = props;
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);
  const [helperText, setHelperText] = useState(props.helperText);
  const [showHelperText, setShowHelperText] = useState(false);
  const currentDate = new Date();
  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    if (startDate?.getTime() > currentDate.getTime()) return true;

    return selectedDate.getTime() > currentDate.getTime() + 10 * 60 * 1000;
  };
  //https://github.com/Hacker0x01/react-datepicker/issues/862
  const TextFieldDatePicker = forwardRef(({ onClick, value }, ref) => (
    <GoodbricksTextField name={name} label={label} innerRef={ref} value={value} onClick={onClick} fullWidth={true} />
  ));

  React.useEffect(() => {
    register({ name: name });
    setValue(name, new Date(startDate).getTime());
    if (onChange) {
      onChange(name, new Date(startDate).getTime());
    }
  }, [startDate]);

  const formattedDate = (date) => dayjs(date).format('MM/DD/YYYY');

  return (
    <React.Fragment>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          if (date.getTime() >= currentDate.getTime() && props.helperText) {
            setHelperText(`${props.helperText} ${formattedDate(date)}`);
            setShowHelperText(true);
          } else {
            setShowHelperText(false);
          }
          setStartDate(date);
        }}
        showTimeSelect
        minDate={noMinDate ? undefined : currentDate}
        filterTime={noMinDate ? undefined : filterPassedTime}
        dateFormat="dd MMM yyyy @ h:mm aa"
        customInput={<TextFieldDatePicker {...props} />}
      />
      {showHelperText ? (
        <span>
          <Typography component="span">{helperText}</Typography>
        </span>
      ) : undefined}
    </React.Fragment>
  );
}
